import React, { useMemo } from 'react';

import {
  Card,
  CHECKBOX,
  DATEPICKER,
  DROPDOWN,
  FILE_UPLOAD,
  INPUT,
  MULTI_DROPDOWN,
  RADIO_GROUP,
  SECTION,
  TEXT_AREA,
  TIMEPICKER,
  YEARPICKER
} from '@neslotech/eventhub-ui-kit';
import { getClassNames } from '@neslotech/utils';

import { useFormBuilderContext } from '../../../hooks/useFormBuilderContext';

import { ReactComponent as TrashIcon } from '../../../icons/trash-icon.svg';

import SectionProperties from './forms/section/SectionProperties';
import InputProperties from './forms/input/InputProperties';
import TextAreaProperties from './forms/text-area/TextAreaProperties';
import DropdownProperties from './forms/dropdown/DropdownProperties';
import TimepickerProperties from './forms/timepicker/TimepickerProperties';
import DatepickerProperties from './forms/datepicker/DatepickerProperties';
import CheckboxProperties from './forms/checkbox/CheckboxProperties';
import FileUploadProperties from './forms/file-upload/FileUploadProperties';
import RadioGroupProperties from './forms/radio-group/RadioGroupProperties';
import YearpickerProperties from './forms/yearpicker/YearpickerProperties';

import './widget-properties.scss';

const WidgetProperties = ({ open }) => {
  const { context, onCloseProperties, onSaveProperties, onRemoveElement } = useFormBuilderContext();

  const PropertiesForm = useMemo(() => {
    if (!context) {
      return null;
    }

    switch (context.type) {
      case SECTION:
        return SectionProperties;
      case INPUT:
        return InputProperties;
      case TEXT_AREA:
        return TextAreaProperties;
      case MULTI_DROPDOWN:
      case DROPDOWN:
        return DropdownProperties;
      case TIMEPICKER:
        return TimepickerProperties;
      case DATEPICKER:
        return DatepickerProperties;
      case CHECKBOX:
        return CheckboxProperties;
      case FILE_UPLOAD:
        return FileUploadProperties;
      case RADIO_GROUP:
        return RadioGroupProperties;
      case YEARPICKER:
        return YearpickerProperties;
      default:
        throw new Error(
          `The type of widget provided does not have a property form defined: ${context.type}`
        );
    }
  }, [context]);

  const handleCancel = () => {
    onCloseProperties();
  };

  const handleSave = (payload) => {
    onSaveProperties(payload);
  };

  return (
    <article className={getClassNames('widget-properties', { open })}>
      <Card>
        <header className="widget-properties__action">
          <TrashIcon onClick={() => onRemoveElement()} />
        </header>
        {PropertiesForm && context && (
          <PropertiesForm element={context} onSave={handleSave} onCancel={handleCancel} />
        )}
      </Card>
    </article>
  );
};

export default WidgetProperties;
