export const classesTableHeaders = [
  { Header: 'Class Name', accessor: 'name' },
  { Header: 'Price', accessor: 'price' }
];

export const membersTableHeaders = [
  { Header: 'Member Type', accessor: 'name' },
  { Header: 'Price', accessor: 'price' },
  { Header: 'Limit', accessor: 'limit' }
];
