import React from 'react';

import { Button, ButtonGroup, Form, FormRow, Input, RadioGroup } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import { ReactComponent as RemoveIcon } from '../../../../../icons/circle-close-icon.svg';
import { ReactComponent as AddIcon } from '../../../../../icons/add-filled-icon.svg';

import './dropdown-properties.scss';

const layoutOptions = [
  {
    label: 'Full Width',
    value: true
  },
  {
    label: 'Half Width',
    value: false
  }
];

const requiredOptions = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

const rules = {
  validates: {
    label: ['isPresent'],
    placeholder: ['isPresent'],
    items: ['isPresent']
  }
};

const formify = ({ id, type, placeholder, items, label, filled, required }) => ({
  id,
  type,
  placeholder,
  label,
  items,
  filled,
  required
});

const serverify = ({ id, type, placeholder, items, label, filled, required }) => ({
  id,
  type,
  placeholder,
  label,
  items,
  filled,
  required
});

const SelectionField = ({ value, onRemove }) => (
  <article className="selection-field">
    <small>{value}</small>
    <RemoveIcon onClick={() => onRemove(value)} />
  </article>
);

const DropdownProperties = ({ element, onSave, onCancel }) => {
  const [form, setForm] = useFormState(formify(element), rules);

  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleSave = () => {
    const payload = serverify(form);
    onSave(payload);
  };

  const addItem = () => {
    if (form?.item === '') {
      return;
    }

    const existingItems = form.items.filter((value) => value !== form.item);
    handleChange({ item: '', items: [...existingItems, form.item] });
  };

  const removeItem = (value) => {
    const existingItems = form.items.filter((itemValue) => value !== itemValue);
    handleChange({ items: existingItems });
  };

  return (
    <article className="dropdown-properties">
      <Form>
        <FormRow>
          <Input
            name="label"
            placeholder="Label Text"
            label="Label Text"
            onChange={handleChange}
            value={form?.label}
            error={form?.messages?.label}
          />
        </FormRow>
        <FormRow>
          <Input
            name="placeholder"
            placeholder="Placeholder Text"
            label="Placeholder Text"
            onChange={handleChange}
            value={form?.placeholder}
            error={form?.messages?.placeholder}
          />
        </FormRow>
        <FormRow>
          <Input
            name="item"
            placeholder="Enter a value"
            label="Selection fields"
            onChange={handleChange}
            value={form?.item}
            action={<AddIcon />}
            onActionClick={addItem}
          />
        </FormRow>
        <section className="dropdown-properties__items">
          {(form?.items ?? []).map((item) => (
            <SelectionField key={item} value={item} onRemove={removeItem} />
          ))}
        </section>
        <FormRow>
          <RadioGroup
            name="filled"
            items={layoutOptions}
            onChange={handleChange}
            label="Layout Options"
            value={form?.filled}
          />
        </FormRow>
        <FormRow>
          <RadioGroup
            name="required"
            items={requiredOptions}
            onChange={handleChange}
            label="Required field?"
            value={form?.required}
          />
        </FormRow>
      </Form>
      <ButtonGroup centered>
        <Button label="Save" disabled={!form?.valid} onClick={handleSave} />
        <Button secondary label="Cancel" onClick={onCancel} />
      </ButtonGroup>
    </article>
  );
};

export default DropdownProperties;
