import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import { cancelEvent } from '../../../../actions/event.actions';

import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';

import CancelEvent from '../../../../components/events/cancel/CancelEvent';

const CancelEventContainer = ({ event, show, setShowModal }) => {
  const dispatch = useDispatch();
  const { competitionId } = useParams();

  const { id } = useOrganisationContext();

  const onClose = () => setShowModal(false);

  const handleConfirm = (payload) => {
    dispatch(cancelEvent(id, competitionId, event.id, payload));
    onClose();
  };

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout large onClose={onClose} title="Cancel Event">
            <CancelEvent event={event} onConfirmClick={handleConfirm} onCancelClick={onClose} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default CancelEventContainer;
