import React from 'react';

import {
  Button,
  Card,
  Colorpicker,
  Form,
  FormRow,
  Input,
  RadioGroup,
  Timepicker
} from '@neslotech/eventhub-ui-kit';
import { convertTimeToDate, toLongDateString } from '@neslotech/utils';

import { useScheduleContext } from '../../../../hooks/useScheduleContext';

import { ReactComponent as CalendarIcon } from '../../../../icons/calendar-icon.svg';
import { ReactComponent as DescriptionIcon } from '../../../../icons/paragraph-icon.svg';
import { ReactComponent as LocationIcon } from '../../../../icons/location-icon.svg';
import { ReactComponent as FlagIcon } from '../../../../icons/flag-icon.svg';

import './add-schedule-timeslot.scss';

const radioItems = () => [
  {
    label: 'Email',
    value: 'email'
  },
  {
    label: 'SMS',
    value: 'sms'
  },
  {
    label: 'Both',
    value: 'both'
  }
];

const AddScheduleTimeslot = () => {
  const { event, selectedTimeslot, handleSelectedTimeslotChange, handleSaveAdditionalTimeslot } =
    useScheduleContext();

  const onChange = (value) => handleSelectedTimeslotChange(value);

  return (
    <section className="add-schedule-timeslot">
      <Card noShadow>
        <h3>Additional Time Slot</h3>
        <article className="add-schedule-timeslot__date">
          <CalendarIcon />
          <p>{toLongDateString(event?.startDate)}</p>
        </article>
        <Form>
          <FormRow>
            <Input
              labelPrefix={<FlagIcon />}
              name="name"
              label="Timeslot Name"
              placeholder="Timeslot Name"
              value={selectedTimeslot?.name}
              onChange={onChange}
              guttered
              borderless
              shadowed
            />
          </FormRow>
          <FormRow fluid>
            <Timepicker
              name="startTime"
              label="Start Time"
              placeholder="Start Time"
              initialValue={convertTimeToDate(selectedTimeslot?.startTime)}
              onChange={onChange}
            />
            <Timepicker
              name="endTime"
              label="End Time"
              placeholder="End Time"
              initialValue={convertTimeToDate(selectedTimeslot?.endTime)}
              onChange={onChange}
            />
          </FormRow>
          <FormRow>
            <Colorpicker
              label="Timeslot Colour"
              name="color"
              value={selectedTimeslot?.color}
              onChange={onChange}
            />
          </FormRow>
          <FormRow>
            <RadioGroup
              name="notificationType"
              label="Notification Type"
              value={selectedTimeslot?.notificationType}
              onChange={onChange}
              items={radioItems()}
              fluid
            />
          </FormRow>
          <FormRow>
            <Input
              labelPrefix={<DescriptionIcon />}
              name="description"
              label="Description"
              placeholder="Description (Optional)"
              value={selectedTimeslot?.description}
              onChange={onChange}
              guttered
              borderless
              shadowed
            />
          </FormRow>
          <FormRow>
            <Input
              labelPrefix={<LocationIcon />}
              name="location"
              label="Location"
              placeholder="Timeslot Location (Optional)"
              value={selectedTimeslot?.location}
              onChange={onChange}
              guttered
              borderless
              shadowed
            />
          </FormRow>
        </Form>
        <article className="add-schedule-timeslot__actions">
          <Button
            label="Save Additional Timeslot"
            onClick={handleSaveAdditionalTimeslot}
            tertiary
            longText
          />
        </article>
      </Card>
    </section>
  );
};

export default AddScheduleTimeslot;
