import axios from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import { addSystemNotice } from '../actions/system.actions';
import { RETRIEVE_PROFILE, SET_PROFILE, UPDATE_PROFILE } from '../actions/profile.action';

import { getLoadProfileRequest, getUpdateProfileRequest } from '../tools/api/profile.endpoints';

export function* performRetrieveProfile({ onSuccess }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadProfileRequest();

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_PROFILE, profile: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error, SNACK_CRITICAL));
  }
}

export function* watchForRetrieveProfileRequest() {
  yield takeLatest(RETRIEVE_PROFILE, performRetrieveProfile);
}

export function* performUpdateProfile({ profile }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getUpdateProfileRequest(profile);

    // make the request, retrieve the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('Your profile has been updated.', SNACK_SUCCESS));

    // set profile on the state
    yield put({ type: SET_PROFILE, profile: data });
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to update my profile.', SNACK_CRITICAL)
    );
  }
}

export function* watchForUpdateProfileRequest() {
  yield takeLatest(UPDATE_PROFILE, performUpdateProfile);
}

export default function* profileSaga() {
  yield all([watchForRetrieveProfileRequest(), watchForUpdateProfileRequest()]);
}
