import React from 'react';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import RemoveOrganiser from '../../../../components/organiser/remove/RemoveOrganiser';
import { removeOrganiser } from '../../../../actions/organisation.actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const RemoveOrganiserContainer = ({ organiserId, show, setShowModal, id }) => {
  const dispatch = useDispatch();
  const onClose = () => setShowModal(false);

  const navigate = useNavigate();

  const handleConfirm = () => {
    dispatch(removeOrganiser(id, organiserId, () => navigate('/organisations/details')));
  };

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout onClose={onClose} title="Are you sure?">
            <RemoveOrganiser
              handleConfirm={handleConfirm}
              onClose={onClose}
              setShowModal={setShowModal}
            />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveOrganiserContainer;
