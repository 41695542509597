import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Loader, PageLayout } from '@neslotech/eventhub-ui-kit';

import { acceptEntry, loadEntry } from '../../../../actions/entry.actions';

import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';

import OrganizerLayout from '../../../../components/layout/organizer/OrganizerLayout';
import EntryDetails from '../../../../components/entries/details/EntryDetails';

const EntryContainer = () => {
  const dispatch = useDispatch();
  const { id: entryId } = useParams();

  const { id, verified } = useOrganisationContext();

  const entry = useSelector(({ entry_store }) => entry_store.entry);
  const stateLoading = useSelector(({ entry_store }) => entry_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadEntry(id, entryId, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, entryId]);

  const handleAccept = () => {
    dispatch(acceptEntry(id, entryId));
  };

  return (
    <OrganizerLayout gutterless>
      <PageLayout title="Entry Details" back fixed>
        {loading && <Loader filled />}
        {!loading && <EntryDetails verified={verified} entry={entry} onAccept={handleAccept} />}
      </PageLayout>
    </OrganizerLayout>
  );
};

export default EntryContainer;
