import React from 'react';
import { useDispatch } from 'react-redux';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import { reinstateCompetition } from '../../../../actions/competition.actions';
import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';

import ReinstateCompetition from '../../../../components/competitions/reinstate/ReinstateCompetition';

const ReinstateCompetitionContainer = ({ competition, show, setShowModal }) => {
  const dispatch = useDispatch();
  const { id } = useOrganisationContext();

  const onClose = () => setShowModal(false);

  const handleConfirm = () => {
    dispatch(reinstateCompetition(id, competition.id));
    onClose();
  };

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout onClose={onClose} title="Reinstate Competition">
            <ReinstateCompetition
              competition={competition}
              onConfirmClick={handleConfirm}
              onCancelClick={onClose}
            />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default ReinstateCompetitionContainer;
