import Cookies from 'js-cookie';

const ORGANIZER_CONTEXT_ID = 'eventhub-organizer-context-id';

/** Sets the organisation in context cookie */
export const setOrganisationIdCookie = (value) => {
  Cookies.set(ORGANIZER_CONTEXT_ID, value);
};

/** Get the organisation in context cookie */
export const getOrganisationIdCookie = () => {
  const contextId = Cookies.get(ORGANIZER_CONTEXT_ID);
  if (!contextId) {
    return null;
  }

  return Number(contextId);
};

/** Remove the organisation in context cookie */
export const removeOrganisationIdCookie = () => {
  Cookies.remove(ORGANIZER_CONTEXT_ID);
};
