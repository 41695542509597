import React from 'react';

import { Button, ButtonGroup, FormRow, Datepicker } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';
import { getTomorrowFromDate } from '@neslotech/utils';

import './postpone-event.scss';

const serverify = ({ startDate, endDate }) => ({
  start_date: startDate,
  end_date: endDate
});

const PostponeDate = ({ onConfirmClick, onCancelClick }) => {
  const [form, setForm] = useFormState(
    { startDate: new Date(), endDate: getTomorrowFromDate(new Date()) },
    { validates: {} }
  );

  const handleChange = (newForm) => setForm({ ...form, ...newForm });
  const handleStartDateChange = (newForm) => {
    if (newForm?.startDate > form?.endDate) {
      handleChange({ ...newForm, endDate: getTomorrowFromDate(newForm.startDate) });
    } else {
      handleChange(newForm);
    }
  };

  const handleConfirm = () => {
    const payload = serverify(form);
    onConfirmClick(payload);
  };

  return (
    <section className="postpone-event">
      <section className="postpone-event__message">
        <p>
          Please select a date for your event to be postponed to. If you would like to do this at a
          later stage it can be edited on the upcoming events details page.
        </p>
      </section>
      <section className="postpone-event__form">
        <FormRow fluidSpaced>
          <Datepicker
            name="startDate"
            label="Start Date"
            placeholder="Start Date"
            value={form?.startDate}
            onChange={handleStartDateChange}
          />
          <Datepicker
            name="endDate"
            label="End Date"
            placeholder="End Date"
            minDate={form?.startDate}
            value={form?.endDate}
            onChange={handleChange}
          />
        </FormRow>
      </section>
      <ButtonGroup>
        <Button label="Confirm" onClick={handleConfirm} />
        <Button label="Cancel" onClick={onCancelClick} secondary />
      </ButtonGroup>
    </section>
  );
};

export default PostponeDate;
