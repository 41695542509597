import React, { useMemo } from 'react';

import { AddAction, DetailsSection, Input, Table } from '@neslotech/eventhub-ui-kit';

import { getDenomination } from '../../tools/constants';
import { tableHeaders } from './dynamic-templates.helper';

import { ReactComponent as RemoveIcon } from '../../icons/x-icon.svg';

import './dynamic-points-config-template.scss';

const DynamicPointsConfigTemplate = ({ editable, form, handleChange }) => {
  const rowData = useMemo(() => {
    const handleValueChange = (index, value) => {
      const places = form?.places;
      let allocation = form?.places[index];
      allocation = {
        ...allocation,
        ...value
      };
      places[index] = allocation;

      handleChange({ places });
    };

    return form.places.map((place, index) => ({
      place: (
        <Input borderless name="place" placeholder={place.place} value={place.place} disabled />
      ),
      points: (
        <Input
          disabled={!editable}
          borderless
          name="points"
          type="number"
          min="0"
          placeholder="25"
          value={place.points}
          onChange={(val) => handleValueChange(index, val)}
        />
      ),
      icon: editable && (
        <RemoveIcon
          className="dynamic-points-config-template__remove-icon"
          onClick={() => removeRow(place)}
        />
      )
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, handleChange, editable]);

  const removeRow = (place) => {
    let places = form?.places ?? [];
    places = places
      .filter((currentPlace) => currentPlace !== place)
      .map((place, index) => ({
        place: getDenomination(String(index + 1)),
        points: place.points
      }));
    handleChange({ places });
  };

  const addNewRow = () => {
    const places = form?.places ?? [];
    places.push({
      place: getDenomination(String(form?.places.length + 1)),
      points: ''
    });
    handleChange({ places });
  };

  return (
    <section className="dynamic-points-config-template">
      {editable && (
        <DetailsSection
          title={editable ? form?.template ?? 'Points Configuration' : ''}
          subtitle={editable ? 'Please configure the points for this event' : ''}
        >
          <Table cols={tableHeaders} rowData={rowData} pageLength={100} />
        </DetailsSection>
      )}
      {!editable && <Table cols={tableHeaders} rowData={rowData} />}
      {editable && <AddAction title="Add New Row" onClick={addNewRow} />}
    </section>
  );
};

DynamicPointsConfigTemplate.defaultProps = {
  editable: true
};

export default DynamicPointsConfigTemplate;
