import React from 'react';

import { convertTimeToDate, getClassNames, isEmpty, noOp } from '@neslotech/utils';
import { Checkbox, Form, FormRow, Input, Timepicker } from '@neslotech/eventhub-ui-kit';

import { useScheduleContext } from '../../../../hooks/useScheduleContext';

import { ReactComponent as FlagIcon } from '../../../../icons/flag-icon.svg';
import { ReactComponent as CircleCheckIcon } from '../../../../icons/circle-check-icon.svg';

import './add-class-schedule-heat.scss';

const SaveAction = ({ title, onClick, disabled }) => (
  <article
    className={getClassNames('save-action', { disabled })}
    onClick={!disabled ? onClick : noOp}
  >
    <CircleCheckIcon />
    <small className={getClassNames('save-action__title', { disabled })}>{title}</small>
  </article>
);

const AddClassScheduleHeat = () => {
  const { selectedClassSchedule, showHeatForm, heat, handleHeatChange, handleSaveHeat } =
    useScheduleContext();

  if (!showHeatForm && !isEmpty(selectedClassSchedule?.heats)) {
    return null;
  }

  return (
    <article className="add-schedule-heat">
      <Form>
        <FormRow fluid>
          <Input
            labelPrefix={<FlagIcon />}
            label="Heat Name"
            placeholder="Heat Name"
            name="name"
            value={heat?.name}
            onChange={handleHeatChange}
            shadowed
            borderless
            guttered
          />
          <article className="add-schedule-heat__info">
            <label className="add-schedule-heat__info-label">Heat Information</label>
            <Checkbox
              name="priority"
              checked={heat?.priority}
              onChange={handleHeatChange}
              label="Priority heat?"
            />
            <Checkbox
              name="counts"
              checked={heat?.counts}
              onChange={handleHeatChange}
              label="Counts for points"
            />
          </article>
        </FormRow>
        <FormRow fluid>
          <Timepicker
            name="startTime"
            label="Start Time"
            initialValue={convertTimeToDate(heat?.startTime)}
            onChange={handleHeatChange}
            placeholder="Start Time"
          />
          <Timepicker
            name="endTime"
            label="End Time"
            initialValue={convertTimeToDate(heat?.endTime)}
            onChange={handleHeatChange}
            placeholder="End Time"
          />
        </FormRow>
        <FormRow>
          <SaveAction
            title="Save Heat"
            onClick={() => handleSaveHeat(heat)}
            disabled={!heat?.name}
          />
        </FormRow>
      </Form>
    </article>
  );
};

export default AddClassScheduleHeat;
