import React from 'react';

import { Button, ButtonGroup } from '@neslotech/eventhub-ui-kit';

import './duplicate-event.scss';

const DuplicateEvent = ({ onConfirmClick, onCancelClick }) => (
  <section className="duplicate-event">
    <section className="duplicate-event__details">
      <p className="duplicate-event__message">
        An event with the following matching details already exists:
      </p>
      <ul className="duplicate-event__list">
        <li>Event Name</li>
        <li>Competition Event Falls Under</li>
        <li>Start and End Date</li>
      </ul>
      <p className="duplicate-event__reason">
        More than one event with these matching details cannot exist.
      </p>
    </section>
    <ButtonGroup>
      <Button label="View Event" onClick={onConfirmClick} />
      <Button label="Cancel" onClick={onCancelClick} secondary />
    </ButtonGroup>
  </section>
);

export default DuplicateEvent;
