import { API_HOST } from './index';

import { getHttpGetOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

const getSportsEndpoint = () => `${API_HOST}/sports`;
export const getLoadSportsRequest = () => [
  getSportsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

const getClassTypesEndpoint = (id) => `${API_HOST}/sports/${id}/class_types`;
export const getLoadClassTypesRequest = (sportId) => [
  getClassTypesEndpoint(sportId),
  getHttpGetOptions(getAuthHeaders())
];
