export const SAVE_TEMPLATE = '[TEMPLATE_STORE] Save template';
export const saveTemplate = (id, payload) => ({
  type: SAVE_TEMPLATE,
  id,
  payload
});

export const LOAD_TEMPLATES = '[TEMPLATE_STORE] Load all templates';
export const SET_TEMPLATES = '[TEMPLATE_STORE] Set all templates';
export const loadTemplates = (id, onComplete) => ({
  type: LOAD_TEMPLATES,
  id,
  onComplete
});

export const LOAD_POINTS_CONFIG_TEMPLATES = '[TEMPLATE_STORE] Load points config templates';
export const SET_POINTS_CONFIG_TEMPLATES = '[TEMPLATE_STORE] Set points config templates';
export const loadPointsConfigTemplates = (id) => ({
  type: LOAD_POINTS_CONFIG_TEMPLATES,
  id
});

export const LOAD_ENTRY_FORM_TEMPLATES = '[TEMPLATE_STORE] Load entry form templates';
export const SET_ENTRY_FORM_TEMPLATES = '[TEMPLATE_STORE] Set entry form templates';
export const loadEntryFormTemplates = (id) => ({
  type: LOAD_ENTRY_FORM_TEMPLATES,
  id
});

export const LOAD_TEMPLATE = '[TEMPLATE_STORE] Load template';
export const SET_TEMPLATE = '[TEMPLATE_STORE] Set template';
export const loadTemplate = (id, templateId, onComplete) => ({
  type: LOAD_TEMPLATE,
  id,
  templateId,
  onComplete
});

export const UPDATE_TEMPLATE = '[TEMPLATE_STORE] Update template';
export const updateTemplate = (id, templateId, payload) => ({
  type: UPDATE_TEMPLATE,
  id,
  templateId,
  payload
});

export const REMOVE_TEMPLATE = '[TEMPLATE_STORE] Remove template';
export const removeTemplate = (id, templateId, navigate) => ({
  type: REMOVE_TEMPLATE,
  id,
  templateId,
  navigate
});
