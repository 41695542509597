import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Header, Sidebar } from '@neslotech/eventhub-ui-kit';
import { getClassNames } from '@neslotech/utils';

import { useAuth } from '../../../hooks/useAuth';

import { sidebarItems } from './sidebar.helpers';
import { dropdownItems } from './layout.helpers';
import { useOrganisationContext } from '../../../hooks/useOrganisationContext';

import { ReactComponent as BackIcon } from '../../../icons/chevron-left-icon.svg';

import OrganisationSwitch from '../../organisation/switch/OrganisationSwitch';

import './organizer-layout.scss';

const OrganizerLayout = ({ title, subtitle, back, gutterless, children }) => {
  const navigate = useNavigate();
  const auth = useAuth();

  const { verified } = useOrganisationContext();

  return (
    <section className="organizer-layout">
      <Sidebar items={sidebarItems} />
      <section className="organizer-layout__content-section">
        <Header items={dropdownItems(navigate, auth)}>
          <OrganisationSwitch verified={verified} />
        </Header>
        <section className={getClassNames('organizer-layout__content', { gutterless })}>
          {title && (
            <header className="organizer-layout__content-title">
              {back && <BackIcon role="button" onClick={() => navigate(-1)} />}
              <section className={getClassNames('organizer-layout__title-wrapper', { back: back })}>
                <h4>{title}</h4>
                {subtitle && <h6>{subtitle}</h6>}
              </section>
            </header>
          )}
          {children}
        </section>
      </section>
    </section>
  );
};

export default OrganizerLayout;
