import React from 'react';

import { getClassNames } from '@neslotech/utils';

import './badge.scss';

const Badge = ({ title, large, image, onImageClick, small, medium, id }) => (
  <section
    id={id}
    className={getClassNames('badge', { large, medium, small })}
    onClick={onImageClick}
  >
    <div className="badge__icon">{image}</div>
    {title && <label className="badge__title">{title}</label>}
  </section>
);

export default Badge;
