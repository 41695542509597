import axios from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import { SNACK_CRITICAL } from '@neslotech/utils';

import {
  getAcceptEntryRequest,
  getDownloadEntriesRequest,
  getLoadEntriesRequest,
  getLoadEntryRequest,
  getRejectEntryRequest
} from '../tools/api/entry.endpoints';

import { addSystemNotice } from '../actions/system.actions';
import {
  ACCEPT_ENTRY,
  DOWNLOAD_ENTRIES,
  LOAD_ENTRIES,
  LOAD_ENTRY,
  loadEntry,
  REJECT_ENTRY,
  SET_ENTRIES,
  SET_ENTRY
} from '../actions/entry.actions';

export function* performLoadEntries({ id, onSuccess }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadEntriesRequest(id);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_ENTRIES, entries: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    yield put(addSystemNotice(response?.data?.error ?? 'Failed to load entries.', SNACK_CRITICAL));
  }
}

export function* watchForLoadEntriesRequest() {
  yield takeLatest(LOAD_ENTRIES, performLoadEntries);
}

export function* performDownloadEntries({ id }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getDownloadEntriesRequest(id);

    const { data, headers } = yield call(axios, endpoint, requestOptions);

    // Get the Content-Disposition header value from the response
    const contentDisposition = headers['content-disposition'];

    // Use a regular expression to extract the file name from the header value
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = regex.exec(contentDisposition);
    const filename = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'unknown';

    const csvBlob = new Blob([data], { type: 'text/csv' });
    const anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = window.URL.createObjectURL(csvBlob);
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to download entries.', SNACK_CRITICAL)
    );
  }
}

export function* watchForDownloadEntriesRequest() {
  yield takeLatest(DOWNLOAD_ENTRIES, performDownloadEntries);
}

export function* performLoadEntry({ id, entryId, onSuccess }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadEntryRequest(id, entryId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_ENTRY, entry: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    yield put(addSystemNotice(response?.data?.error ?? 'Failed to load entry.', SNACK_CRITICAL));
  }
}

export function* watchForLoadEntryRequest() {
  yield takeLatest(LOAD_ENTRY, performLoadEntry);
}

export function* performRejectEntry({ id, entryId, payload }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getRejectEntryRequest(id, entryId, payload);

    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('This entry was rejected successfully.'));

    yield put(loadEntry(id, entryId));
  } catch ({ response }) {
    yield put(addSystemNotice(response?.data?.error ?? 'Failed to reject entry.', SNACK_CRITICAL));
  }
}

export function* watchForRejectEntryRequest() {
  yield takeLatest(REJECT_ENTRY, performRejectEntry);
}

export function* performAcceptEntry({ id, entryId }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getAcceptEntryRequest(id, entryId);

    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('This entry was accepted successfully.'));

    yield put(loadEntry(id, entryId));
  } catch ({ response }) {
    yield put(addSystemNotice(response?.data?.error ?? 'Failed to accept entry.', SNACK_CRITICAL));
  }
}

export function* watchForAcceptEntryRequest() {
  yield takeLatest(ACCEPT_ENTRY, performAcceptEntry);
}

export default function* entrySaga() {
  yield all([
    watchForLoadEntriesRequest(),
    watchForLoadEntryRequest(),
    watchForRejectEntryRequest(),
    watchForAcceptEntryRequest(),
    watchForDownloadEntriesRequest()
  ]);
}
