import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { capitalise } from '@neslotech/utils/build';
import { DRAFT_STATUS, Modal, ModalLayout, PUBLISHED_STATUS } from '@neslotech/eventhub-ui-kit';

import { saveTemplate } from '../../../../actions/template.actions';

import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';

import SaveEntryFormTemplate from '../../../../components/competitions/templates/SaveEntryFormTemplate';

const SaveEntryFormTemplateContainer = ({ template, show, setShowModal, context }) => {
  const dispatch = useDispatch();

  const { id } = useOrganisationContext();

  const pageTitle = useMemo(() => {
    switch (template?.status) {
      case PUBLISHED_STATUS:
        return 'Save as Template';
      case DRAFT_STATUS:
        return `Save Template as a ${capitalise(template?.status)}`;
      default:
        return '';
    }
  }, [template?.status]);

  const onClose = () => setShowModal(false);

  const handleConfirm = (payload) => {
    dispatch(saveTemplate(id, { ...template, ...payload }, context));
    onClose();
  };

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout onClose={onClose} title={pageTitle}>
            <SaveEntryFormTemplate
              status={template?.status}
              onConfirmClick={handleConfirm}
              onCancelClick={onClose}
            />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default SaveEntryFormTemplateContainer;
