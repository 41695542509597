import React, { useMemo } from 'react';

import { Datepicker, Form, FormRow, Input, RadioGroup, Select } from '@neslotech/eventhub-ui-kit';
import { getTomorrowFromDate } from '@neslotech/utils';

import { useWizardContext } from '../../../../hooks/useWizardContext';

import '../add-competition.scss';

const CompetitionDetails = ({ form, handleChange }) => {
  const { sports, onSetSport } = useWizardContext();

  const mappedSports = useMemo(() => {
    return (sports ?? []).map((sport) => ({
      text: sport.name,
      value: sport.id,
      onClick: () => {
        handleChange({ sportId: sport.id, sport: sport.name });
        onSetSport(sport);
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sports, form]);

  const handleStartDateChange = (newForm) => {
    if (newForm?.startDate > form?.endDate) {
      handleChange({ ...newForm, endDate: getTomorrowFromDate(newForm.startDate) });
    } else {
      handleChange(newForm);
    }
  };

  return (
    <section className="add-competition-details">
      <Form>
        <FormRow fluidSpaced>
          <Input
            name="name"
            placeholder="Competition Name"
            label="Competition Name"
            value={form?.name}
            error={form?.messages?.name}
            onChange={handleChange}
          />
          <Select
            name="competitionType"
            placeholder="Type of Competition"
            label="Type of Competition"
            value={form?.competitionType}
            items={[
              {
                text: 'League',
                value: 'League',
                onClick: () => handleChange({ competitionType: 'League' })
              },
              {
                text: 'Knockout',
                value: 'Knockout',
                onClick: () => handleChange({ competitionType: 'Knockout' })
              },
              {
                text: 'Round Robin',
                value: 'Round Robin',
                onClick: () => handleChange({ competitionType: 'Round Robin' })
              }
            ]}
            error={form?.messages?.competitionType}
          />
        </FormRow>
        <FormRow fluidSpaced>
          <Datepicker
            name="startDate"
            placeholder="Start Date"
            label="Start Date"
            value={form?.startDate}
            error={form?.messages?.startDate}
            onChange={handleStartDateChange}
          />
          <Datepicker
            name="endDate"
            placeholder="End Date"
            label="End Date"
            value={form?.endDate}
            error={form?.messages?.endDate}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow fluidSpaced>
          <Datepicker
            name="entryStartDate"
            placeholder="Entry Start Date"
            label="Entry Start Date"
            value={form?.entryStartDate}
            error={form?.messages?.entryStartDate}
            onChange={handleStartDateChange}
          />
          <Datepicker
            name="entryEndDate"
            placeholder="Entry End Date"
            label="Entry End Date"
            value={form?.entryEndDate}
            error={form?.messages?.entryEndDate}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow fluidSpaced>
          <Select
            name="sport"
            placeholder="Sport"
            label="Sport"
            value={form?.sport}
            items={mappedSports}
            error={form?.messages?.sport}
          />
          <RadioGroup
            name="suppliedBankingDetails"
            label="Are you using your supplied banking details?"
            fluid
            value={form?.suppliedBankingDetails}
            error={form?.messages?.suppliedBankingDetails}
            onChange={handleChange}
            items={[
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]}
          />
        </FormRow>
        <FormRow fluidSpaced>
          <RadioGroup
            name="national"
            label="National or Regional Competition"
            fluid
            value={form?.national}
            onChange={handleChange}
            items={[
              { label: 'National', value: true },
              { label: 'Regional', value: false }
            ]}
          />
          <RadioGroup
            name="visibleEntries"
            label="Do you want participants entries to be visible on the landing page?"
            fluid
            value={form?.visibleEntries}
            onChange={handleChange}
            items={[
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]}
          />
        </FormRow>
        <FormRow fluidSpaced>
          <RadioGroup
            name="paid"
            label="Paid or Free Competition"
            fluid
            value={form?.paid}
            onChange={handleChange}
            items={[
              { label: 'Paid', value: true },
              { label: 'Free', value: false }
            ]}
          />
          <RadioGroup
            name="regulated"
            label="Regulated Competition"
            fluid
            value={form?.regulated}
            onChange={handleChange}
            items={[
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]}
          />
        </FormRow>
      </Form>
      {(form.paid || form.regulated) && (
        <FormRow
          singleRow={(form.paid && !form.regulated) || (!form.paid && form.regulated)}
          fluidSpaced={form.paid && form.regulated}
        >
          {form.paid && (
            <Input
              name="registrationFee"
              placeholder="Registration Fee"
              label="Registration Fee"
              type="number"
              prefix="R"
              value={form?.registrationFee}
              onChange={handleChange}
            />
          )}
          {form.regulated && (
            <Input
              name="regulationNumber"
              placeholder="Regulation Number"
              label="Regulation Number"
              value={form?.regulationNumber}
              onChange={handleChange}
            />
          )}
        </FormRow>
      )}
    </section>
  );
};

export default CompetitionDetails;
