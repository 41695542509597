import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ACTIVE_STATUS,
  Button,
  CANCELLED_STATUS,
  Card,
  ContextDetails,
  DetailsSection,
  DRAFT_STATUS,
  Form,
  FormRow,
  InfoCard,
  Input,
  RadioGroup,
  Scroller,
  Select,
  Table,
  TextArea,
  UPCOMING_STATUS
} from '@neslotech/eventhub-ui-kit';
import { capitalise, isEmpty } from '@neslotech/utils';

import { classesTableHeaders, membersTableHeaders } from './competition-details.helpers';

import { ReactComponent as CalendarIcon } from '../../../icons/calendar-icon.svg';
import { ReactComponent as EditIcon } from '../../../icons/blue-edit-icon.svg';
import defaultImage from '../../../images/details-default-image.svg';

import FormCanvas from '../../form-builder/FormCanvas';

import './competition-details.scss';

const CompetitionDetailsSection = ({ competition }) => (
  <section className="competition-details-section">
    <Form>
      <FormRow fluidSpaced>
        <Input
          placeholder="Competition Name"
          label="Competition Name"
          value={competition.name}
          disabled
        />
        <Select
          placeholder="Type of Competition"
          label="Type of Competition"
          value={competition.competition_type}
          disabled
        />
      </FormRow>
      <FormRow fluidSpaced>
        <Select placeholder="Sport" label="Sport" value={competition.sport} disabled />
        <Input
          placeholder="Start and End Date"
          label="Start and End Date"
          action={<CalendarIcon />}
          value={`${competition.start_date} - ${competition.end_date}`}
          disabled
        />
      </FormRow>
      <FormRow fluidSpaced>
        <Input
          placeholder="Entry Start Date"
          label="Entry Start Date"
          action={<CalendarIcon />}
          value={competition.entry_start_date}
          disabled
        />
        <Input
          placeholder="Entry End Date"
          label="Entry End Date"
          action={<CalendarIcon />}
          value={competition.entry_end_date}
          disabled
        />
      </FormRow>
      <FormRow fluidSpaced>
        <RadioGroup
          label="Are you using your supplied banking details?"
          disabled
          fluid
          name="supplied_banking_details"
          value={competition.supplied_banking_details}
          items={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
        />
        <RadioGroup
          label="National or Regional Competition"
          disabled
          fluid
          name="national"
          value={competition.national}
          items={[
            { label: 'National', value: true },
            { label: 'Regional', value: false }
          ]}
        />
      </FormRow>
      <FormRow fluidSpaced>
        <RadioGroup
          label="Paid or Free Competition"
          disabled
          fluid
          name="paid"
          value={competition.paid}
          items={[
            { label: 'Paid', value: true },
            { label: 'Free', value: false }
          ]}
        />
        <RadioGroup
          label="Regulated Competition"
          disabled
          fluid
          name="regulated"
          value={competition.regulated}
          items={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
        />
      </FormRow>
      {(competition.paid || competition.regulated) && (
        <FormRow
          singleRow={
            (competition.paid && !competition.regulated) ||
            (!competition.paid && competition.regulated)
          }
          fluidSpaced={competition.paid && competition.regulated}
        >
          {competition.paid && (
            <Input
              name="registrationFee"
              placeholder="Registration Fee"
              label="Registration Fee"
              value={competition?.registration_fee}
              disabled
            />
          )}
          {competition.regulated && (
            <Input
              name="regulationNumber"
              placeholder="Regulation Number"
              label="Regulation Number"
              value={competition?.regulation_number}
              disabled
            />
          )}
        </FormRow>
      )}
      <FormRow>
        <RadioGroup
          label="Do you want participants entries to be visible on the landing page?"
          disabled
          fluid
          name="visible_entries"
          value={competition.visible_entries}
          items={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
        />
      </FormRow>
    </Form>
  </section>
);

const BankingDetailsSection = ({ competition }) => (
  <DetailsSection title="Banking Details">
    <section className="competition-details-section">
      <Form>
        <FormRow fluidSpaced>
          <Select
            placeholder="Account Type"
            name="bankAccountType"
            label="Account Type"
            value={capitalise(competition?.banking_detail?.account_type)}
            disabled
          />
          <Input
            placeholder="Account Number"
            label="Account Number"
            value={competition?.banking_detail?.account_number}
            disabled
          />
        </FormRow>
        <FormRow fluidSpaced>
          <Input
            placeholder="Bank Name"
            label="Bank Name"
            value={competition?.banking_detail?.bank_name}
            disabled
          />
          <Input
            placeholder="Branch Number"
            label="Branch Number"
            value={competition?.banking_detail?.branch_number}
            disabled
          />
        </FormRow>
      </Form>
    </section>
  </DetailsSection>
);

const DescriptionSection = ({ competition }) => (
  <DetailsSection title="Competition Description">
    <section className="description-section">
      <Form>
        <FormRow>
          <TextArea
            placeholder="Enter Competition Description"
            value={competition.description}
            disabled
          />
        </FormRow>
      </Form>
    </section>
  </DetailsSection>
);

const ClassesSection = ({ competition }) => {
  const mappedClass = useMemo(() => {
    return (competition.classes ?? []).map((klass) => ({
      name: klass.name,
      firstClassPrice: klass.first_class_price,
      secondClassPrice: klass.second_class_price,
      firstClassFamilyPrice: klass.first_class_family_price,
      secondClassFamilyPrice: klass.second_class_family_price
    }));
  }, [competition.classes]);

  return (
    <DetailsSection title="Classes in the Competition">
      <section className="classes-section">
        <Table cols={classesTableHeaders} rowData={mappedClass} />
      </section>
    </DetailsSection>
  );
};

const MembersSection = ({ competition }) => (
  <DetailsSection title="Member Selection">
    <section className="members-section">
      <Table cols={membersTableHeaders} rowData={competition.members} />
    </section>
  </DetailsSection>
);

const mapCards = (events, onOpenEvent) =>
  events.map((event) => (
    <InfoCard
      onClick={() => onOpenEvent(event.id)}
      key={event.id}
      name={event.name}
      description={event.description}
      date={event.start_date}
      image={<img src={event.image ?? defaultImage} alt={event.name} />}
    />
  ));

const EventsSection = ({ competition, onOpenEvent }) => (
  <section className="events-section">
    <Card>
      <p>Events in the competition</p>
      <Scroller items={mapCards(competition.events, onOpenEvent)} />
    </Card>
  </section>
);

const tableHeaders = [
  { Header: 'Rider Place', accessor: 'place' },
  { Header: 'Points per place', accessor: 'points' }
];
const PointsConfigurationSection = ({ competition }) => (
  <section className="add-preview__points-config">
    <DetailsSection title="Points Configuration Details">
      <Table cols={tableHeaders} rowData={competition.points_configuration.positions} />
    </DetailsSection>
    <DetailsSection title="" subtitle="Points configuration description">
      <TextArea
        placeholder="Enter your description"
        value={competition.points_configuration.description}
      />
    </DetailsSection>
  </section>
);

const EntryFormSection = () => (
  <section className="add-preview__entry-form">
    <FormCanvas />
  </section>
);

const CompetitionDetails = ({
  competition,
  onCancel,
  onDelete,
  onReinstate,
  onPostpone,
  onPublish,
  verified
}) => {
  const navigate = useNavigate();

  const onOpenEvent = (id) => {
    navigate(`/competitions/${competition.id}/events/${id}`);
  };

  const onAddEvent = () => {
    navigate(`/competitions/${competition.id}/events/add`);
  };

  const actions = useMemo(() => {
    switch (competition.status) {
      case ACTIVE_STATUS:
      case UPCOMING_STATUS:
        return [
          <Button
            disabled={!verified}
            key="postpone"
            label="Postpone"
            hollow
            onClick={onPostpone}
          />,
          <Button disabled={!verified} key="cancel" label="Cancel" danger onClick={onCancel} />
        ];
      case CANCELLED_STATUS:
        return [
          <Button disabled={!verified} key="reinstate" label="Reinstate" onClick={onReinstate} />,
          <Button disabled={!verified} key="delete" label="Delete" danger onClick={onDelete} />
        ];
      case DRAFT_STATUS:
        return [
          <Button disabled={!verified} key="publish" label="Publish" onClick={onPublish} />,
          <Button disabled={!verified} key="delete" label="Delete" danger onClick={onDelete} />
        ];
      default:
        return [];
    }
  }, [competition.status, onCancel, onDelete, onReinstate, onPostpone, onPublish, verified]);

  const memoizedCompetition = useMemo(() => {
    if (!competition.image) {
      competition.image = defaultImage;
    }

    return competition;
  }, [competition]);

  return (
    <section className="competition-details">
      <ContextDetails context={memoizedCompetition} actions={actions} />
      <section className="competition-details__details">
        <DetailsSection
          title="Competition Details"
          icon={verified && <EditIcon onClick={() => navigate('edit')} />}
        >
          <CompetitionDetailsSection competition={competition} />
        </DetailsSection>
        {competition.banking_detail && <BankingDetailsSection competition={competition} />}
        <DescriptionSection competition={competition} />
        <ClassesSection competition={competition} />
        <MembersSection competition={competition} />
        {!isEmpty(competition?.points_configuration?.positions) && (
          <PointsConfigurationSection competition={competition} />
        )}
        {competition.entry_form && <EntryFormSection />}
        {!isEmpty(competition.events) && (
          <EventsSection competition={competition} onOpenEvent={onOpenEvent} />
        )}
        {(competition.status === UPCOMING_STATUS || competition.status === ACTIVE_STATUS) && (
          <div className="competition-details__details-action">
            <Button disabled={!verified} label="Create Event" hollow onClick={onAddEvent} />
          </div>
        )}
      </section>
    </section>
  );
};

export default CompetitionDetails;
