import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  Accordion,
  ACTIVE_STATUS,
  Form,
  FormRow,
  Select,
  UPCOMING_STATUS
} from '@neslotech/eventhub-ui-kit';

import { LINK, STANDALONE } from '../../AddEvent';

import './event-details-requirements.scss';

const EventDetailsRequirements = ({ form, handleChange }) => {
  const competitions = useSelector(({ competition_store }) => competition_store.competitions);

  const competitionItems = useMemo(() => {
    return competitions
      .filter(({ status }) => status === UPCOMING_STATUS || status === ACTIVE_STATUS)
      .map((competition) => ({
        text: competition.name,
        value: competition.name,
        onClick: () => handleChange({ competition })
      }));
  }, [competitions, handleChange]);

  return (
    <Accordion title="Event Details Requirements" open>
      <section className="event-details-requirements">
        <Form>
          <FormRow>
            <Select
              name="type"
              placeholder="Select an option that suits your events needs"
              label="Would you like to link this to an existing competition or create a stand alone event?"
              value={form?.type}
              items={[
                {
                  text: LINK,
                  value: LINK,
                  onClick: () => handleChange({ type: LINK })
                },
                {
                  text: STANDALONE,
                  value: STANDALONE,
                  onClick: () => handleChange({ type: STANDALONE })
                }
              ]}
            />
          </FormRow>
          {form?.type === LINK && (
            <FormRow>
              <Select
                name="competition"
                placeholder="Select an option that suits your events needs"
                label="Which competition would you like to link this event to?"
                value={form?.competition?.name}
                items={competitionItems}
              />
            </FormRow>
          )}
        </Form>
      </section>
    </Accordion>
  );
};

export default EventDetailsRequirements;
