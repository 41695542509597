import React from 'react';

import { generateId, getClassNames } from '@neslotech/utils';
import {
  Accordion,
  CHECKBOX,
  DATEPICKER,
  DROPDOWN,
  FILE_UPLOAD,
  INPUT,
  MULTI_DROPDOWN,
  RADIO_GROUP,
  TEXT_AREA,
  TIMEPICKER,
  YEARPICKER
} from '@neslotech/eventhub-ui-kit';

import { useFormBuilderContext } from '../../../hooks/useFormBuilderContext';

import { ReactComponent as InputIcon } from '../../../icons/form-builder/plain-text-icon.svg';
import { ReactComponent as DescriptionIcon } from '../../../icons/form-builder/description-icon.svg';
import { ReactComponent as DropdownIcon } from '../../../icons/form-builder/dropdown-icon.svg';
import { ReactComponent as TimepickerIcon } from '../../../icons/form-builder/timepicker-icon.svg';
import { ReactComponent as DatepickerIcon } from '../../../icons/form-builder/datepicker-icon.svg';
import { ReactComponent as CheckboxIcon } from '../../../icons/form-builder/checkbox-icon.svg';
import { ReactComponent as FileUploadIcon } from '../../../icons/form-builder/file-upload-icon.svg';
import { ReactComponent as RadioButtonIcon } from '../../../icons/form-builder/radio-button-icon.svg';
import { ReactComponent as YearSelectorIcon } from '../../../icons/form-builder/year-selector-icon.svg';

import './widget-selector.scss';

const SelectorItem = ({ item, Icon, onSelect }) => (
  <article className="selector-item" onClick={() => onSelect(item)}>
    <Icon />
    <small className="selector-item__title">{item.title}</small>
  </article>
);

const textFieldsItems = [
  {
    icon: InputIcon,
    title: 'Plain Text Field',
    getDefaultContext: () => ({
      id: generateId(),
      type: INPUT,
      required: false,
      filled: false
    })
  },
  {
    icon: DescriptionIcon,
    title: 'Description',
    getDefaultContext: () => ({
      id: generateId(),
      type: TEXT_AREA,
      required: false,
      filled: true
    })
  },
  {
    icon: DropdownIcon,
    title: 'Multi Selector Dropdown',
    getDefaultContext: () => ({
      id: generateId(),
      type: MULTI_DROPDOWN,
      items: [],
      required: false,
      filled: false
    })
  },
  {
    icon: DropdownIcon,
    title: 'Single Selector Dropdown',
    getDefaultContext: () => ({
      id: generateId(),
      type: DROPDOWN,
      items: [],
      required: false,
      filled: false
    })
  }
];

const formsItems = [
  {
    icon: TimepickerIcon,
    title: 'Time Picker',
    getDefaultContext: () => ({
      id: generateId(),
      type: TIMEPICKER,
      required: false,
      filled: false
    })
  },
  {
    icon: DatepickerIcon,
    title: 'Date Picker',
    getDefaultContext: () => ({
      id: generateId(),
      type: DATEPICKER,
      required: false,
      filled: false
    })
  },
  {
    icon: CheckboxIcon,
    title: 'Checkbox',
    getDefaultContext: () => ({
      id: generateId(),
      type: CHECKBOX,
      required: false,
      filled: false
    })
  },
  {
    icon: FileUploadIcon,
    title: 'File Upload',
    getDefaultContext: () => ({
      id: generateId(),
      type: FILE_UPLOAD,
      required: false,
      filled: true
    })
  },
  {
    icon: RadioButtonIcon,
    title: 'Radio Selector',
    getDefaultContext: () => ({
      id: generateId(),
      type: RADIO_GROUP,
      items: [],
      required: false,
      filled: false
    })
  },
  {
    icon: YearSelectorIcon,
    title: 'Year Selector',
    getDefaultContext: () => ({
      id: generateId(),
      type: YEARPICKER,
      required: false,
      filled: false
    })
  }
];

const WidgetSelector = ({ open }) => {
  const { onAddElement } = useFormBuilderContext();

  const handleSelection = (item) => {
    const context = item.getDefaultContext();
    onAddElement(context);
  };

  return (
    <article className={getClassNames('widget-selector', { open })}>
      <Accordion title="Text Fields" open alternate>
        <section className="widget-selector__widgets">
          {textFieldsItems.map((item) => (
            <SelectorItem
              key={item.title}
              onSelect={handleSelection}
              item={item}
              Icon={item.icon}
            />
          ))}
        </section>
      </Accordion>
      <Accordion title="Forms" open alternate>
        <section className="widget-selector__widgets">
          {formsItems.map((item) => (
            <SelectorItem
              key={item.title}
              onSelect={handleSelection}
              item={item}
              Icon={item.icon}
            />
          ))}
        </section>
      </Accordion>
    </article>
  );
};

export default WidgetSelector;
