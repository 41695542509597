import React, { useMemo } from 'react';

import { capitalise } from '@neslotech/utils';
import {
  Button,
  ButtonGroup,
  ENTRY_FORM_TYPE,
  PUBLISHED_STATUS,
  TextArea
} from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import './save-entry-form-template.scss';

const rules = {
  validates: {
    name: ['isPresent']
  }
};

const serverify = (form) => ({
  name: form.name,
  template_type: ENTRY_FORM_TYPE
});

const SaveEntryFormTemplate = ({ onConfirmClick, onCancelClick, status }) => {
  const [form, setForm] = useFormState(undefined, rules);

  const statusContext = useMemo(() => {
    return status === PUBLISHED_STATUS ? 'template' : status;
  }, [status]);

  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleConfirm = () => {
    const payload = serverify(form);
    onConfirmClick(payload);
  };

  return (
    <section className="save-entry-form-template">
      <section className="save-entry-form-template__message">
        <p>What would you like to name your entry form {statusContext}?</p>
      </section>
      <section className="save-entry-form-template__form">
        <TextArea
          label={`Entry Form ${capitalise(statusContext)} Name`}
          shadowed
          name="name"
          rows={3}
          placeholder={`Enter entry form ${statusContext} name`}
          onChange={handleChange}
          value={form?.name}
        />
      </section>
      <ButtonGroup>
        <Button label="Confirm" disabled={!form?.valid} onClick={handleConfirm} />
        <Button label="Cancel" onClick={onCancelClick} secondary />
      </ButtonGroup>
    </section>
  );
};

export default SaveEntryFormTemplate;
