import {
  SET_BANKING_DETAILS,
  SET_ORGANISATION,
  SET_ORGANISER
} from '../actions/organisation.actions';

export const initialState = {
  organisation: undefined,
  organiser: undefined,
  bankingDetails: undefined
};

export const organisationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANISATION:
      const { organisation } = action;
      return {
        ...state,
        organisation
      };
    case SET_BANKING_DETAILS:
      const { bankingDetails } = action;
      return {
        ...state,
        bankingDetails
      };
    case SET_ORGANISER:
      const { organiser } = action;
      return {
        ...state,
        organiser
      };
    default:
      return state;
  }
};
