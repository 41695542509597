import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { PageLayout } from '@neslotech/eventhub-ui-kit';

import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';

import { saveTemplate } from '../../../../actions/template.actions';

import OrganizerLayout from '../../../../components/layout/organizer/OrganizerLayout';
import AddEntryFormTemplate from '../../../../components/templates/add/AddEntryFormTemplate';

const AddEntryFormTemplateContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useOrganisationContext();

  const onAdd = (payload) => {
    dispatch(saveTemplate(id, payload));
    navigate('/templates');
  };

  return (
    <OrganizerLayout gutterless>
      <PageLayout title="Create Entry Form Template" back>
        <AddEntryFormTemplate onAdd={onAdd} />
      </PageLayout>
    </OrganizerLayout>
  );
};

export default AddEntryFormTemplateContainer;
