import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getOrganisationIdCookie, setOrganisationIdCookie } from '../tools/organisation.util';

import { OrganisationContext } from '../contexts/Organisation.context';

import { loadOrganisation } from '../actions/organisation.actions';

const OrganisationProvider = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [organisationId, setOrganisationId] = useState(getOrganisationIdCookie());
  const contextId = getOrganisationIdCookie();

  const verified = useSelector(
    ({ organisation_store }) => organisation_store.organisation?.verified ?? false
  );

  useEffect(() => {
    setOrganisationId(contextId);
    dispatch(loadOrganisation(contextId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextId]);

  const handleOrganisationChange = ({ id }) => {
    setOrganisationIdCookie(id);
    setOrganisationId(id);
    navigate('/dashboard');
  };

  const value = {
    id: organisationId,
    onOrganisationChange: handleOrganisationChange,
    verified
  };

  return <OrganisationContext.Provider value={value}>{children}</OrganisationContext.Provider>;
};

export default OrganisationProvider;
