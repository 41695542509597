import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ACTIVE_STATUS,
  Button,
  CANCELLED_STATUS,
  COMPLETED_STATUS,
  DRAFT_STATUS,
  filterElement,
  searchElement,
  Tabs,
  UPCOMING_STATUS,
  useFilters
} from '@neslotech/eventhub-ui-kit';
import { isEmpty } from '@neslotech/utils';

import { ReactComponent as ActiveIcon } from '../../icons/active-icon.svg';
import { ReactComponent as UpcomingIcon } from '../../icons/upcoming-icon.svg';
import { ReactComponent as CheckIcon } from '../../icons/check-icon.svg';
import { ReactComponent as NoEntryIcon } from '../../icons/no-entry-icon.svg';
import { ReactComponent as DraftIcon } from '../../icons/draft-icon.svg';

import CompetitionCard from '../card/EventCard';
import EmptyMessage from '../empty/EmptyMessage';

import './competitions-list.scss';

const tabs = (filter) => [
  {
    key: UPCOMING_STATUS,
    label: 'Upcoming',
    icon: <UpcomingIcon />,
    onClick: () => filter(UPCOMING_STATUS)
  },
  {
    key: ACTIVE_STATUS,
    label: 'Active',
    icon: <ActiveIcon />,
    onClick: () => filter(ACTIVE_STATUS)
  },
  {
    key: COMPLETED_STATUS,
    label: 'Completed',
    icon: <CheckIcon />,
    onClick: () => filter(COMPLETED_STATUS)
  },
  {
    key: CANCELLED_STATUS,
    label: 'Cancelled',
    icon: <NoEntryIcon />,
    onClick: () => filter(CANCELLED_STATUS)
  },
  {
    key: DRAFT_STATUS,
    label: 'Drafts',
    icon: <DraftIcon />,
    onClick: () => filter(DRAFT_STATUS)
  }
];

const CompetitionsList = ({ competitions, verified }) => {
  const navigate = useNavigate();
  const { search, filters, filtering, searching, setFilterDefaults } = useFilters();

  const [tabbedCompetitions, setTabbedCompetitions] = useState([]);

  const filteredCompetitions = useMemo(() => {
    return tabbedCompetitions
      .filter(
        (competition) =>
          searchElement(competition.name, search) ||
          searchElement(competition.sport, search) ||
          searchElement(competition.created_by, search)
      )
      .filter((competition) => filterElement(competition, filters));
  }, [tabbedCompetitions, search, filters]);

  const emptyMessage = useMemo(() => {
    return filtering || searching
      ? 'There are no competitions listed under that name.'
      : 'This table is empty. There are no competitions listed yet.';
  }, [filtering, searching]);

  useEffect(
    () => {
      setFilterDefaults({ status: '', regulated: '', national: '' });
      filterCompetitions();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [competitions]
  );

  const filterCompetitions = (type) => {
    switch (type) {
      case COMPLETED_STATUS:
        setTabbedCompetitions(competitions.filter((item) => item.status === COMPLETED_STATUS));
        break;
      case CANCELLED_STATUS:
        setTabbedCompetitions(competitions.filter((item) => item.status === CANCELLED_STATUS));
        break;
      case DRAFT_STATUS:
        setTabbedCompetitions(competitions.filter((item) => item.status === DRAFT_STATUS));
        break;
      default:
      case UPCOMING_STATUS:
        setTabbedCompetitions(competitions.filter((item) => item.status === UPCOMING_STATUS));
        break;
      case ACTIVE_STATUS:
        setTabbedCompetitions(competitions.filter((item) => item.status === ACTIVE_STATUS));
        break;
    }
  };

  return (
    <section className="competitions-list">
      <section className="competitions-list__filters">
        <Tabs tabs={tabs(filterCompetitions)} vertical />
      </section>
      <section className="competitions-list__list">
        {isEmpty(filteredCompetitions) && (
          <EmptyMessage
            type="competition"
            message={emptyMessage}
            action={
              <Button
                label="Create Competition"
                hollow
                onClick={() => navigate('/competitions/add')}
                disabled={!verified}
              />
            }
          />
        )}
        {filteredCompetitions.map((competition) => (
          <CompetitionCard
            key={competition.id}
            entity={competition}
            type="Competition"
            onClick={() => navigate(`/competitions/${competition.id}`)}
          />
        ))}
      </section>
    </section>
  );
};

CompetitionsList.defaultProps = {
  competitions: []
};

export default CompetitionsList;
