import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPatchOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

import { API_HOST } from './index';

const getCompetitionsEndpoint = (id) => `${API_HOST}/organisations/${id}/competitions`;
export const getLoadCompetitionsRequest = (id) => [
  getCompetitionsEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getCreateCompetitionsRequest = (id, payload) => [
  getCompetitionsEndpoint(id),
  getHttpPostOptions(payload, getAuthHeaders())
];

const getCompetitionEndpoint = (id, competitionId) =>
  `${API_HOST}/organisations/${id}/competitions/${competitionId}`;
export const getLoadCompetitionRequest = (id, competitionId) => [
  getCompetitionEndpoint(id, competitionId),
  getHttpGetOptions(getAuthHeaders())
];

export const getEditCompetitionRequest = (id, competitionId, payload) => [
  getCompetitionEndpoint(id, competitionId),
  getHttpPutOptions(payload, getAuthHeaders())
];

export const getRemoveCompetitionRequest = (id, competitionId) => [
  getCompetitionEndpoint(id, competitionId),
  getHttpDeleteOptions(getAuthHeaders())
];

const getCompetitionClassesEndpoint = (id, competitionId) =>
  `${getCompetitionEndpoint(id, competitionId)}/classes`;
export const getLoadCompetitionClassesRequest = (id, competitionId) => [
  getCompetitionClassesEndpoint(id, competitionId),
  getHttpGetOptions(getAuthHeaders())
];

const getCompetitionMembersEndpoint = (id, competitionId) =>
  `${getCompetitionEndpoint(id, competitionId)}/members`;
export const getLoadCompetitionMemberTypesRequest = (id, competitionId) => [
  getCompetitionMembersEndpoint(id, competitionId),
  getHttpGetOptions(getAuthHeaders())
];

const getCancelCompetitionEndpoint = (id, competitionId) =>
  `${getCompetitionEndpoint(id, competitionId)}/cancel`;
export const getCancelCompetitionRequest = (id, competitionId, payload) => [
  getCancelCompetitionEndpoint(id, competitionId),
  getHttpPatchOptions(payload, getAuthHeaders())
];

const getReinstateCompetitionEndpoint = (id, competitionId) =>
  `${getCompetitionEndpoint(id, competitionId)}/reinstate`;
export const getReinstateCompetitionRequest = (id, competitionId) => [
  getReinstateCompetitionEndpoint(id, competitionId),
  getHttpPatchOptions(undefined, getAuthHeaders())
];

const getPostponeCompetitionEndpoint = (id, competitionId) =>
  `${getCompetitionEndpoint(id, competitionId)}/postpone`;
export const getPostponeCompetitionRequest = (id, competitionId, payload) => [
  getPostponeCompetitionEndpoint(id, competitionId),
  getHttpPatchOptions(payload, getAuthHeaders())
];

const getCompetitionPointsConfigurationEndpoint = (id, competitionId) =>
  `${getCompetitionEndpoint(id, competitionId)}/points-configuration`;
export const getLoadCompetitionPointsConfigurationRequest = (id, competitionId) => [
  getCompetitionPointsConfigurationEndpoint(id, competitionId),
  getHttpGetOptions(getAuthHeaders())
];

const getCompetitionEntryFormEndpoint = (id, competitionId) =>
  `${getCompetitionEndpoint(id, competitionId)}/entry-form`;
export const getLoadCompetitionEntryFormRequest = (id, competitionId) => [
  getCompetitionEntryFormEndpoint(id, competitionId),
  getHttpGetOptions(getAuthHeaders())
];

const getCompetitionDetailsEndpoint = (id) =>
  `${API_HOST}/organisations/${id}/competitions/details`;
export const getSaveCompetitionsDetailsRequest = (id, payload) => [
  getCompetitionDetailsEndpoint(id),
  getHttpPostOptions(payload, getAuthHeaders())
];

const getCompetitionDetailEndpoint = (id, competitionId) =>
  `${API_HOST}/organisations/${id}/competitions/${competitionId}/details`;
export const getUpdateCompetitionsDetailsRequest = (id, competitionId, payload) => [
  getCompetitionDetailEndpoint(id, competitionId),
  getHttpPutOptions(payload, getAuthHeaders())
];

export const getSaveCompetitionsPointsConfigRequest = (id, competitionId, payload) => [
  getCompetitionPointsConfigurationEndpoint(id, competitionId),
  getHttpPostOptions(payload, getAuthHeaders())
];

export const getSaveCompetitionsEntryFormRequest = (id, competitionId, payload) => [
  getCompetitionEntryFormEndpoint(id, competitionId),
  getHttpPostOptions(payload, getAuthHeaders())
];

const getDraftCompetitionEndpoint = (id, competitionId) =>
  `${getCompetitionEndpoint(id, competitionId)}/draft`;
export const getSaveCompetitionsDraftRequest = (id, competitionId) => [
  getDraftCompetitionEndpoint(id, competitionId),
  getHttpPatchOptions(undefined, getAuthHeaders())
];

const getPublishCompetitionEndpoint = (id, competitionId) =>
  `${getCompetitionEndpoint(id, competitionId)}/publish`;
export const getPublishCompetitionRequest = (id, competitionId) => [
  getPublishCompetitionEndpoint(id, competitionId),
  getHttpPatchOptions(undefined, getAuthHeaders())
];
