import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Dropdown } from '@neslotech/eventhub-ui-kit';

import { useOrganisationContext } from '../../../hooks/useOrganisationContext';
import { switchOrganisationItems } from './switch-organisation.helpers';

import { retrieveProfile } from '../../../actions/profile.action';

import { ReactComponent as ChevronIconRight } from '../../../icons/chevron-right-icon.svg';

import OrganisationBadge from '../badge/OrganisationBadge';

import './organisation-switch.scss';

const OrganisationSwitch = () => {
  const dispatch = useDispatch();
  const { id, onOrganisationChange } = useOrganisationContext();
  const navigate = useNavigate();

  const organisations = useSelector(({ profile_store }) => profile_store.profile?.organisations);

  const selectedOrganisation = useMemo(() => {
    return (organisations ?? []).find((organisation) => organisation.id === id);
  }, [id, organisations]);

  useEffect(
    () => {
      if (!organisations) {
        dispatch(retrieveProfile());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organisations]
  );

  const handleSwitchOrganisation = (organisation) => onOrganisationChange(organisation);

  return (
    <section className="organisation-switch">
      <Dropdown
        menuItems={switchOrganisationItems(organisations, handleSwitchOrganisation, navigate)}
        placement="bottom-end"
      >
        {selectedOrganisation && (
          <div className="organisation-switch__organisation">
            <OrganisationBadge
              name={selectedOrganisation.name}
              imgSrc={selectedOrganisation.image}
              small
            />
            <label>{selectedOrganisation.name}</label>
            <ChevronIconRight />
          </div>
        )}
      </Dropdown>
    </section>
  );
};

export default OrganisationSwitch;
