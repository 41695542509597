import { YES } from '@neslotech/eventhub-ui-kit';
import { convertTimeToDate, isEmpty, isValuePresent } from '@neslotech/utils';

export const formifyDetails = (data) => ({
  competition_id: data?.competition?.id,
  name: data?.name,
  startTime: data?.start_time ? convertTimeToDate(data.start_time) : '',
  endTime: data?.end_time ? convertTimeToDate(data.end_time) : '',
  startDate: data?.start_date ? new Date(data?.start_date) : '',
  endDate: data?.end_date ? new Date(data?.end_date) : '',
  venue: data?.venue,
  national: data?.national,
  entryStartDate: data?.entry_start_date ? new Date(data?.entry_start_date) : '',
  entryEndDate: data?.entry_end_date ? new Date(data?.entry_end_date) : '',
  attendees: data?.attendee_count,
  price: (data?.price ?? '').replace('R', ''),
  refundable: data?.refundable,
  visibleEntries: data?.visible_entries,
  suppliedBankingDetails: data?.supplied_banking_details,
  description: data?.description,
  bankingDetails: formifyBankingDetails(data?.banking_detail),
  classes: formifyClasses(data?.classes ?? []),
  members: formifyMembers(data?.members ?? []),
  additionalLogos: data?.additional_logos ?? [],
  supportingDocuments: data?.supporting_documents ?? []
});

const formifyClasses = (data) => {
  return data.map((item) => ({
    selected: true,
    id: item.id,
    competitionClassId: item.competition_class_id,
    name: item.name,
    price: item.price
  }));
};

const formifyMembers = (data) => {
  return data.map((item) => ({
    selected: true,
    id: item.id,
    memberTypeId: item.member_type_id,
    name: item.name,
    price: item.price,
    limit: item.limit
  }));
};

const formifyBankingDetails = (data) => {
  if (!data) {
    return undefined;
  }

  return {
    id: data.id,
    bankAccountType: data.account_type,
    bankAccountNumber: data.account_number,
    bankName: data.bank_name,
    branchNumber: data.branch_number
  };
};

export const formifyPointsConfig = (data) => ({
  id: data?.id,
  required: !isEmpty(data?.positions) ? YES : undefined,
  description: data?.description,
  places: data?.positions
});

export const formifyEntryForm = (data) => ({
  required: isValuePresent(data?.entry_form) ? YES : undefined,
  entryForm: data?.entry_form
});
