import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { convertTimeToDate, isEmpty, isUndefined } from '@neslotech/utils';

import { ScheduleContext } from '../contexts/Schedule.context';
import { useOrganisationContext } from '../hooks/useOrganisationContext';

import {
  createEventSchedule,
  loadEvent,
  loadEventClasses,
  loadEventSchedule,
  updateEventSchedule
} from '../actions/event.actions';

const formifyEvent = (event) => ({
  name: event?.name ?? '',
  startDate: event?.start_date ?? new Date(),
  endDate: event?.end_date ?? new Date(),
  startTime: convertTimeToDate(event?.start_time) ?? new Date().setHours(7),
  endTime: convertTimeToDate(event?.end_time) ?? new Date().setHours(8),
  venue: event?.venue ?? '',
  competition: event?.competition
});

const formifySchedule = (schedule) => ({
  id: schedule?.id,
  status: schedule?.status,
  competitionClassSchedules: (schedule?.competition_class_schedules ?? []).map((classSchedule) => {
    return formifyClassSchedule(classSchedule);
  }),
  timeslots: schedule?.timeslots.map((timeslot) => formifyTimeslot(timeslot))
});

const formifyClassSchedule = (classSchedule) => ({
  id: classSchedule.id,
  description: classSchedule.description,
  location: classSchedule.location,
  color: classSchedule.color,
  notificationType: classSchedule.notification_type,
  competitionClass: classSchedule.competition_class,
  heats: (classSchedule.heats ?? []).map((heat) => ({
    id: heat.id,
    name: heat.name,
    startTime: heat.start_time,
    endTime: heat.end_time,
    priority: heat.priority ?? false,
    countsForPoints: heat.counts_for_points ?? false
  }))
});

const formifyTimeslot = (timeslot) => ({
  id: timeslot.id,
  description: timeslot.description,
  location: timeslot.location,
  color: timeslot.color,
  name: timeslot.name,
  startTime: timeslot.start_time,
  endTime: timeslot.end_time,
  notificationType: timeslot.notification_type
});

const serverifyClassSchedule = (classSchedule) => ({
  id: classSchedule.id,
  description: classSchedule.description,
  location: classSchedule.location,
  color: classSchedule.color,
  notification_type: classSchedule.notificationType,
  competition_class: classSchedule.competitionClass,
  heats: (classSchedule.heats ?? []).map((heat) => ({
    id: heat.id,
    name: heat.name,
    start_time: heat.startTime,
    end_time: heat.endTime,
    priority: heat.priority ?? false,
    counts_for_points: heat.countsForPoints ?? false
  }))
});

const serverifySchedule = (schedule) => ({
  id: schedule.id,
  status: schedule.status,
  competition_class_schedules: schedule.competitionClassSchedules.map((classSchedule) => {
    if (!classSchedule.color) {
      return null;
    }
    return serverifyClassSchedule(classSchedule);
  }),
  timeslots: schedule.timeslots.map((timeslot) => serverifyTimeslot(timeslot))
});

const serverifyTimeslot = (timeslot) => ({
  id: timeslot.id,
  description: timeslot.description,
  location: timeslot.location,
  color: timeslot.color,
  name: timeslot.name,
  start_time: timeslot.startTime,
  end_time: timeslot.endTime,
  notification_type: timeslot.notificationType
});

const ScheduleProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id: eventId, competitionId } = useParams();

  const { id } = useOrganisationContext();

  useEffect(() => {
    dispatch(loadEventClasses(eventId, () => setLoading(false)));
    dispatch(loadEvent(id, competitionId, eventId, () => setLoading(false)));
    dispatch(loadEventSchedule(eventId, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const stateLoading = useSelector(({ event_store }) => event_store.loading);
  const event = useSelector(({ event_store }) => formifyEvent(event_store.event));
  const classes = useSelector(({ event_store }) => event_store.classes);
  const stateSchedule = useSelector(({ event_store }) => formifySchedule(event_store.schedule));

  const initialSchedule = useMemo(() => {
    const competitionClassSchedules = classes.map((klass) => ({
      competitionClass: klass,
      heats: []
    }));

    return {
      status: 'draft',
      competitionClassSchedules,
      timeslots: []
    };
  }, [classes]);

  const [schedule, setSchedule] = useState(initialSchedule);
  const [heat, setHeat] = useState({});
  const [showHeatForm, setShowHeatForm] = useState(true);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [selectedClassSchedule, setSelectedClassSchedule] = useState(
    initialSchedule.competitionClassSchedules[0]
  );
  const [selectedTimeslot, setSelectedTimeslot] = useState();

  useEffect(() => {
    setSchedule(initialSchedule);
    setSelectedClassSchedule(initialSchedule.competitionClassSchedules[0]);
    setShowHeatForm(isEmpty(initialSchedule.competitionClassSchedules[0]?.heats));
  }, [initialSchedule]);

  const [loading, setLoading] = useState(stateLoading ?? true);

  const onSchedulePreview = () =>
    navigate(`/competitions/${competitionId}/events/${eventId}/schedule/preview`);

  const handleClassScheduleChange = (newState) => {
    const updated = { ...selectedClassSchedule, ...newState };

    const index = schedule.competitionClassSchedules.indexOf(selectedClassSchedule);
    schedule.competitionClassSchedules.splice(index, 1, updated);

    setSelectedClassSchedule(updated);

    const updatedSchedule = { ...schedule };
    setSchedule(updatedSchedule);
  };

  const handleHeatChange = (newState) => {
    setHeat({ ...heat, ...newState });
  };

  const updateHeat = (updatedHeat) => {
    const foundSchedule = schedule.competitionClassSchedules.find(
      (item) => item.competitionClass === selectedClassSchedule.competitionClass
    );

    foundSchedule.heats.splice(updatedHeat.key, 1, updatedHeat);

    setSchedule({
      ...schedule,
      competitionClassSchedules: schedule.competitionClassSchedules
    });

    setShowHeatForm(false);
    setHeat({});
  };

  const handleRemoveHeat = (competitionClassSchedule, heatToRemove) => {
    const foundSchedule = schedule.competitionClassSchedules.find(
      (item) => item.competitionClass === competitionClassSchedule.competitionClass
    );

    foundSchedule.heats.splice(heatToRemove.key, 1);

    setSchedule({
      ...schedule,
      competitionClassSchedules: schedule.competitionClassSchedules
    });

    setShowHeatForm(isEmpty(foundSchedule.heats));
    setShowRemoveModal(false);
    setHeat({});
  };

  const handleSaveHeat = (newState) => {
    if (!isUndefined(newState.key)) {
      updateHeat(newState);
    } else {
      selectedClassSchedule.heats = [...selectedClassSchedule.heats, newState];

      setShowHeatForm(false);
      setHeat({});
    }
  };

  const handleAddNewTimeslot = () => {
    const newTimeSlot = {
      key: `additional-timeslot-${schedule.timeslots?.length}`,
      name: 'Additional Time Slot'
    };

    setSelectedTimeslot(newTimeSlot);
    setSelectedClassSchedule(undefined);
    schedule.timeslots.push(newTimeSlot);
  };

  const handleSelectedTimeslotChange = (newState) => {
    const updated = { ...selectedTimeslot, ...newState };

    const index = schedule.timeslots.indexOf(selectedTimeslot);
    schedule.timeslots?.splice(index, 1, updated);

    setSelectedTimeslot(updated);

    const updatedSchedule = { ...schedule };
    setSchedule(updatedSchedule);
  };

  const handleSaveSchedule = () => {
    const payload = serverifySchedule(schedule);
    if (payload.id) {
      // TODO: map heat attributes from heats.
      dispatch(updateEventSchedule(eventId, payload.id, payload));
    } else {
      dispatch(createEventSchedule(eventId, payload));
    }
  };

  const handleSaveAdditionalTimeslot = () => {
    handleSaveSchedule();
  };

  const value = {
    event,
    schedule,
    stateSchedule,
    loading,
    selectedClassSchedule,
    setSelectedClassSchedule,
    heat,
    setHeat,
    showHeatForm,
    setShowHeatForm,
    showRemoveModal,
    setShowRemoveModal,
    selectedTimeslot,
    setSelectedTimeslot,
    handleHeatChange,
    handleSaveHeat,
    handleClassScheduleChange,
    handleRemoveHeat,
    handleAddNewTimeslot,
    handleSelectedTimeslotChange,
    handleSaveSchedule,
    handleSaveAdditionalTimeslot,
    onSchedulePreview
  };

  return <ScheduleContext.Provider value={value}>{children}</ScheduleContext.Provider>;
};

export default ScheduleProvider;
