import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setOrganisationIdCookie } from '../../../../tools/organisation.util';

import { retrieveProfile } from '../../../../actions/profile.action';

import AuthLayout from '../../../../components/layout/auth/AuthLayout';
import OrganisationSelection from '../../../../components/auth/organisation/selection/OrganisationSelection';

const OrganisationSelectionContainer = () => {
  const dispatch = useDispatch();
  const organisations = useSelector(({ profile_store }) => profile_store.profile?.organisations);

  const navigate = useNavigate();

  const handleSelectOrganisation = (id) => {
    setOrganisationIdCookie(id);
    navigate('/dashboard');
  };

  useEffect(
    () => {
      dispatch(retrieveProfile());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      if (organisations?.length === 1) {
        handleSelectOrganisation(organisations[0].id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organisations]
  );

  return (
    <AuthLayout
      title="Organisations"
      subtitle="Please select which organisation you wish to login to"
      noLogo
    >
      <OrganisationSelection
        organisations={organisations}
        onSelectOrganisation={handleSelectOrganisation}
      />
    </AuthLayout>
  );
};

export default OrganisationSelectionContainer;
