import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, FilterLayout, Loader, PageLayout, RadioGroup } from '@neslotech/eventhub-ui-kit';
import { noOp } from '@neslotech/utils';

import { useOrganisationContext } from '../../../hooks/useOrganisationContext';

import { loadEvents } from '../../../actions/event.actions';

import { ReactComponent as ChevronRightIcon } from '../../../icons/chevron-right-icon.svg';

import OrganizerLayout from '../../../components/layout/organizer/OrganizerLayout';
import EventsList from '../../../components/events/EventsList';

const menuItems = (handleChange, filters) => [
  {
    text: 'National/Regional',
    onClick: noOp,
    suffix: <ChevronRightIcon />,
    expandable: true,
    expandedItems: (
      <RadioGroup
        onChange={handleChange}
        value={filters?.national}
        name="national"
        items={[
          { label: 'National', value: true },
          { label: 'Regional', value: false }
        ]}
      />
    )
  }
];

const EventsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, verified } = useOrganisationContext();

  const events = useSelector(({ event_store }) => event_store.events);
  const stateLoading = useSelector(({ event_store }) => event_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadEvents(id, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <OrganizerLayout>
      <PageLayout title="All Events">
        <FilterLayout
          menuItems={menuItems}
          searchPlaceholder="Search for an event..."
          actions={
            <Button onClick={() => navigate('add')} label="Create Event" disabled={!verified} />
          }
        >
          {loading && <Loader filled />}
          {!loading && <EventsList events={events} verified={verified} />}
        </FilterLayout>
      </PageLayout>
    </OrganizerLayout>
  );
};

export default EventsContainer;
