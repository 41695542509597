import React, { useState } from 'react';

import {
  CHECKBOX,
  DATEPICKER,
  DROPDOWN,
  FILE_UPLOAD,
  INPUT,
  MULTI_DROPDOWN,
  RADIO_GROUP,
  SECTION,
  TEXT_AREA,
  TIMEPICKER,
  YEARPICKER
} from '@neslotech/eventhub-ui-kit';
import { generateId, scrollIntoView } from '@neslotech/utils';

import { FormBuilderContext } from '../contexts/FormBuilder.context';

const getEmptySection = () => ({
  id: generateId(),
  title: '',
  fluid: false,
  elements: [],
  type: SECTION
});

const FormBuilderProvider = ({ children, existingEntity }) => {
  const [openProperties, setOpenProperties] = useState(false);
  const [openSelector, setOpenSelector] = useState(false);

  const [context, setContext] = useState();
  const [activeSection, setActiveSection] = useState();
  const [sections, setSections] = useState(existingEntity?.entry_form ?? []);

  const handleContextChange = (newContext) => {
    setOpenProperties(true);
    setOpenSelector(false);
    setContext(newContext);
  };

  const handleAddSection = () => {
    const newSection = getEmptySection();
    setSections([...sections, newSection]);
    handleContextChange(newSection);
    handleActivateSection(newSection, true);
    setOpenProperties(true);
    scrollIntoView('widget-properties');
  };

  const handleCloseProperties = () => {
    setContext(null);
    setOpenProperties(false);
    setOpenSelector(true);
  };

  const handleCloseSelector = () => {
    setContext(null);
    setOpenSelector(false);
  };

  const handleActivateSection = (section, block = false) => {
    setActiveSection(section);
    setOpenProperties(false);
    if (!block) {
      setOpenSelector(true);
    }
  };

  const handleSetSections = (newSections) => {
    setSections(newSections);
  };

  const handleAddElement = (newElement) => {
    const updatedSections = sections.reduce((accum, section) => {
      if (section.id === activeSection?.id) {
        section.elements.push(newElement);
      }

      accum.push(section);
      return accum;
    }, []);
    setSections(updatedSections);
    handleContextChange(newElement);
    scrollIntoView('widget-properties');
  };

  const handleSaveProperties = (element) => {
    let updatedSections;
    switch (element.type) {
      case SECTION:
        updatedSections = sections.reduce((accum, section) => {
          if (section.id === element.id) {
            section = {
              ...section,
              ...element
            };
          }

          accum.push(section);

          return accum;
        }, []);
        break;
      case DROPDOWN:
      case MULTI_DROPDOWN:
      case TEXT_AREA:
      case TIMEPICKER:
      case DATEPICKER:
      case CHECKBOX:
      case FILE_UPLOAD:
      case RADIO_GROUP:
      case YEARPICKER:
      case INPUT:
        updatedSections = sections.reduce((accum, section) => {
          if (section.id === activeSection?.id) {
            const updatedElements = section.elements.reduce((accum, item) => {
              if (item.id === element.id) {
                item = {
                  ...item,
                  ...element
                };
              }

              accum.push(item);
              return accum;
            }, []);

            section.elements = updatedElements;
          }

          accum.push(section);

          return accum;
        }, []);
        break;
      default:
        throw new Error(`There is no save operation defined for the type: ${element.type}`);
    }

    setSections(updatedSections);
    handleCloseProperties();
    document
      .getElementById('form-builder')
      ?.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
  };

  const handleRemoveElement = (element) => {
    let updatedSections;
    switch (element?.type ?? context?.type) {
      case SECTION:
        updatedSections = sections.filter((item) => item.id !== (element?.id ?? context?.id));
        break;
      case DROPDOWN:
      case MULTI_DROPDOWN:
      case TEXT_AREA:
      case TIMEPICKER:
      case DATEPICKER:
      case CHECKBOX:
      case FILE_UPLOAD:
      case RADIO_GROUP:
      case YEARPICKER:
      case INPUT:
        updatedSections = sections.reduce((accum, section) => {
          if (section.id === activeSection?.id) {
            const updatedElements = section.elements.filter((item) => item.id !== context?.id);
            section.elements = updatedElements;
          }

          accum.push(section);

          return accum;
        }, []);
        break;
      default:
        throw new Error(`There is no remove operation defined for the type: ${context?.type}`);
    }

    setSections(updatedSections);
    handleCloseProperties();
  };

  const value = {
    onContextChange: handleContextChange,
    onAddSection: handleAddSection,
    onCloseSelector: handleCloseSelector,
    onCloseProperties: handleCloseProperties,
    onSaveProperties: handleSaveProperties,
    onAddElement: handleAddElement,
    onActivateSection: handleActivateSection,
    onSetSections: handleSetSections,
    openProperties,
    openSelector,
    context,
    sections,
    activeSection,
    onRemoveElement: handleRemoveElement
  };

  return <FormBuilderContext.Provider value={value}>{children}</FormBuilderContext.Provider>;
};

export default FormBuilderProvider;
