import axios from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import { noOp, SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import { addSystemNotice } from '../actions/system.actions';
import {
  CANCEL_EVENT,
  CREATE_EVENT_SCHEDULE,
  LOAD_EVENT,
  LOAD_EVENT_CLASSES,
  LOAD_EVENT_SCHEDULE,
  LOAD_EVENTS,
  loadEvent,
  loadEvents,
  POSTPONE_EVENT,
  PUBLISH_EVENT,
  REINSTATE_EVENT,
  REMOVE_EVENT,
  SAVE_EVENT_DETAILS,
  SAVE_EVENT_DRAFT,
  SAVE_EVENT_ENTRY_FORM,
  SAVE_EVENT_POINTS_CONFIG,
  SET_EVENT,
  SET_EVENT_CLASSES,
  SET_EVENT_SCHEDULE,
  SET_EVENTS,
  UPDATE_EVENT_DETAILS,
  UPDATE_EVENT_SCHEDULE
} from '../actions/event.actions';

import {
  getCancelEventRequest,
  getCreateEventScheduleRequest,
  getEventClassesRequest,
  getLoadEventRequest,
  getLoadEventScheduleRequest,
  getLoadEventsRequest,
  getPostponeEventRequest,
  getPublishEventRequest,
  getReinstateEventRequest,
  getRemoveEventRequest,
  getSaveEventsDetailsRequest,
  getSaveEventsDraftRequest,
  getSaveEventsEntryFormRequest,
  getSaveEventsPointsConfigRequest,
  getUpdateEventScheduleRequest,
  getUpdateEventsDetailsRequest
} from '../tools/api/event.endpoints';

export function* performLoadEvents({ id, onSuccess }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadEventsRequest(id);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_EVENTS, events: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    yield put(addSystemNotice(response?.data?.error ?? 'Failed to load events.', SNACK_CRITICAL));
  }
}

export function* watchForLoadEventsRequest() {
  yield takeLatest(LOAD_EVENTS, performLoadEvents);
}

export function* performLoadEvent({ id, competitionId, eventId, onSuccess }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadEventRequest(id, competitionId, eventId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_EVENT, event: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    yield put(addSystemNotice(response?.data?.error ?? 'Failed to load event.', SNACK_CRITICAL));
  }
}

export function* watchForLoadEventRequest() {
  yield takeLatest(LOAD_EVENT, performLoadEvent);
}

export function* performCancelEvent({ id, competitionId, eventId, payload }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getCancelEventRequest(id, competitionId, eventId, payload);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('This event has been cancelled.', SNACK_SUCCESS));

    yield put(loadEvent(id, competitionId, eventId, noOp));
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.message ?? 'Failed to cancel the event.', SNACK_CRITICAL)
    );
  }
}

export function* watchForCancelEventRequest() {
  yield takeLatest(CANCEL_EVENT, performCancelEvent);
}

export function* performRemoveEvent({ id, competitionId, eventId, navigate }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getRemoveEventRequest(id, competitionId, eventId);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('This event has been removed.', SNACK_SUCCESS));

    // reload events
    yield put(loadEvents(id, noOp));
    yield call(navigate, '/events');
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to remove the event.', SNACK_CRITICAL)
    );
  }
}

export function* watchForRemoveEventRequest() {
  yield takeLatest(REMOVE_EVENT, performRemoveEvent);
}

export function* performReinstateEvent({ id, competitionId, eventId }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getReinstateEventRequest(id, competitionId, eventId);

    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('This event has been reinstated.', SNACK_SUCCESS));

    yield put(loadEvent(id, competitionId, eventId, noOp));
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.message ?? 'Failed to reinstate event.', SNACK_CRITICAL)
    );
  }
}

export function* watchForReinstateEventRequest() {
  yield takeLatest(REINSTATE_EVENT, performReinstateEvent);
}

export function* performCreateEventSchedule({ id, payload }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getCreateEventScheduleRequest(id, payload);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_EVENT_SCHEDULE, schedule: data });
  } catch ({ response }) {
    yield put(
      addSystemNotice(
        response?.data?.message ?? 'Failed to save the event schedule.',
        SNACK_CRITICAL
      )
    );
  }
}

export function* watchForCreateEventScheduleRequest() {
  yield takeLatest(CREATE_EVENT_SCHEDULE, performCreateEventSchedule);
}

export function* performUpdateEventSchedule({ id, scheduleId, payload }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getUpdateEventScheduleRequest(id, scheduleId, payload);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_EVENT_SCHEDULE, schedule: data });
  } catch ({ response }) {
    yield put(
      addSystemNotice(
        response?.data?.message ?? 'Failed to save the event schedule.',
        SNACK_CRITICAL
      )
    );
  }
}

export function* watchForUpdateEventScheduleRequest() {
  yield takeLatest(UPDATE_EVENT_SCHEDULE, performUpdateEventSchedule);
}

export function* performPostponeEvent({ id, competitionId, eventId, payload }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getPostponeEventRequest(id, competitionId, eventId, payload);

    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('This event has been postponed.', SNACK_SUCCESS));

    yield put(loadEvent(id, competitionId, eventId, noOp));
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.message ?? 'Failed to postpone event.', SNACK_CRITICAL)
    );
  }
}

export function* watchForPostponeEventRequest() {
  yield takeLatest(POSTPONE_EVENT, performPostponeEvent);
}

export function* performLoadEventClasses({ eventId, onSuccess }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getEventClassesRequest(eventId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_EVENT_CLASSES, classes: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.message ?? 'Failed to load event classes.', SNACK_CRITICAL)
    );
  }
}

export function* watchForLoadEventsClassesRequest() {
  yield takeLatest(LOAD_EVENT_CLASSES, performLoadEventClasses);
}

export function* performLoadEventSchedule({ eventId, onSuccess }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadEventScheduleRequest(eventId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_EVENT_SCHEDULE, schedule: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.message ?? 'Failed to load event schedules.', SNACK_CRITICAL)
    );
  }
}

export function* watchForLoadEventScheduleRequest() {
  yield takeLatest(LOAD_EVENT_SCHEDULE, performLoadEventSchedule);
}

export function* performSaveEventDetails({ id, competitionId, payload, onSuccess, onError }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getSaveEventsDetailsRequest(id, competitionId, payload);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_EVENT, event: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    if (response.status === 422) {
      // provide the errors back to the caller
      yield call(onError, response.data?.errors);
    } else {
      yield put(addSystemNotice('Failed to save event as draft.', SNACK_CRITICAL));
    }
  }
}

export function* watchForSaveEventDetailsRequest() {
  yield takeLatest(SAVE_EVENT_DETAILS, performSaveEventDetails);
}

export function* performUpdateEventDetails({
  id,
  competitionId,
  eventId,
  payload,
  onSuccess,
  onError
}) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getUpdateEventsDetailsRequest(
      id,
      competitionId,
      eventId,
      payload
    );

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_EVENT, event: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    if (response.status === 422) {
      // provide the errors back to the caller
      yield call(onError, response.data?.errors);
    } else {
      yield put(addSystemNotice('Failed to update event.', SNACK_CRITICAL));
    }
  }
}

export function* watchForUpdateEventDetailsRequest() {
  yield takeLatest(UPDATE_EVENT_DETAILS, performUpdateEventDetails);
}

export function* performSaveEventPointsConfig({
  id,
  competitionId,
  eventId,
  payload,
  onSuccess,
  onError
}) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getSaveEventsPointsConfigRequest(
      id,
      competitionId,
      eventId,
      payload
    );

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_EVENT, event: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    if (response.status === 422) {
      // provide the errors back to the caller
      yield call(onError, response.data?.errors);
    } else {
      yield put(addSystemNotice('Failed to save event points configuration.', SNACK_CRITICAL));
    }
  }
}

export function* watchForSaveEventPointsConfigRequest() {
  yield takeLatest(SAVE_EVENT_POINTS_CONFIG, performSaveEventPointsConfig);
}

export function* performSaveEventEntryForm({
  id,
  competitionId,
  eventId,
  payload,
  onSuccess,
  onError
}) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getSaveEventsEntryFormRequest(
      id,
      competitionId,
      eventId,
      payload
    );

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_EVENT, event: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    if (response.status === 422) {
      // provide the errors back to the caller
      yield call(onError, response.data?.errors);
    } else {
      yield put(addSystemNotice('Failed to save event entry form.', SNACK_CRITICAL));
    }
  }
}

export function* watchForSaveEventEntryFormRequest() {
  yield takeLatest(SAVE_EVENT_ENTRY_FORM, performSaveEventEntryForm);
}

export function* performSaveEventDraft({ id, competitionId, eventId, navigate, onError }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getSaveEventsDraftRequest(id, competitionId, eventId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice(data.message, SNACK_SUCCESS));

    if (navigate) {
      yield call(navigate, `/competitions/${competitionId}/events/${eventId}`);
    }
  } catch ({ response }) {
    if (response.status === 422) {
      // provide the errors back to the caller
      yield call(onError, response.data?.errors);
    } else {
      yield put(
        addSystemNotice(response.data?.message ?? 'Failed to save event as draft', SNACK_CRITICAL)
      );
    }
  }
}

export function* watchForSaveEventDraftRequest() {
  yield takeLatest(SAVE_EVENT_DRAFT, performSaveEventDraft);
}

export function* performPublishEvent({
  id,
  competitionId,
  eventId,
  navigate,
  onError,
  onDuplicate
}) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getPublishEventRequest(id, competitionId, eventId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice(data.message, SNACK_SUCCESS));

    if (navigate) {
      yield call(navigate, `/competitions/${competitionId}/events/${eventId}`);
    }
  } catch ({ response }) {
    if (response.status === 422) {
      // provide the errors back to the caller
      yield call(onError, response.data?.errors);

      if (response.data.event_id) {
        // provide the id of the duplicate, so we can navigate to it
        yield call(onDuplicate, competitionId, response.data.event_id);
      }
    } else {
      yield put(
        addSystemNotice(response.data?.message ?? 'Failed to publish competition', SNACK_CRITICAL)
      );
    }
  }
}

export function* watchForPublishEventRequest() {
  yield takeLatest(PUBLISH_EVENT, performPublishEvent);
}

export default function* eventSaga() {
  yield all([
    watchForLoadEventsRequest(),
    watchForLoadEventRequest(),
    watchForCancelEventRequest(),
    watchForRemoveEventRequest(),
    watchForReinstateEventRequest(),
    watchForPostponeEventRequest(),
    watchForLoadEventsClassesRequest(),
    watchForLoadEventScheduleRequest(),
    watchForCreateEventScheduleRequest(),
    watchForUpdateEventScheduleRequest(),
    watchForSaveEventDetailsRequest(),
    watchForUpdateEventDetailsRequest(),
    watchForSaveEventPointsConfigRequest(),
    watchForSaveEventEntryFormRequest(),
    watchForSaveEventDraftRequest(),
    watchForPublishEventRequest()
  ]);
}
