import React from 'react';

import {
  Button,
  ButtonGroup,
  Checkbox,
  Form,
  FormRow,
  Input,
  RadioGroup
} from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import { mapFileTypes, mapFileTypesToObject } from '../../../../../tools/file.util';

import './file-upload-properties.scss';

const requiredOptions = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

const rules = {
  validates: {
    label: ['isPresent'],
    description: ['isPresent']
  }
};

const formify = ({ id, type, description, label, filled, required, fileTypes }) => {
  const mappedTypes = mapFileTypesToObject(fileTypes);
  return {
    id,
    type,
    description,
    label,
    filled,
    required,
    ...mappedTypes
  };
};

const serverify = ({ id, type, description, label, filled, required, ...rest }) => {
  const fileTypes = mapFileTypes(rest);
  return {
    id,
    type,
    description,
    label,
    filled,
    required,
    fileTypes
  };
};

const FileUploadProperties = ({ element, onSave, onCancel }) => {
  const [form, setForm] = useFormState(formify(element), rules);

  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleSave = () => {
    const payload = serverify(form);
    onSave(payload);
  };

  return (
    <article className="file-upload-properties">
      <Form>
        <FormRow>
          <Input
            name="label"
            placeholder="Label Text"
            label="Label Text"
            onChange={handleChange}
            value={form?.label}
            error={form?.messages?.label}
          />
        </FormRow>
        <FormRow>
          <Input
            name="description"
            placeholder="Description"
            label="Description"
            onChange={handleChange}
            value={form?.description}
            error={form?.messages?.description}
          />
        </FormRow>
        <FormRow>
          <label className="file-upload-properties__types-label">Allowed File Types</label>
          <section className="file-upload-properties__types">
            <Checkbox name="jpeg" label="JPEG" checked={form?.jpeg} onChange={handleChange} />
            <Checkbox name="svg" label="SVG" checked={form?.svg} onChange={handleChange} />
            <Checkbox name="png" label="PNG" checked={form?.png} onChange={handleChange} />
            <Checkbox name="webp" label="WebP" checked={form?.webp} onChange={handleChange} />
            <Checkbox name="pdf" label="PDF" checked={form?.pdf} onChange={handleChange} />
            <Checkbox name="docx" label="DOCX" checked={form?.docx} onChange={handleChange} />
            <Checkbox name="mp4" label="MP4" checked={form?.mp4} onChange={handleChange} />
            <Checkbox name="html" label="HTML" checked={form?.html} onChange={handleChange} />
            <Checkbox name="xls" label="XLS" checked={form?.xls} onChange={handleChange} />
            <Checkbox name="txt" label="TXT" checked={form?.txt} onChange={handleChange} />
          </section>
        </FormRow>
        <FormRow>
          <RadioGroup
            name="required"
            items={requiredOptions}
            onChange={handleChange}
            label="Required field?"
            value={form?.required}
          />
        </FormRow>
      </Form>
      <ButtonGroup centered>
        <Button label="Save" disabled={!form?.valid} onClick={handleSave} />
        <Button secondary label="Cancel" onClick={onCancel} />
      </ButtonGroup>
    </article>
  );
};

export default FileUploadProperties;
