import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  filterElement,
  getProfileInitials,
  ProfileImage,
  searchElement,
  Table,
  Tabs,
  useFilters
} from '@neslotech/eventhub-ui-kit';

import { ReactComponent as AllIcon } from '../../icons/inbox-icon.svg';
import { ReactComponent as RequestsIcon } from '../../icons/chat-icon.svg';
import { ReactComponent as CancelledIcon } from '../../icons/hollow-remove-icon.svg';

import './entries-list.scss';

const ALL = 'all';
const REQUESTS = 'awaiting_verification';
const CANCELLED = 'cancelled';

const headers = [
  {
    Header: '',
    accessor: 'image',
    disableSortBy: true
  },
  {
    Header: 'Entrant Name',
    accessor: 'full_name'
  },
  {
    Header: 'Type',
    accessor: 'type'
  },
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'Last Modified',
    accessor: 'updated_at'
  }
];

const modifiers = {
  image: {
    thin: true
  },
  status: {
    capitalize: true
  }
};

const EntriesList = ({ entries, onDownload }) => {
  const navigate = useNavigate();
  const { filters, search, searching, filtering, setFilterDefaults } = useFilters();

  const [activeTab, setActiveTab] = useState(ALL);

  useEffect(
    () => {
      setFilterDefaults({ type: '' });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entries]
  );

  const tabs = useMemo(() => {
    return [
      {
        key: ALL,
        icon: <AllIcon />,
        label: 'All Entries',
        onClick: () => setActiveTab(ALL)
      },
      {
        key: REQUESTS,
        icon: <RequestsIcon />,
        label: 'Entry Requests',
        onClick: () => setActiveTab(REQUESTS)
      },
      {
        key: CANCELLED,
        icon: <CancelledIcon />,
        label: 'Cancelled Entries',
        onClick: () => setActiveTab(CANCELLED)
      }
    ];
  }, []);

  const filteredData = useMemo(() => {
    return entries
      .filter((entry) => (activeTab === ALL ? true : entry.status === activeTab))
      .filter((entry) => filterElement(entry, filters));
  }, [activeTab, entries, filters]);

  const rowData = useMemo(() => {
    return filteredData
      .reduce((accum, entry) => {
        const data = entry.entrants.map((entrant) => ({
          id: entry.id,
          name: entry.name,
          type: entry.type,
          status: entry.status.replace('_', ' '),
          image: (
            <ProfileImage small imgSrc={entrant.image} initials={getProfileInitials(entrant)} />
          ),
          full_name: entrant.full_name,
          reference_number: entry.reference_number,
          updated_at: entry.updated_at,
          onClick: () => navigate(`${entry.id}`)
        }));
        return [...accum, ...data];
      }, [])
      .filter(
        (entry) =>
          searchElement(entry.full_name, search) ||
          searchElement(entry.name, search) ||
          searchElement(entry.reference_number, search)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData, search]);

  const emptyMessage = useMemo(() => {
    if (!filters?.type) {
      return searching || filtering
        ? 'There are no entries listed under that competition, event or entrant name.'
        : 'There are no entries found for any events or competitions yet.';
    }

    return filters?.type === 'Event'
      ? 'There are no entries listed under that event or entrant name.'
      : 'There are no entries listed under that competition or entrant name.';
  }, [filters?.type, searching, filtering]);

  return (
    <article className="entries-list">
      <header className="entries-list__tabs">
        <Tabs tabs={tabs} activeTab={activeTab} />
      </header>
      <section className="entries-list__table">
        <Table
          cols={headers}
          columnModifiers={modifiers}
          rowData={rowData}
          emptyTitle="No Entries Found"
          emptySubtitle={emptyMessage}
        />
      </section>
      <footer className="entries-list__actions">
        <Button label="Download Table" onClick={onDownload} />
      </footer>
    </article>
  );
};

export default EntriesList;
