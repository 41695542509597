import React, { useEffect, useMemo } from 'react';
import { array } from 'prop-types';

import {
  CHECKBOX,
  CheckboxGroup,
  Datepicker,
  DATEPICKER,
  DetailsSection,
  DROPDOWN,
  FILE_UPLOAD,
  FileUploadGroup,
  Form,
  FormRow,
  Input,
  INPUT,
  MULTI_DROPDOWN,
  RADIO_GROUP,
  RadioGroup,
  SECTION,
  Select,
  TEXT_AREA,
  TextArea,
  Timepicker,
  TIMEPICKER,
  YEARPICKER
} from '@neslotech/eventhub-ui-kit';
import { getClassNames, noOp } from '@neslotech/utils';

import { useFormBuilderContext } from '../../hooks/useFormBuilderContext';

import { ReactComponent as EditIcon } from '../../icons/blue-edit-icon.svg';
import { ReactComponent as TrashIcon } from '../../icons/trash-icon.svg';

import './form-canvas.scss';

const FormElement = ({ editable, element, onFocus, onClick }) => {
  return useMemo(() => {
    switch (element.type) {
      case INPUT:
        return (
          <Input
            selectable
            disabled={!editable}
            onFocus={onFocus}
            placeholder={element.placeholder}
            label={element.label}
            value={element.value}
          />
        );
      case TEXT_AREA:
        return (
          <TextArea
            selectable
            informational={element.informational}
            boxed={!element.informational}
            disabled={!editable || element.informational}
            onFocus={onFocus}
            placeholder={element.placeholder}
            label={element.label}
            subtitle={element.subtitle}
            value={element.value}
          />
        );
      case MULTI_DROPDOWN:
      case DROPDOWN:
        return (
          <Select
            selectable
            items={[]}
            onFocus={onFocus}
            placeholder={element.placeholder}
            label={element.label}
            disabled={!editable}
            value={element.value}
          />
        );
      case TIMEPICKER:
        return (
          <Timepicker
            selectable
            preventOpen
            onFocus={onFocus}
            disabled={!editable}
            label={element.label}
            placeholder={element.placeholder}
            value={element.value}
          />
        );
      case DATEPICKER:
        return (
          <Datepicker
            selectable
            preventOpen
            onFocus={onFocus}
            disabled={!editable}
            label={element.label}
            placeholder={element.placeholder}
            value={element.value}
          />
        );
      case CHECKBOX:
        return (
          <CheckboxGroup
            onFocus={onFocus}
            disabled={!editable}
            label={element.label}
            description={element.description}
            items={element.items}
            value={element.value}
          />
        );
      case FILE_UPLOAD:
        return (
          <FileUploadGroup
            onClick={onClick}
            disabled={!editable}
            fileTypes={element.fileTypes}
            description={element.description}
            label={element.label}
          />
        );
      case RADIO_GROUP:
        const items = element.items.map((item) => ({
          label: item,
          value: item
        }));
        return (
          <RadioGroup
            onChange={onFocus}
            items={items}
            label={element.label}
            description={element.description}
            disabled={!editable}
            fluid
            value={element.value}
          />
        );
      case YEARPICKER:
        return (
          <Datepicker
            selectable
            yearOnly
            preventOpen
            onFocus={onFocus}
            label={element.label}
            placeholder={element.placeholder}
            disabled={!editable}
            value={element.value}
          />
        );
      default:
        throw new Error(`The type of element provided does not exist: ${element.type}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editable, element]);
};

const SectionBuilder = ({ section, onElementClick, editable }) => {
  const formRows = useMemo(() => {
    return section.elements.reduce((accum, element, currentIndex) => {
      const exists = Object.keys(accum).some((key) => key.includes(element.id));
      if (exists) {
        return accum;
      }

      if (element.filled) {
        accum = {
          ...accum,
          [element.id]: (
            <FormRow>
              <section className="form-canvas__element" onClick={() => onElementClick(element)}>
                <FormElement
                  editable={editable}
                  onFocus={() => onElementClick(element)}
                  onClick={() => onElementClick(element)}
                  key={element.id}
                  element={element}
                />
              </section>
            </FormRow>
          )
        };
        return accum;
      }

      const nextElement = section.elements[currentIndex + 1];
      if (nextElement && !nextElement.filled) {
        accum = {
          ...accum,
          [`${element.id}_${nextElement.id}`]: (
            <FormRow fluidSpaced>
              <section className="form-canvas__element" onClick={() => onElementClick(element)}>
                <FormElement
                  editable={editable}
                  onFocus={() => onElementClick(element)}
                  onClick={() => onElementClick(element)}
                  key={element.id}
                  element={element}
                />
              </section>
              <section className="form-canvas__element" onClick={() => onElementClick(nextElement)}>
                <FormElement
                  editable={editable}
                  onFocus={() => onElementClick(nextElement)}
                  onClick={() => onElementClick(nextElement)}
                  key={nextElement.id}
                  element={nextElement}
                />
              </section>
            </FormRow>
          )
        };
      } else {
        accum = {
          ...accum,
          [element.id]: (
            <FormRow singleRow>
              <section className="form-canvas__element" onClick={() => onElementClick(element)}>
                <FormElement
                  editable={editable}
                  onFocus={() => onElementClick(element)}
                  onClick={() => onElementClick(element)}
                  key={element.id}
                  element={element}
                />
              </section>
            </FormRow>
          )
        };
      }

      return accum;
    }, []);
  }, [editable, onElementClick, section.elements]);

  return Object.entries(formRows).map(([key, Row]) => (
    <React.Fragment key={key}>{Row}</React.Fragment>
  ));
};

const FormCanvas = ({ handleChange, editable, showTitle }) => {
  const {
    sections,
    onActivateSection,
    onContextChange,
    activeSection,
    onCloseProperties,
    onCloseSelector,
    context,
    onRemoveElement
  } = useFormBuilderContext();

  const handleElementClick = (element) => {
    onContextChange(element);
  };

  useEffect(() => {
    handleChange({ entryForm: sections });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);

  useEffect(() => {
    if (!editable) {
      onCloseProperties();
      onCloseSelector();
      onActivateSection(undefined, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editable]);

  return (
    <article className={getClassNames('form-canvas', { editable })}>
      {(sections ?? []).map((section, index) => (
        <section
          className={getClassNames('form-canvas__section', {
            active: editable && activeSection?.id === section.id
          })}
          key={section.id}
        >
          <DetailsSection title={!editable && showTitle && index === 0 ? 'Entry Form Details' : ''}>
            <header
              className={getClassNames('form-canvas__section-header', {
                active: editable && activeSection?.id === section.id
              })}
            >
              <h5
                className="form-canvas__section-title"
                onClick={() => {
                  if (!editable || activeSection?.id !== section.id) {
                    return;
                  }

                  handleElementClick(section);
                }}
              >
                {section.title}
              </h5>
              {editable &&
                (activeSection?.id !== section.id || (!!context && context?.type !== SECTION)) && (
                  <EditIcon onClick={() => onActivateSection(section)} />
                )}
              {editable &&
                activeSection?.id === section.id &&
                (!context || context?.type === SECTION) && (
                  <TrashIcon
                    className="form-canvas__delete-icon"
                    onClick={() => onRemoveElement(section)}
                  />
                )}
            </header>
            <Form>
              <SectionBuilder
                editable={editable && activeSection?.id === section.id}
                section={section}
                onElementClick={handleElementClick}
              />
            </Form>
          </DetailsSection>
        </section>
      ))}
    </article>
  );
};

FormCanvas.defaultProps = {
  sections: array,
  editable: false,
  showTitle: true,
  handleChange: noOp
};

export default FormCanvas;
