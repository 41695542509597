import React from 'react';

import { getClassNames } from '@neslotech/utils';

import { ReactComponent as Logo } from '../../../images/logo.svg';

import './auth-layout.scss';

const renderLogo = (noLogo, title, subtitle, large) =>
  !noLogo && (
    <header className="auth-layout__content-header">
      <Logo />
      {large && <h3>{title}</h3>}
      {!large && <p>{title}</p>}
      {subtitle && <p className="auth-layout__subtitle">{subtitle}</p>}
    </header>
  );

const renderNoLogo = (noLogo, title, subtitle) =>
  noLogo && (
    <header className="auth-layout__content-header">
      <h3>{title}</h3>
      {subtitle && <p className="auth-layout__subtitle">{subtitle}</p>}
    </header>
  );

const AuthLayout = ({ title, subtitle, noLogo, large, children }) => (
  <section className="auth-layout">
    <h3>EventHub brings people together through live experiences.</h3>
    <section className={getClassNames('auth-layout__content', { large })}>
      {renderLogo(noLogo, title, subtitle, large)}
      {renderNoLogo(noLogo, title, subtitle)}
      <div className="auth-layout__content-form">{children}</div>
    </section>
  </section>
);

AuthLayout.defaultProps = {
  noLogo: false
};

export default AuthLayout;
