import React, { useEffect } from 'react';

import {
  Button,
  ButtonGroup,
  Datepicker,
  DetailsSection,
  DocumentDetail,
  DRAFT_STATUS,
  Form,
  FormRow,
  Input,
  RadioGroup,
  Table,
  TextArea,
  Timepicker
} from '@neslotech/eventhub-ui-kit';
import { convertTimeToDate, isEmpty } from '@neslotech/utils';

import { classesTableHeaders, membersTableHeaders } from '../../details/event-details.helpers';

import { useWizardContext } from '../../../../hooks/useWizardContext';
import { useFormBuilderContext } from '../../../../hooks/useFormBuilderContext';

import { ReactComponent as LocationIcon } from '../../../../icons/location-icon.svg';

import BankingDetailsForm from '../../../banking-details/BankingDetailsForm';
import FormCanvas from '../../../form-builder/FormCanvas';

import './preview.scss';

const EventDetailsSection = ({ event }) => (
  <section className="event-details-section">
    <Form>
      <FormRow fluidSpaced>
        <Input
          name="name"
          placeholder="Event Name"
          label="Event Name"
          value={event.name}
          disabled
        />
        <section className="add-event-details__times">
          <Timepicker
            name="startTime"
            label="Start Time"
            placeholder="Start Time"
            initialValue={convertTimeToDate(event.startTime)}
            disabled
          />
          <Timepicker
            name="endTime"
            label="End Time"
            placeholder="End Time"
            initialValue={convertTimeToDate(event.endTime)}
            disabled
          />
        </section>
      </FormRow>
      <FormRow fluidSpaced>
        <Datepicker
          name="startDate"
          placeholder="Start Date"
          label="Start Date"
          value={event.startDate}
          disabled
        />
        <Datepicker
          name="endDate"
          placeholder="End Date"
          label="End Date"
          value={event.endDate}
          disabled
        />
      </FormRow>
      <FormRow fluidSpaced>
        <Input
          name="venue"
          label="Location"
          placeholder="Location"
          action={<LocationIcon />}
          value={event.venue}
          disabled
        />
        <RadioGroup
          name="national"
          label="National or Regional Event"
          fluid
          value={event.national}
          disabled
          items={[
            { label: 'National', value: true },
            { label: 'Regional', value: false }
          ]}
        />
      </FormRow>
      <FormRow fluidSpaced>
        <Datepicker
          name="entryStartDate"
          placeholder="Entry Start Date"
          label="Entry Start Date"
          value={event.entryStartDate}
          disabled
        />
        <Datepicker
          name="entryEndDate"
          placeholder="Entry End Date"
          label="Entry End Date"
          value={event.entryEndDate}
          disabled
        />
      </FormRow>
      <FormRow fluidSpaced>
        <Input
          name="attendees"
          label="Amount of Attendees"
          placeholder="Amount of Attendees"
          value={event.attendees}
          disabled
        />
        <Input
          name="price"
          label="Attendee Price"
          placeholder="Attendee Price"
          prefix="R"
          value={event.price}
          disabled
        />
      </FormRow>
      <FormRow fluidSpaced>
        <RadioGroup
          name="refundable"
          label="Refundable Event"
          fluid
          value={event.refundable}
          disabled
          items={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
        />
        <RadioGroup
          name="visibleEntries"
          label="Do you want participants entries to be visible to other entrants?"
          fluid
          value={event.visibleEntries}
          disabled
          items={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
        />
      </FormRow>
      <FormRow>
        <RadioGroup
          label="Are you using your supplied banking details?"
          disabled
          fluid
          name="suppliedBankingDetails"
          value={event.suppliedBankingDetails}
          items={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
        />
      </FormRow>
    </Form>
  </section>
);

const DescriptionSection = ({ event }) => (
  <DetailsSection title="Event Description">
    <section className="description-section">
      <Form>
        <FormRow>
          <TextArea placeholder="Enter Event Description" value={event.description} disabled />
        </FormRow>
      </Form>
    </section>
  </DetailsSection>
);

const ClassesSection = ({ event }) => (
  <DetailsSection title="Classes in the Event">
    <section className="classes-section">
      <Table
        cols={classesTableHeaders}
        rowData={(event.classes ?? []).filter((klass) => klass.selected)}
      />
    </section>
  </DetailsSection>
);

const MembersSection = ({ event }) => (
  <DetailsSection title="Member Selection">
    <section className="members-section">
      <Table
        cols={membersTableHeaders}
        rowData={(event.members ?? []).filter((type) => type.selected)}
      />
    </section>
  </DetailsSection>
);

const tableHeaders = [
  { Header: 'Rider Place', accessor: 'place' },
  { Header: 'Points per place', accessor: 'points' }
];
const PointsConfigurationSection = ({ pointsConfiguration }) => (
  <section className="add-preview__points-config">
    <DetailsSection title="Points Configuration Details">
      <Table cols={tableHeaders} rowData={pointsConfiguration.places} />
    </DetailsSection>
    {pointsConfiguration?.description && (
      <DetailsSection title="" subtitle="Points configuration description">
        <TextArea
          placeholder="Enter your description"
          value={pointsConfiguration.description}
          disabled
        />
      </DetailsSection>
    )}
  </section>
);

const EntryFormSection = ({ event }) => {
  const { onSetSections } = useFormBuilderContext();

  useEffect(() => {
    onSetSections(event.entryForm);
  }, [event.entryForm, onSetSections]);

  return (
    <section className="add-preview__entry-form">
      <FormCanvas />
    </section>
  );
};

const Preview = () => {
  const { entity, onDiscard, onPublish, onSaveAsDraft } = useWizardContext();

  return (
    <>
      <section className="add-preview">
        <DetailsSection title="Event Details">
          <EventDetailsSection event={entity?.details} />
        </DetailsSection>
        {entity?.details?.bankingDetails && (
          <DetailsSection title="Banking Details">
            <BankingDetailsForm entity={entity?.details?.bankingDetails} disabled />
          </DetailsSection>
        )}
        <DescriptionSection event={entity?.details} />
        {!isEmpty(entity?.details?.additionalLogos) && (
          <section className="add-event__documents">
            {entity?.details?.additionalLogos.map((logo) => (
              <DocumentDetail key={logo.id} name={logo.name} />
            ))}
          </section>
        )}
        <DetailsSection title="Supporting Documents" noCard>
          <section className="add-event__documents">
            {entity?.details?.supportingDocuments.map((document) => (
              <DocumentDetail key={document.id} name={document.name} />
            ))}
          </section>
        </DetailsSection>
        <ClassesSection event={entity?.details} />
        <MembersSection event={entity?.details} />
        {!isEmpty(entity?.points_configuration?.places) && (
          <PointsConfigurationSection pointsConfiguration={entity.points_configuration} />
        )}
        {entity?.entry_form && <EntryFormSection event={entity.entry_form} />}
      </section>
      <section className="wizard-layout__actions">
        <ButtonGroup fluid spaced>
          <Button
            disabled={entity?.details.competition_status === DRAFT_STATUS}
            label="Publish"
            onClick={onPublish}
          />
          <Button label="Save Draft" hollow onClick={onSaveAsDraft} />
        </ButtonGroup>
        <Button label="Clear Page" secondaryDanger onClick={onDiscard} />
      </section>
    </>
  );
};

export default Preview;
