import React, { useMemo } from 'react';

import { Card, Tooltip } from '@neslotech/eventhub-ui-kit';

import Badge from '../../organisation/badge/Badge';

import OrganisationBadge from '../../organisation/badge/OrganisationBadge';

import './organisation-badges.scss';

const OrganisationBadges = ({ organisations }) => {
  const renderOrganisationsBadges = useMemo(() => {
    const hasMultipleOrganisations = organisations?.length > 5;

    const organisationNames = (organisations ?? [])
      .map((organisation, index) => {
        if (index < 5 && hasMultipleOrganisations) {
          return null;
        }

        return organisation.name;
      })
      .filter((item) => !!item);

    return (
      <section className="organisation-badges__badges">
        {(organisations ?? []).map((organisation, index) => {
          if (index > 4 && hasMultipleOrganisations) {
            return null;
          }

          return (
            <Tooltip
              align="center"
              key={organisation.id}
              parentId={`organisation_detail_badge_${organisation.id}`}
              content={organisation.name}
            >
              <Badge
                id={`organisation_detail_badge_${organisation.id}`}
                medium
                image={
                  <OrganisationBadge
                    name={organisation.name}
                    imgSrc={organisation.image}
                    medium
                    round
                  />
                }
              />
            </Tooltip>
          );
        })}
        {hasMultipleOrganisations && (
          <Tooltip parentId="organisation_badges_extra" content={organisationNames.toString()}>
            <section id="organisation_badges_extra" className="organisation-badges__extra">
              <p>{organisations?.length - 5}</p>
            </section>
          </Tooltip>
        )}
      </section>
    );
  }, [organisations]);

  return (
    <Card>
      <section className="organisation-badges">
        <header>
          <h4>Organisation Badges</h4>
        </header>
        {renderOrganisationsBadges}
      </section>
    </Card>
  );
};

export default OrganisationBadges;
