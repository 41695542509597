import React from 'react';

import { Button, ButtonGroup } from '@neslotech/eventhub-ui-kit';

import './remove-organiser.scss';

const RemoveOrganiser = ({ handleConfirm, onClose }) => (
  <section className="remove-organiser">
    <section className="remove-organiser__message">
      <p>
        Removing an organiser takes away their ability to view internal organisational details or
        make changes to the organisation
      </p>
    </section>
    <ButtonGroup>
      <Button label="Confirm" onClick={handleConfirm} />
      <Button label="Cancel" onClick={onClose} secondary />
    </ButtonGroup>
  </section>
);

export default RemoveOrganiser;
