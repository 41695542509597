import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import DuplicateCompetition from '../../../../components/competitions/duplicate/DuplicateCompetition';

const DuplicateCompetitionContainer = ({ competitionId, show, setShowModal }) => {
  const navigate = useNavigate();

  const onClose = () => setShowModal(false);

  const handleView = () => {
    onClose();
    navigate(`/competitions/${competitionId}`);
  };

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout onClose={onClose} title="Duplicate Found">
            <DuplicateCompetition onConfirmClick={handleView} onCancelClick={onClose} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default DuplicateCompetitionContainer;
