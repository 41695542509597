import { SET_ENTRIES, SET_ENTRY } from '../actions/entry.actions';

export const initialState = {
  entries: [],
  entry: undefined
};

export const entryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ENTRIES:
      const { entries } = action;
      return {
        ...state,
        entries
      };
    case SET_ENTRY:
      const { entry } = action;
      return {
        ...state,
        entry
      };
    default:
      return state;
  }
};
