import React, { useMemo, useState } from 'react';

import {
  Button,
  ButtonGroup,
  DetailsSection,
  ENTRY_FORM_TYPE,
  POINTS_CONFIG_TYPE
} from '@neslotech/eventhub-ui-kit';
import { getClassNames } from '@neslotech/utils';

import FormBuilderProvider from '../../../providers/FormBuilder.provider';

import { ReactComponent as EditIcon } from '../../../icons/blue-edit-icon.svg';

import PointsConfigTemplate from './points-config/PointsConfigTemplate';
import EntryFormTemplate from './entry-form/EntryFormTemplate';

import './template-details.scss';

const TemplateDetails = ({ template, onUpdate, onRemove }) => {
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState();

  const handleChange = (value) => {
    setFormData(value);
  };

  const data = useMemo(() => {
    if (!template) {
      return [];
    }

    return template.data;
  }, [template]);

  const actions = useMemo(() => {
    const handleUpdate = () => {
      onUpdate(formData);
      setEditMode(false);
    };

    if (editMode) {
      return (
        <ButtonGroup fluid spaced>
          <Button label="Update" onClick={handleUpdate} />
          <Button danger label="Cancel" onClick={() => setEditMode(false)} />
        </ButtonGroup>
      );
    }

    return <Button label="Delete" danger onClick={() => onRemove()} />;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, formData]);

  return (
    <FormBuilderProvider existingEntity={{ entry_form: data }}>
      <article className={getClassNames('template-details', { editMode })}>
        <DetailsSection title="">
          <header className="template-details__header">
            <h5>{template.name}</h5>
            <EditIcon onClick={() => setEditMode(true)} />
          </header>
          {template.template_type === ENTRY_FORM_TYPE && (
            <EntryFormTemplate template={data} editMode={editMode} onChange={handleChange} />
          )}
          {template.template_type === POINTS_CONFIG_TYPE && (
            <PointsConfigTemplate editMode={editMode} template={data} onChange={handleChange} />
          )}
        </DetailsSection>
        <section className="template-details__actions">{actions}</section>
      </article>
    </FormBuilderProvider>
  );
};

export default TemplateDetails;
