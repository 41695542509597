export const SET_PROFILE = '[PROFILE_STORE] Set profile';

export const RETRIEVE_PROFILE = '[PROFILE_STORE] Retrieve profile';
export const retrieveProfile = (onSuccess) => ({
  type: RETRIEVE_PROFILE,
  onSuccess
});

export const UPDATE_PROFILE = '[PROFILE_STORE] Update my profile';
export const updateProfile = (profile) => ({
  type: UPDATE_PROFILE,
  profile
});
