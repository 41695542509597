export const classesTableHeaders = [
  { Header: 'Class Name', accessor: 'name' },
  { Header: '1st Class', accessor: 'firstClassPrice' },
  { Header: '2nd Class', accessor: 'secondClassPrice' },
  { Header: '1st Class (Family Member)', accessor: 'firstClassFamilyPrice' },
  { Header: '2nd Class (Family Member)', accessor: 'secondClassFamilyPrice' }
];

export const membersTableHeaders = [
  { Header: 'Member Type', accessor: 'name' },
  { Header: 'Price', accessor: 'price' },
  { Header: 'Limit', accessor: 'limit' }
];
