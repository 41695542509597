import React from 'react';
import { Route, Routes } from 'react-router-dom';

import LoginContainer from './login/Login.container';
import ForgotPasswordContainer from './forgot-password/ForgotPassword.container';
import ResetPasswordContainer from './reset-password/ResetPassword.container';
import PasswordExpiredLinkContainer from './expired-link/PasswordExpiredLink.container';
import RegisterContainer from './register/Register.container';
import OrganisationDetailsContainer from './organisation/details/OrganisationDetails.container';
import SuccessContainer from './success/Success.container';
import VerifyEmailContainer from './verify-email/VerifyEmail.container';
import VerificationExpiredLinkContainer from './expired-link/VerificationExpiredLink.container';
import CompleteProfileContainer from './complete-profile/CompleteProfile.container';
import OrganisationSelectionContainer from './organisation/selection/OrganisationSelection.container';

const AuthContainer = () => (
  <Routes>
    <Route exact path="/" element={<LoginContainer />} />

    <Route exact path="/register" element={<RegisterContainer />} />
    <Route
      exact
      path="/register/:id/organisation-details"
      element={<OrganisationDetailsContainer />}
    />
    <Route exact path="/organisation-selection" element={<OrganisationSelectionContainer />} />
    <Route exact path="/register/:id/success" element={<SuccessContainer />} />

    <Route exact path="/password/forgot" element={<ForgotPasswordContainer />} />
    <Route exact path="/password/:token/reset" element={<ResetPasswordContainer />} />
    <Route exact path="/password/:token/expired" element={<PasswordExpiredLinkContainer />} />

    <Route exact path="/profile/:token/verify-email" element={<VerifyEmailContainer />} />
    <Route exact path="/profile/:token/expired" element={<VerificationExpiredLinkContainer />} />
    <Route exact path="/profile/:token/complete-profile" element={<CompleteProfileContainer />} />
  </Routes>
);

export default AuthContainer;
