import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Loader, PageLayout, UPCOMING_STATUS } from '@neslotech/eventhub-ui-kit';

import { loadCompetition, publishCompetition } from '../../../../actions/competition.actions';
import { loadSports } from '../../../../actions/system.actions';

import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';
import FormBuilderProvider from '../../../../providers/FormBuilder.provider';

import OrganizerLayout from '../../../../components/layout/organizer/OrganizerLayout';
import CompetitionDetails from '../../../../components/competitions/details/CompetitionDetails';

import CancelCompetitionContainer from '../cancel/CancelCompetition.container';
import RemoveCompetitionContainer from '../remove/RemoveCompetition.container';
import ReinstateCompetitionContainer from '../reinstate/ReinstateCompetition.container';
import PostponeCompetitionContainer from '../postpone/PostponeCompetition.container';

const CompetitionContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id: competitionId } = useParams();
  const { id, verified } = useOrganisationContext();

  const competition = useSelector(({ competition_store }) => competition_store.competition);
  const stateLoading = useSelector(({ competition_store }) => competition_store.loading);

  const [modalContext, setModalContext] = useState('');
  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadSports());
    dispatch(loadCompetition(id, competitionId, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, competitionId]);

  const handlePublish = () => {
    competition.status = UPCOMING_STATUS;
    dispatch(publishCompetition(id, competitionId, navigate));
  };

  return (
    <>
      <OrganizerLayout gutterless>
        <PageLayout title="View Competition Details" fixed back>
          {loading && <Loader filled />}
          {!loading && (
            <FormBuilderProvider existingEntity={competition}>
              <CompetitionDetails
                competition={competition}
                onCancel={() => setModalContext('cancel')}
                onDelete={() => setModalContext('remove')}
                onReinstate={() => setModalContext('reinstate')}
                onPostpone={() => setModalContext('postpone')}
                onPublish={handlePublish}
                verified={verified}
              />
            </FormBuilderProvider>
          )}
        </PageLayout>
      </OrganizerLayout>
      <RemoveCompetitionContainer
        competition={competition}
        show={modalContext === 'remove'}
        setShowModal={setModalContext}
      />
      <ReinstateCompetitionContainer
        competition={competition}
        show={modalContext === 'reinstate'}
        setShowModal={setModalContext}
      />
      <CancelCompetitionContainer
        competition={competition}
        show={modalContext === 'cancel'}
        setShowModal={setModalContext}
      />
      <PostponeCompetitionContainer
        competition={competition}
        show={modalContext === 'postpone'}
        setShowModal={setModalContext}
      />
    </>
  );
};

export default CompetitionContainer;
