import React, { useMemo } from 'react';

import { AddAction, Input, Table } from '@neslotech/eventhub-ui-kit';
import { generateId } from '@neslotech/utils';

import { ReactComponent as RemoveIcon } from '../../../../icons/x-icon.svg';

import '../add-competition.scss';

export const tableHeaders = [
  { Header: '', accessor: 'icon', disableSortBy: true },
  { Header: 'Member Type', accessor: 'name' },
  { Header: 'Price', accessor: 'price' },
  { Header: 'Limit', accessor: 'limit' }
];

const EMPTY_CREW_MEMBER = () => ({
  name: '',
  price: '',
  limit: '',
  _id: generateId()
});

const CompetitionMembers = ({ form, handleChange }) => {
  const mappedData = useMemo(() => {
    const handleValueChange = (index, value) => {
      const members = form?.members ?? [];
      let type = form?.members[index] ?? {};
      type = {
        ...type,
        ...value
      };
      members[index] = type;

      handleChange({ members });
    };

    return (form?.members ?? [])
      .filter((item) => !item._destroy)
      .map((type, index) => ({
        name: (
          <Input
            borderless
            name="name"
            placeholder="Crew Member"
            value={type.name}
            autoComplete="off"
            onChange={(val) => handleValueChange(index, val)}
          />
        ),
        price: (
          <Input
            borderless
            type="number"
            name="price"
            placeholder="50"
            prefix="R"
            value={type.price}
            autoComplete="off"
            onChange={(val) => handleValueChange(index, val)}
          />
        ),
        limit: (
          <Input
            borderless
            type="number"
            name="limit"
            placeholder="5"
            value={type.limit}
            autoComplete="off"
            onChange={(val) => handleValueChange(index, val)}
          />
        ),
        icon: <RemoveIcon onClick={() => removeRow(type)} />
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.members, handleChange]);

  const addNewRow = () => {
    const members = form?.members ?? [];
    members.push(EMPTY_CREW_MEMBER());
    handleChange({ members });
  };

  const removeRow = (type) => {
    const members = (form?.members ?? []).reduce((accum, member) => {
      if (member._id === type._id) {
        member._destroy = '1';
      }

      return [...accum, member];
    }, []);
    handleChange({ members });
  };

  return (
    <section className="add-competition-members">
      <Table cols={tableHeaders} rowData={mappedData} />
      <AddAction title="Add New Member Type" onClick={addNewRow} />
    </section>
  );
};

export default CompetitionMembers;
