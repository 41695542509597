import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { requestPasswordReset } from '../../../actions/auth.actions';

import AuthLayout from '../../../components/layout/auth/AuthLayout';
import ForgotPassword from '../../../components/auth/forgot-password/ForgotPassword';

const subtitle =
  'Please enter your email address below and we will send you a link to reset your password';

const ForgotPasswordContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onRequestLink = (payload) => {
    dispatch(requestPasswordReset(payload, navigate));
  };

  return (
    <AuthLayout title="Forgot Password?" subtitle={subtitle} noLogo>
      <ForgotPassword onRequestLink={onRequestLink} />
    </AuthLayout>
  );
};

export default ForgotPasswordContainer;
