import React from 'react';

import { TextArea } from '@neslotech/eventhub-ui-kit';

import '../add-event.scss';

const EventDescription = ({ form, handleChange }) => (
  <section className="add-event-description">
    <TextArea
      name="description"
      placeholder="Please enter a description for your event."
      value={form?.description}
      onChange={handleChange}
    />
  </section>
);

export default EventDescription;
