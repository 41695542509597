import React from 'react';

import OrganiserPersonalDetails from './personal/OrganiserPersonalDetails';
import OrganiserAccountDetails from './account/OrganiserAccountDetails';
import OrganisationBadges from '../../profile/badges/OrganisationBadges';

import { Button } from '@neslotech/eventhub-ui-kit';

import './organiser-details.scss';

const OrganiserDetails = ({ organiser, setShowModal }) => {
  const onOpen = () => {
    setShowModal(true);
  };

  return (
    <section className="organiser-details">
      <OrganiserPersonalDetails organiser={organiser} />
      <section className="organiser-details__split">
        <OrganisationBadges organisations={organiser?.organisations} />
        <OrganiserAccountDetails organiser={organiser} />
      </section>
      {organiser?.can_remove_organisers && (
        <footer className="organiser-details__actions">
          <Button label="Remove Organiser" longText danger onClick={onOpen} />
        </footer>
      )}
    </section>
  );
};

export default OrganiserDetails;
