import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { PageLayout } from '@neslotech/eventhub-ui-kit';

import {
  DETAILS_KEY,
  ENTRY_FORM_KEY,
  POINTS_CONFIG_KEY,
  PREVIEW_KEY
} from '../../../../tools/constants';

import FormBuilderProvider from '../../../../providers/FormBuilder.provider';

import { clearCompetition } from '../../../../actions/competition.actions';

import CompetitionWizardProvider from '../../../../providers/wizard/CompetitionWizard.provider';
import DuplicateCompetitionContainer from '../duplicate/DuplicateCompetition.container';

import OrganizerLayout from '../../../../components/layout/organizer/OrganizerLayout';
import Wizard from '../../../../components/wizard/Wizard';
import AddCompetition from '../../../../components/competitions/add/AddCompetition';
import EntryForm from '../../../../components/competitions/add/entry-form/EntryForm';
import PointsConfiguration from '../../../../components/competitions/add/points-configuration/PointsConfiguration';
import Preview from '../../../../components/competitions/add/preview/Preview';

const steps = [
  {
    key: DETAILS_KEY,
    index: 1,
    label: 'Create a competition',
    component: AddCompetition
  },
  {
    key: POINTS_CONFIG_KEY,
    index: 2,
    label: 'Create points configuration',
    component: PointsConfiguration
  },
  {
    key: ENTRY_FORM_KEY,
    index: 3,
    label: 'Create entry form',
    component: EntryForm
  },
  {
    key: PREVIEW_KEY,
    index: 4,
    label: 'Preview',
    component: Preview
  }
];

const AddCompetitionContainer = () => {
  const dispatch = useDispatch();

  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [duplicateId, setDuplicateId] = useState();

  useEffect(() => {
    dispatch(clearCompetition());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDuplicate = (competitionId) => {
    setDuplicateId(competitionId);
    setShowDuplicateModal(true);
  };

  return (
    <>
      <OrganizerLayout gutterless>
        <PageLayout back title="Create New Competition" fixed>
          <CompetitionWizardProvider onDuplicateFound={handleDuplicate}>
            <FormBuilderProvider>
              <Wizard steps={steps} />
            </FormBuilderProvider>
          </CompetitionWizardProvider>
        </PageLayout>
      </OrganizerLayout>
      <DuplicateCompetitionContainer
        competitionId={duplicateId}
        setShowModal={setShowDuplicateModal}
        show={showDuplicateModal}
      />
    </>
  );
};

export default AddCompetitionContainer;
