import React from 'react';

import { Button, ButtonGroup, Form, FormRow, TextArea } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import './remove-entry.scss';

const rules = {
  validates: {
    reason: ['isPresent']
  }
};

const serverify = ({ reason }) => ({ reason });

const RemoveEntry = ({ entry, onConfirmClick, onCancelClick }) => {
  const [form, setForm] = useFormState(undefined, rules);

  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleConfirm = () => {
    const payload = serverify(form);
    onConfirmClick(payload);
  };

  return (
    <article className="remove-entry">
      <section className="remove-entry__message">
        <p>
          Please provide a reason(s) for rejecting <span>{entry?.created_by}'s</span> entry form
          below
        </p>
      </section>
      <section className="remove-entry__reason">
        <Form>
          <FormRow>
            <TextArea
              name="reason"
              boxed
              shadowed
              label="Reason:"
              placeholder="Enter a reason for rejection"
              onChange={handleChange}
              value={form?.reason}
              error={form?.messages?.reason}
            />
          </FormRow>
        </Form>
      </section>
      <ButtonGroup>
        <Button disabled={!form?.valid} label="Confirm" onClick={handleConfirm} />
        <Button label="Cancel" onClick={onCancelClick} secondary />
      </ButtonGroup>
    </article>
  );
};

export default RemoveEntry;
