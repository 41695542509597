import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import DuplicateEvent from '../../../../components/events/duplicate/DuplicateEvent';

const DuplicateEventContainer = ({ competitionId, eventId, show, setShowModal }) => {
  const navigate = useNavigate();

  const onClose = () => setShowModal(false);

  const handleView = () => {
    onClose();
    navigate(`/competitions/${competitionId}/events/${eventId}`);
  };

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout onClose={onClose} title="Duplicate Found">
            <DuplicateEvent onConfirmClick={handleView} onCancelClick={onClose} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default DuplicateEventContainer;
