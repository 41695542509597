import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { REGISTER } from '@neslotech/eventhub-ui-kit';

import { createOrganisation } from '../../../../actions/organisation.actions';

import AuthLayout from '../../../../components/layout/auth/AuthLayout';
import OrganisationDetails from '../../../../components/auth/organisation/details/OrganisationDetails';

const OrganisationDetailsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const onCreateOrganisation = (payload) => {
    payload.user_id = id;
    dispatch(createOrganisation(payload, navigate, REGISTER));
  };

  return (
    <AuthLayout title="Organisation Information" subtitle="We just need a few more details" noLogo>
      <OrganisationDetails onSave={onCreateOrganisation} />
    </AuthLayout>
  );
};

export default OrganisationDetailsContainer;
