import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getAuthTokenCookie, removeAuthTokenCookie } from '../tools/auth.util';
import { removeOrganisationIdCookie } from '../tools/organisation.util';

import { AuthContext } from '../contexts/Auth.context';

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState(getAuthTokenCookie());

  const handleLogout = () => {
    setToken(null);
    removeAuthTokenCookie();
    removeOrganisationIdCookie();

    navigate('/');
  };

  const value = {
    token,
    onLogout: handleLogout
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
