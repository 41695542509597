import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '@neslotech/eventhub-ui-kit';

import { loadOrganisation } from '../../../actions/organisation.actions';
import { useOrganisationContext } from '../../../hooks/useOrganisationContext';

import OrganizerLayout from '../../../components/layout/organizer/OrganizerLayout';
import Dashboard from '../../../components/dashboard/Dashboard';
import InviteOrganiserContainer from '../organisers/invite/InviteOrganiser.container';

const DashboardContainer = () => {
  const { id, verified } = useOrganisationContext();
  const dispatch = useDispatch();
  const organisation = useSelector(({ organisation_store }) => organisation_store.organisation);
  const stateLoading = useSelector(({ organisation_store }) => organisation_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(loadOrganisation(id, () => setLoading(false)));
  }, [dispatch, id]);

  return (
    <>
      <OrganizerLayout>
        {loading && <Loader filled />}
        {!loading && (
          <Dashboard
            verified={verified}
            organisation={organisation}
            onAddOrganiserClick={() => setShowModal(true)}
          />
        )}
      </OrganizerLayout>
      <InviteOrganiserContainer setShowModal={setShowModal} show={showModal} />
    </>
  );
};

export default DashboardContainer;
