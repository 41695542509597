import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  DRAFT_STATUS,
  FilterLayout,
  Loader,
  PageLayout,
  PUBLISHED_STATUS,
  RadioGroup
} from '@neslotech/eventhub-ui-kit';
import { noOp } from '@neslotech/utils';

import { useOrganisationContext } from '../../../hooks/useOrganisationContext';

import { loadTemplates } from '../../../actions/template.actions';

import { ReactComponent as ChevronRightIcon } from '../../../icons/chevron-right-icon.svg';

import OrganizerLayout from '../../../components/layout/organizer/OrganizerLayout';
import TemplatesList from '../../../components/templates/TemplatesList';

const menuItems = (handleChange, filters) => [
  {
    text: 'Status',
    onClick: noOp,
    suffix: <ChevronRightIcon />,
    expandable: true,
    expandedItems: (
      <RadioGroup
        onChange={handleChange}
        value={filters?.status}
        name="status"
        items={[
          { label: 'Published', value: PUBLISHED_STATUS },
          { label: 'Draft', value: DRAFT_STATUS }
        ]}
      />
    )
  }
];

const TemplatesContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useOrganisationContext();

  const templates = useSelector(({ template_store }) => template_store.templates);
  const stateLoading = useSelector(({ template_store }) => template_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadTemplates(id, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const mappedData = useMemo(() => {
    return templates.map((template) => ({
      ...template,
      onClick: () => navigate(`${template.id}`)
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates]);

  return (
    <OrganizerLayout>
      <PageLayout title="Manage All Templates">
        <FilterLayout searchPlaceholder="Search for a template..." menuItems={menuItems}>
          {loading && <Loader filled />}
          {!loading && <TemplatesList templates={mappedData} />}
        </FilterLayout>
      </PageLayout>
    </OrganizerLayout>
  );
};

export default TemplatesContainer;
