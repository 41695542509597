import React from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  ButtonGroup,
  Form,
  FormAction,
  FormRow,
  Input,
  ORGANIZER_ROLE
} from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import './forgot-password.scss';

const rules = {
  validates: {
    email: ['isPresent', 'isEmail']
  }
};

const serverify = ({ email }) => ({
  email,
  role: ORGANIZER_ROLE
});

const ForgotPassword = ({ onRequestLink }) => {
  const [form, setForm] = useFormState(undefined, rules);
  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleSubmit = () => {
    const payload = serverify(form);
    onRequestLink(payload);
  };

  return (
    <section className="forgot-password">
      <Form>
        <FormRow>
          <Input
            name="email"
            label="Email Address"
            placeholder="Enter your email"
            type="email"
            value={form?.email}
            onChange={handleChange}
            error={form?.messages?.email}
          />
        </FormRow>
        <ButtonGroup>
          <FormAction label="Request Link" disabled={!form?.valid} onClick={handleSubmit} />
          <Link to="/">
            <Button secondary label="Cancel" />
          </Link>
        </ButtonGroup>
      </Form>
    </section>
  );
};

export default ForgotPassword;
