import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Loader, PageLayout, UPCOMING_STATUS } from '@neslotech/eventhub-ui-kit';
import { loadEvent, loadEventSchedule, publishEvent } from '../../../../actions/event.actions';

import FormBuilderProvider from '../../../../providers/FormBuilder.provider';
import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';

import OrganizerLayout from '../../../../components/layout/organizer/OrganizerLayout';
import EventDetails from '../../../../components/events/details/EventDetails';

import CancelEventContainer from '../cancel/CancelEvent.container';
import RemoveEventContainer from '../remove/RemoveEvent.container';
import ReinstateEventContainer from '../reinstate/ReinstateEvent.container';
import PostponeEventContainer from '../postpone/PostponeEvent.container';

const EventContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id: eventId, competitionId } = useParams();
  const { id, verified } = useOrganisationContext();

  const event = useSelector(({ event_store }) => event_store.event);
  const schedule = useSelector(({ event_store }) => event_store.schedule);
  const stateLoading = useSelector(({ event_store }) => event_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);
  const [modalContext, setModalContext] = useState('');

  const subtitle = useMemo(() => {
    return event?.competition && `Linked to: ${event.competition.name}`;
  }, [event]);

  useEffect(() => {
    dispatch(loadEvent(id, competitionId, eventId, () => setLoading(false)));
    dispatch(loadEventSchedule(eventId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, competitionId, eventId]);

  const handlePublish = () => {
    event.status = UPCOMING_STATUS;
    dispatch(publishEvent(id, competitionId, eventId, navigate));
  };

  const handleCreateSchedule = () =>
    navigate(`/competitions/${competitionId}/events/${eventId}/schedule/add`);

  return (
    <>
      <OrganizerLayout gutterless>
        <PageLayout title="View Event Details" subtitle={subtitle} fixed back>
          <FormBuilderProvider existingEntity={event}>
            {loading && <Loader filled />}
            {!loading && (
              <EventDetails
                event={event}
                schedule={schedule}
                onDelete={() => setModalContext('remove')}
                onCancel={() => setModalContext('cancel')}
                onReinstate={() => setModalContext('reinstate')}
                onPostpone={() => setModalContext('postpone')}
                onPublish={handlePublish}
                onCreateSchedule={handleCreateSchedule}
                verified={verified}
              />
            )}
          </FormBuilderProvider>
        </PageLayout>
      </OrganizerLayout>
      <CancelEventContainer
        event={event}
        show={modalContext === 'cancel'}
        setShowModal={setModalContext}
      />
      <RemoveEventContainer
        event={event}
        show={modalContext === 'remove'}
        setShowModal={setModalContext}
      />
      <ReinstateEventContainer
        event={event}
        show={modalContext === 'reinstate'}
        setShowModal={setModalContext}
      />
      <PostponeEventContainer
        event={event}
        show={modalContext === 'postpone'}
        setShowModal={setModalContext}
      />
    </>
  );
};

export default EventContainer;
