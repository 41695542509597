import React, { useEffect, useMemo } from 'react';

import {
  Button,
  ButtonGroup,
  Datepicker,
  DetailsSection,
  Form,
  FormRow,
  Input,
  RadioGroup,
  Select,
  Table,
  TextArea
} from '@neslotech/eventhub-ui-kit';

import { isEmpty } from '@neslotech/utils';

import {
  classesTableHeaders,
  membersTableHeaders
} from '../../details/competition-details.helpers';

import { useWizardContext } from '../../../../hooks/useWizardContext';
import { useFormBuilderContext } from '../../../../hooks/useFormBuilderContext';

import { ReactComponent as CalendarIcon } from '../../../../icons/calendar-icon.svg';

import BankingDetailsForm from '../../../banking-details/BankingDetailsForm';
import FormCanvas from '../../../form-builder/FormCanvas';

import './preview.scss';

const CompetitionDetailsSection = ({ competition }) => {
  const dateValue = useMemo(() => {
    if (competition.startDate instanceof Date) {
      return `${competition.startDate.toDateString()} - ${competition.endDate.toDateString()}`;
    }

    return `${competition.startDate} - ${competition.endDate}`;
  }, [competition.startDate, competition.endDate]);

  return (
    <section className="competition-details-section">
      <Form>
        <FormRow fluidSpaced>
          <Input
            placeholder="Competition Name"
            label="Competition Name"
            value={competition.name}
            disabled
          />
          <Select
            placeholder="Type of Competition"
            label="Type of Competition"
            value={competition.competitionType}
            disabled
          />
        </FormRow>
        <FormRow fluidSpaced>
          <Select placeholder="Sport" label="Sport" value={competition.sport} disabled />
          <Input
            placeholder="Start and End Date"
            label="Start and End Date"
            action={<CalendarIcon />}
            value={dateValue}
            disabled
          />
        </FormRow>
        <FormRow fluidSpaced>
          <Datepicker
            preventOpen
            disabled
            name="entryStartDate"
            placeholder="Entry Start Date"
            label="Entry Start Date"
            value={competition.entryStartDate}
          />
          <Datepicker
            preventOpen
            disabled
            name="entryEndDate"
            placeholder="Entry End Date"
            label="Entry End Date"
            value={competition.entryEndDate}
          />
        </FormRow>
        <FormRow fluidSpaced>
          <RadioGroup
            label="Paid or Free Competition"
            disabled
            fluid
            name="paid"
            value={competition.paid}
            items={[
              { label: 'Paid', value: true },
              { label: 'Free', value: false }
            ]}
          />
          <RadioGroup
            label="Regulated Competition"
            disabled
            fluid
            name="regulated"
            value={competition.regulated}
            items={[
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]}
          />
        </FormRow>
        <FormRow fluidSpaced>
          <RadioGroup
            label="Are you using your supplied banking details?"
            disabled
            fluid
            name="supplied_banking_details"
            value={competition.suppliedBankingDetails}
            items={[
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]}
          />
          <RadioGroup
            label="National or Regional Competition"
            disabled
            fluid
            name="national"
            value={competition.national}
            items={[
              { label: 'National', value: true },
              { label: 'Regional', value: false }
            ]}
          />
        </FormRow>
        <FormRow>
          <RadioGroup
            label="Do you want participants entries to be visible on the landing page?"
            disabled
            fluid
            name="visible_entries"
            value={competition.visibleEntries}
            items={[
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]}
          />
        </FormRow>
        {(competition.paid || competition.regulated) && (
          <FormRow
            singleRow={
              (competition.paid && !competition.regulated) ||
              (!competition.paid && competition.regulated)
            }
            fluidSpaced={competition.paid && competition.regulated}
          >
            {competition.paid && (
              <Input
                name="registrationFee"
                placeholder="Registration Fee"
                label="Registration Fee"
                value={competition?.registrationFee}
                disabled
              />
            )}
            {competition.regulated && (
              <Input
                name="regulationNumber"
                placeholder="Regulation Number"
                label="Regulation Number"
                value={competition?.regulationNumber}
                disabled
              />
            )}
          </FormRow>
        )}
      </Form>
    </section>
  );
};

const DescriptionSection = ({ competition }) => (
  <DetailsSection title="Competition Description">
    <section className="description-section">
      <Form>
        <FormRow>
          <TextArea
            placeholder="Enter Competition Description"
            value={competition.description}
            disabled
          />
        </FormRow>
      </Form>
    </section>
  </DetailsSection>
);

const ClassesSection = ({ competition }) => (
  <DetailsSection title="Classes in the Competition">
    <section className="classes-section">
      <Table cols={classesTableHeaders} rowData={competition.classes} />
    </section>
  </DetailsSection>
);

const MembersSection = ({ competition }) => (
  <DetailsSection title="Member Selection">
    <section className="members-section">
      <Table cols={membersTableHeaders} rowData={competition.members} />
    </section>
  </DetailsSection>
);

const tableHeaders = [
  { Header: 'Rider Place', accessor: 'place' },
  { Header: 'Points per place', accessor: 'points' }
];

const PointsConfigurationSection = ({ pointsConfiguration }) => (
  <section className="add-preview__points-config">
    <DetailsSection title="Points Configuration Details">
      <Table cols={tableHeaders} rowData={pointsConfiguration?.places} />
    </DetailsSection>
    <DetailsSection title="" subtitle="Points configuration description">
      <TextArea
        placeholder="Enter your description"
        value={pointsConfiguration.description}
        disabled
      />
    </DetailsSection>
  </section>
);

const EntryFormSection = ({ competition }) => {
  const { onSetSections } = useFormBuilderContext();

  useEffect(() => {
    onSetSections(competition.entryForm);
  }, [competition.entryForm, onSetSections]);

  return (
    <section className="add-preview__entry-form">
      <FormCanvas />
    </section>
  );
};

const Preview = () => {
  const { entity, onPublish, onSaveAsDraft, onDiscard } = useWizardContext();

  return (
    <>
      <section className="add-preview">
        <DetailsSection title="Competition Details">
          <CompetitionDetailsSection competition={entity.details} />
        </DetailsSection>
        {entity?.details?.bankingDetails && (
          <DetailsSection title="Banking Details">
            <BankingDetailsForm entity={entity.details.bankingDetails} disabled />
          </DetailsSection>
        )}
        <DescriptionSection competition={entity.details} />
        <ClassesSection competition={entity.details} />
        <MembersSection competition={entity.details} />
        {!isEmpty(entity?.points_configuration?.places) && (
          <PointsConfigurationSection pointsConfiguration={entity.points_configuration} />
        )}
        {entity?.entry_form && <EntryFormSection competition={entity.entry_form} />}
      </section>
      <section className="wizard-layout__actions">
        <ButtonGroup fluid spaced>
          <Button label="Publish" onClick={onPublish} />
          <Button label="Save Draft" hollow onClick={onSaveAsDraft} />
        </ButtonGroup>
        <Button label="Clear Page" secondaryDanger onClick={onDiscard} />
      </section>
    </>
  );
};

export default Preview;
