import React from 'react';

import { isEmpty } from '@neslotech/utils';
import { AddAction, Checkbox, Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import { useScheduleContext } from '../../../hooks/useScheduleContext';

import { ReactComponent as FlagIcon } from '../../../icons/flag-icon.svg';
import { ReactComponent as EditIcon } from '../../../icons/blue-edit-icon.svg';
import { ReactComponent as TrashIcon } from '../../../icons/trash-icon.svg';

import RemoveHeat from './remove/RemoveHeat';

import './class-schedule-heats.scss';

const ClassScheduleHeats = ({ heats, onAdd }) => {
  const {
    showHeatForm,
    setShowHeatForm,
    heat,
    setHeat,
    showRemoveModal,
    setShowRemoveModal,
    handleRemoveHeat
  } = useScheduleContext();

  const onEditClick = (heatToUpdate) => {
    setHeat(heatToUpdate);
    setShowHeatForm(true);
  };

  const onTrashClick = (heatToRemove) => {
    setHeat(heatToRemove);
    setShowRemoveModal(true);
  };

  const onClose = () => setShowRemoveModal(false);

  if (isEmpty(heats)) {
    return null;
  }

  return (
    <>
      <section className="schedule-heats">
        <ul className="schedule-heats__heats">
          {heats.map((value, index) => (
            <li key={index} className="schedule-heats__heat">
              <FlagIcon />
              <article className="schedule-heats__heat-details">
                <article className="schedule-heats__info">
                  <dl>
                    <dt>Heat Name</dt>
                    <dd>{value.name}</dd>
                  </dl>
                  <dl>
                    <dt>Heat Time</dt>
                    <dd>
                      {value.startTime} - {value.endTime}
                    </dd>
                  </dl>
                </article>
                <article className="schedule-heats__checks">
                  <Checkbox
                    name="priority"
                    label="Priority Heat"
                    checked={value.priority}
                    disabled
                  />
                  <Checkbox
                    name="priority"
                    label="Counts for points"
                    checked={value.counts}
                    disabled
                  />
                </article>
                <article className="schedule-heats__actions">
                  <article
                    className="schedule-heats__action--edit"
                    onClick={() => onEditClick({ ...value, key: index })}
                  >
                    <EditIcon />
                  </article>
                  <article
                    className="schedule-heats__action--trash"
                    onClick={() => onTrashClick({ ...value, key: index })}
                  >
                    <TrashIcon />
                  </article>
                </article>
              </article>
            </li>
          ))}
        </ul>
        {!showHeatForm && <AddAction title="Add New Heat" onClick={onAdd} />}
      </section>
      {showRemoveModal && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Heat">
            <RemoveHeat
              heat={heat}
              onConfirmClick={() => handleRemoveHeat(heat)}
              onCancelClick={onClose}
            />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default ClassScheduleHeats;
