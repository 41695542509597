import React from 'react';

import { Button, ButtonGroup } from '@neslotech/eventhub-ui-kit';

import './remove-template.scss';

const RemoveTemplate = ({ template, onConfirmClick, onCancelClick }) => (
  <section className="remove-template">
    <section className="remove-template__message">
      <p>
        Are you sure you want to remove <span>{template?.name}</span>?
      </p>
    </section>
    <ButtonGroup>
      <Button label="Confirm" onClick={onConfirmClick} />
      <Button label="Cancel" onClick={onCancelClick} secondary />
    </ButtonGroup>
  </section>
);

export default RemoveTemplate;
