import React from 'react';
import { useDispatch } from 'react-redux';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import { rejectEntry } from '../../../../actions/entry.actions';

import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';

import RemoveEntry from '../../../../components/entries/remove/RemoveEntry';

const RejectEntryContainer = ({ entry, show, setShowModal }) => {
  const dispatch = useDispatch();

  const { id } = useOrganisationContext();

  const onClose = () => setShowModal(false);

  const handleConfirm = (payload) => {
    dispatch(rejectEntry(id, entry.id, payload));
    onClose();
  };

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout large onClose={onClose} title="Reject Entry">
            <RemoveEntry entry={entry} onConfirmClick={handleConfirm} onCancelClick={onClose} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RejectEntryContainer;
