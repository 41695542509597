import React, { useState } from 'react';

import {
  Button,
  ButtonGroup,
  DRAFT_STATUS,
  ENTRY_FORM_TYPE,
  Input,
  PUBLISHED_STATUS
} from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';
import { noOp } from '@neslotech/utils';

import { WizardContext } from '../../../contexts/Wizard.context';
import FormBuilderProvider from '../../../providers/FormBuilder.provider';

import EntryFormTemplate from '../details/entry-form/EntryFormTemplate';

import './add-entry-form-template.scss';

const rules = {
  validates: {
    name: ['isPresent']
  }
};

const serverify = (form) => ({
  name: form.name,
  template_type: ENTRY_FORM_TYPE,
  data: form.sections
});

const AddEntryFormTemplate = ({ onAdd }) => {
  const [template] = useState([]);
  const [form, setForm] = useFormState(undefined, rules);

  const handleChange = (newState) => {
    if (!newState.hasOwnProperty('name')) {
      setForm({ ...form, sections: newState });
    } else {
      setForm({ ...form, ...newState });
    }
  };

  const handleSubmit = () => {
    const payload = serverify(form);
    payload.status = PUBLISHED_STATUS;
    onAdd(payload);
  };

  const handleDraftSubmit = () => {
    const payload = serverify(form);
    payload.status = DRAFT_STATUS;
    onAdd(payload);
  };

  return (
    <article className="add-entry-form-template">
      <header>
        <Input
          value={form?.name}
          placeholder="Enter your template name"
          name="name"
          onChange={handleChange}
          error={form?.messages?.name}
        />
      </header>
      <main className="add-entry-form-template__content">
        <WizardContext.Provider value={{ handleEntityChange: noOp }}>
          <FormBuilderProvider>
            <EntryFormTemplate template={template} editMode={true} onChange={handleChange} />
          </FormBuilderProvider>
        </WizardContext.Provider>
      </main>
      <footer className="add-entry-form-template__actions">
        <ButtonGroup fluid>
          <Button label="Save as Template" disabled={!form?.valid} onClick={handleSubmit} />
          <Button
            label="Save as Draft"
            disabled={!form?.valid}
            hollow
            onClick={handleDraftSubmit}
          />
        </ButtonGroup>
      </footer>
    </article>
  );
};

export default AddEntryFormTemplate;
