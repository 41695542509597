import React, { useMemo } from 'react';
import { shape, string } from 'prop-types';

import { Calendar, DetailsSection } from '@neslotech/eventhub-ui-kit';
import { DAYS, toNumericDateString } from '@neslotech/utils';

import './schedule-section.scss';

const ScheduleSection = ({ title, event, schedule }) => {
  const initialDate = useMemo(() => event.start_date ?? event.startDate, [event]);

  const scheduleEvents = useMemo(() => {
    const classScheduleHeats = (
      schedule?.competition_class_schedules ??
      schedule?.competitionClassSchedules ??
      []
    ).reduce((acc, classSchedule) => {
      const mappedHeats = (classSchedule.heats ?? []).map((heat) => ({
        id: heat.id,
        title: `${classSchedule.competition_class?.name ?? classSchedule.competitionClass?.name} ${
          heat.name
        }`,
        start: `${event?.start_date ?? event?.startDate}T${heat.start_time ?? heat.startTime}`,
        end: `${event?.start_date ?? event.startDate}T${heat.end_time ?? heat.endTime}`,
        color: classSchedule.color,
        borderColor: classSchedule.color
      }));

      return [...acc, ...mappedHeats];
    }, []);

    const timeslots = (schedule?.timeslots ?? []).map((timeslot) => ({
      id: timeslot.id,
      title: timeslot.name,
      start: `${event?.start_date ?? event?.startDate}T${
        timeslot.start_time ?? timeslot.startTime
      }`,
      end: `${event?.start_date ?? event.startDate}T${timeslot.end_time ?? timeslot.endTime}`,
      color: timeslot.color,
      borderColor: timeslot.color
    }));

    return [...classScheduleHeats, ...timeslots];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule]);

  return (
    <DetailsSection title={title}>
      <section className="schedule-section">
        <header>
          <h4>
            {event?.competition?.name}: {event?.name}
          </h4>
          <article className="schedule-section__dates">
            <section className="schedule-section__date">
              <label>{toNumericDateString(event?.start_date ?? event?.startDate)}</label>
            </section>
            <label className="schedule-section__day">
              {DAYS[new Date(event?.start_date ?? event?.startDate).getDay()]}
            </label>
          </article>
        </header>
        <Calendar
          events={scheduleEvents}
          initialDate={initialDate}
          initialView="timeGridDay"
          showDayHeaders={false}
          headerToolbar={{
            start: '',
            center: '',
            end: ''
          }}
          nowIndicator
        />
      </section>
    </DetailsSection>
  );
};

ScheduleSection.defaultProps = {
  title: ''
};

ScheduleSection.propTypes = {
  title: string,
  event: shape({}).isRequired,
  schedule: shape({}).isRequired
};

export default ScheduleSection;
