import axios from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import { noOp, SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import { addSystemNotice } from '../actions/system.actions';
import {
  CANCEL_COMPETITION,
  LOAD_COMPETITION,
  LOAD_COMPETITION_CLASSES,
  LOAD_COMPETITION_ENTRY_FORM,
  LOAD_COMPETITION_MEMBER_TYPES,
  LOAD_COMPETITION_POINTS_CONFIGURATION,
  LOAD_COMPETITIONS,
  loadCompetition,
  loadCompetitions,
  POSTPONE_COMPETITION,
  PUBLISH_COMPETITION,
  REINSTATE_COMPETITION,
  REMOVE_COMPETITION,
  SAVE_COMPETITION_DETAILS,
  SAVE_COMPETITION_DRAFT,
  SAVE_COMPETITION_ENTRY_FORM,
  SAVE_COMPETITION_POINTS_CONFIG,
  SET_COMPETITION,
  SET_COMPETITION_CLASSES,
  SET_COMPETITION_ENTRY_FORM,
  SET_COMPETITION_MEMBER_TYPES,
  SET_COMPETITION_POINTS_CONFIGURATION,
  SET_COMPETITIONS,
  UPDATE_COMPETITION_DETAILS
} from '../actions/competition.actions';
import {
  getCancelCompetitionRequest,
  getLoadCompetitionClassesRequest,
  getLoadCompetitionEntryFormRequest,
  getLoadCompetitionMemberTypesRequest,
  getLoadCompetitionPointsConfigurationRequest,
  getLoadCompetitionRequest,
  getLoadCompetitionsRequest,
  getPostponeCompetitionRequest,
  getPublishCompetitionRequest,
  getReinstateCompetitionRequest,
  getRemoveCompetitionRequest,
  getSaveCompetitionsDetailsRequest,
  getSaveCompetitionsDraftRequest,
  getSaveCompetitionsEntryFormRequest,
  getSaveCompetitionsPointsConfigRequest,
  getUpdateCompetitionsDetailsRequest
} from '../tools/api/competition.endpoints';

export function* performLoadCompetitions({ id, onSuccess }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadCompetitionsRequest(id);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_COMPETITIONS, competitions: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to load competitions.', SNACK_CRITICAL)
    );
  }
}

export function* watchForLoadCompetitionsRequest() {
  yield takeLatest(LOAD_COMPETITIONS, performLoadCompetitions);
}

export function* performLoadCompetition({ id, competitionId, onSuccess }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadCompetitionRequest(id, competitionId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_COMPETITION, competition: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to load competition.', SNACK_CRITICAL)
    );
  }
}

export function* watchForLoadCompetitionRequest() {
  yield takeLatest(LOAD_COMPETITION, performLoadCompetition);
}

export function* performCancelCompetition({ id, competitionId, payload }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getCancelCompetitionRequest(id, competitionId, payload);

    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('This competition has been cancelled.', SNACK_SUCCESS));

    yield put(loadCompetition(id, competitionId, noOp));
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.message ?? 'Failed to cancel competition.', SNACK_CRITICAL)
    );
  }
}

export function* watchForCancelCompetitionRequest() {
  yield takeLatest(CANCEL_COMPETITION, performCancelCompetition);
}

export function* performRemoveCompetition({ id, competitionId, navigate }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getRemoveCompetitionRequest(id, competitionId);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('This competition has been removed.', SNACK_SUCCESS));

    // reload organisations
    yield put(loadCompetitions());

    yield call(navigate, '/competitions');
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to remove the competition.', SNACK_CRITICAL)
    );
  }
}

export function* watchForRemoveCompetitionRequest() {
  yield takeLatest(REMOVE_COMPETITION, performRemoveCompetition);
}

export function* performReinstateCompetition({ id, competitionId }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getReinstateCompetitionRequest(id, competitionId);

    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('This competition has been reinstated.', SNACK_SUCCESS));

    yield put(loadCompetition(id, competitionId, noOp));
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.message ?? 'Failed to reinstate competition.', SNACK_CRITICAL)
    );
  }
}

export function* watchForReinstateCompetitionRequest() {
  yield takeLatest(REINSTATE_COMPETITION, performReinstateCompetition);
}

export function* performPostponeCompetition({ id, competitionId, payload }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getPostponeCompetitionRequest(id, competitionId, payload);

    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('This competition has been postponed.', SNACK_SUCCESS));

    yield put(loadCompetition(id, competitionId, noOp));
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.message ?? 'Failed to postpone competition.', SNACK_CRITICAL)
    );
  }
}

export function* watchForPostponeCompetitionRequest() {
  yield takeLatest(POSTPONE_COMPETITION, performPostponeCompetition);
}

export function* performLoadCompetitionClasses({ id, competitionId }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadCompetitionClassesRequest(id, competitionId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_COMPETITION_CLASSES, classes: data });
  } catch ({ response }) {
    yield put(
      addSystemNotice(
        response?.data?.message ?? 'Failed to load competition classes.',
        SNACK_CRITICAL
      )
    );
  }
}

export function* watchForLoadCompetitionClassesRequest() {
  yield takeLatest(LOAD_COMPETITION_CLASSES, performLoadCompetitionClasses);
}

export function* performLoadCompetitionMemberTypes({ id, competitionId }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadCompetitionMemberTypesRequest(id, competitionId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_COMPETITION_MEMBER_TYPES, memberTypes: data });
  } catch ({ response }) {
    yield put(
      addSystemNotice(
        response?.data?.message ?? 'Failed to load competition member types.',
        SNACK_CRITICAL
      )
    );
  }
}

export function* watchForLoadCompetitionMemberTypesRequest() {
  yield takeLatest(LOAD_COMPETITION_MEMBER_TYPES, performLoadCompetitionMemberTypes);
}

export function* performLoadCompetitionPointsConfiguration({ id, competitionId, onSuccess }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadCompetitionPointsConfigurationRequest(
      id,
      competitionId
    );

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_COMPETITION_POINTS_CONFIGURATION, pointsConfiguration: data });

    if (onSuccess && data) {
      yield call(onSuccess, data);
    }
  } catch ({ response }) {
    yield put(
      addSystemNotice(
        response?.data?.message ?? 'Failed to load competition points configuration.',
        SNACK_CRITICAL
      )
    );
  }
}

export function* watchForLoadCompetitionPointsConfigurationRequest() {
  yield takeLatest(
    LOAD_COMPETITION_POINTS_CONFIGURATION,
    performLoadCompetitionPointsConfiguration
  );
}

export function* performLoadCompetitionEntryForm({ id, competitionId, onSuccess }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadCompetitionEntryFormRequest(id, competitionId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_COMPETITION_ENTRY_FORM, entryForm: data.entry_form });

    if (onSuccess && data) {
      yield call(onSuccess, data.entry_form);
    }
  } catch ({ response }) {
    yield put(
      addSystemNotice(
        response?.data?.message ?? 'Failed to load competition entry form.',
        SNACK_CRITICAL
      )
    );
  }
}

export function* watchForLoadCompetitionEntryFormRequest() {
  yield takeLatest(LOAD_COMPETITION_ENTRY_FORM, performLoadCompetitionEntryForm);
}

export function* performSaveCompetitionDetails({ id, payload, onSuccess, onError }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getSaveCompetitionsDetailsRequest(id, payload);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_COMPETITION, competition: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    if (response.status === 422) {
      // provide the errors back to the caller
      yield call(onError, response.data?.errors);
    } else {
      yield put(addSystemNotice('Failed to save competition as draft.', SNACK_CRITICAL));
    }
  }
}

export function* watchForSaveCompetitionDetailsRequest() {
  yield takeLatest(SAVE_COMPETITION_DETAILS, performSaveCompetitionDetails);
}

export function* performUpdateCompetitionDetails({
  id,
  competitionId,
  payload,
  onSuccess,
  onError
}) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getUpdateCompetitionsDetailsRequest(
      id,
      competitionId,
      payload
    );

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_COMPETITION, competition: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    if (response.status === 422) {
      // provide the errors back to the caller
      yield call(onError, response.data?.errors);
    } else {
      yield put(addSystemNotice('Failed to update competition.', SNACK_CRITICAL));
    }
  }
}

export function* watchForUpdateCompetitionDetailsRequest() {
  yield takeLatest(UPDATE_COMPETITION_DETAILS, performUpdateCompetitionDetails);
}

export function* performSaveCompetitionPointsConfig({
  id,
  competitionId,
  payload,
  onSuccess,
  onError
}) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getSaveCompetitionsPointsConfigRequest(
      id,
      competitionId,
      payload
    );

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_COMPETITION, competition: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    if (response.status === 422) {
      // provide the errors back to the caller
      yield call(onError, response.data?.errors);
    } else {
      yield put(
        addSystemNotice('Failed to save competition points configuration.', SNACK_CRITICAL)
      );
    }
  }
}

export function* watchForSaveCompetitionPointsConfigRequest() {
  yield takeLatest(SAVE_COMPETITION_POINTS_CONFIG, performSaveCompetitionPointsConfig);
}

export function* performSaveCompetitionEntryForm({
  id,
  competitionId,
  payload,
  onSuccess,
  onError
}) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getSaveCompetitionsEntryFormRequest(
      id,
      competitionId,
      payload
    );

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_COMPETITION, competition: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    if (response.status === 422) {
      // provide the errors back to the caller
      yield call(onError, response.data?.errors);
    } else {
      yield put(addSystemNotice('Failed to save competition entry form.', SNACK_CRITICAL));
    }
  }
}

export function* watchForSaveCompetitionEntryFormRequest() {
  yield takeLatest(SAVE_COMPETITION_ENTRY_FORM, performSaveCompetitionEntryForm);
}

export function* performSaveCompetitionDraft({ id, competitionId, navigate, onError }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getSaveCompetitionsDraftRequest(id, competitionId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice(data.message, SNACK_SUCCESS));

    if (navigate) {
      yield call(navigate, `/competitions/${competitionId}`);
    }
  } catch ({ response }) {
    if (response.status === 422) {
      // provide the errors back to the caller
      yield call(onError, response.data?.errors);
    } else {
      yield put(
        addSystemNotice(
          response.data?.message ?? 'Failed to save competition as draft',
          SNACK_CRITICAL
        )
      );
    }
  }
}

export function* watchForSaveCompetitionDraftRequest() {
  yield takeLatest(SAVE_COMPETITION_DRAFT, performSaveCompetitionDraft);
}

export function* performPublishCompetition({ id, competitionId, navigate, onError, onDuplicate }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getPublishCompetitionRequest(id, competitionId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice(data.message, SNACK_SUCCESS));

    if (navigate) {
      yield call(navigate, `/competitions/${competitionId}`);
    }
  } catch ({ response }) {
    if (response.status === 422) {
      // provide the errors back to the caller
      yield call(onError, response.data?.errors);

      if (response.data.competition_id) {
        // provide the id of the duplicate, so we can navigate to it
        yield call(onDuplicate, response.data.competition_id);
      }
    } else {
      yield put(
        addSystemNotice(response.data?.message ?? 'Failed to publish competition', SNACK_CRITICAL)
      );
    }
  }
}

export function* watchForPublishCompetitionRequest() {
  yield takeLatest(PUBLISH_COMPETITION, performPublishCompetition);
}

export default function* competitionSaga() {
  yield all([
    watchForLoadCompetitionsRequest(),
    watchForLoadCompetitionRequest(),
    watchForCancelCompetitionRequest(),
    watchForRemoveCompetitionRequest(),
    watchForReinstateCompetitionRequest(),
    watchForPostponeCompetitionRequest(),
    watchForLoadCompetitionClassesRequest(),
    watchForLoadCompetitionMemberTypesRequest(),
    watchForLoadCompetitionPointsConfigurationRequest(),
    watchForLoadCompetitionEntryFormRequest(),
    watchForSaveCompetitionDetailsRequest(),
    watchForUpdateCompetitionDetailsRequest(),
    watchForSaveCompetitionPointsConfigRequest(),
    watchForSaveCompetitionEntryFormRequest(),
    watchForSaveCompetitionDraftRequest(),
    watchForPublishCompetitionRequest()
  ]);
}
