import React from 'react';

import {
  Datepicker,
  Form,
  FormRow,
  Input,
  RadioGroup,
  Timepicker
} from '@neslotech/eventhub-ui-kit';
import { convertTimeToDate, getTomorrowFromDate } from '@neslotech/utils';

import { ReactComponent as LocationIcon } from '../../../../icons/location-icon.svg';

import '../add-event.scss';

const EventDetails = ({ form, handleChange }) => {
  const handleStartDateChange = (newForm) => {
    if (newForm?.startDate > form?.endDate) {
      handleChange({ ...newForm, endDate: getTomorrowFromDate(newForm.startDate) });
    } else {
      handleChange(newForm);
    }
  };

  return (
    <section className="add-event-details">
      <Form>
        <FormRow fluidSpaced>
          <Input
            name="name"
            placeholder="Event Name"
            label="Event Name"
            value={form?.name}
            error={form?.messages?.name}
            onChange={handleChange}
          />
          <section className="add-event-details__times">
            <Timepicker
              name="startTime"
              label="Start Time"
              placeholder="Start Time"
              initialValue={convertTimeToDate(form?.startTime)}
              onChange={handleChange}
              error={form?.messages?.startTime}
            />
            <Timepicker
              name="endTime"
              label="End Time"
              placeholder="End Time"
              initialValue={convertTimeToDate(form?.endTime)}
              onChange={handleChange}
              error={form?.messages?.endTime}
            />
          </section>
        </FormRow>
        <FormRow fluidSpaced>
          <Datepicker
            name="startDate"
            placeholder="Start Date"
            label="Start Date"
            value={form?.startDate}
            error={form?.messages?.startDate}
            onChange={handleStartDateChange}
          />
          <Datepicker
            name="endDate"
            placeholder="End Date"
            label="End Date"
            value={form?.endDate}
            error={form?.messages?.endDate}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow fluidSpaced>
          <Input
            name="venue"
            label="Location"
            placeholder="Location"
            action={<LocationIcon />}
            value={form?.venue}
            onChange={handleChange}
          />
          <RadioGroup
            name="national"
            label="National or Regional Event"
            fluid
            value={form?.national}
            onChange={handleChange}
            items={[
              { label: 'National', value: true },
              { label: 'Regional', value: false }
            ]}
          />
        </FormRow>
        <FormRow fluidSpaced>
          <Datepicker
            name="entryStartDate"
            placeholder="Entry Start Date"
            label="Entry Start Date"
            value={form?.entryStartDate}
            error={form?.messages?.entryStartDate}
            onChange={handleStartDateChange}
          />
          <Datepicker
            name="entryEndDate"
            placeholder="Entry End Date"
            label="Entry End Date"
            value={form?.entryEndDate}
            error={form?.messages?.entryEndDate}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow fluidSpaced>
          <Input
            name="attendees"
            label="Amount of Attendees"
            placeholder="Amount of Attendees"
            value={form?.attendees}
            onChange={handleChange}
          />
          <Input
            name="price"
            label="Attendee Price"
            placeholder="Attendee Price"
            prefix="R"
            value={form?.price}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow fluidSpaced>
          <RadioGroup
            name="refundable"
            label="Refundable Event"
            fluid
            value={form?.refundable}
            onChange={handleChange}
            items={[
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]}
          />
          <RadioGroup
            name="visibleEntries"
            label="Do you want participants entries to be visible to other entrants?"
            fluid
            value={form?.visibleEntries}
            onChange={handleChange}
            items={[
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]}
          />
        </FormRow>
        <FormRow>
          <RadioGroup
            name="suppliedBankingDetails"
            label="Are you using your supplied banking details?"
            fluid
            value={form?.suppliedBankingDetails}
            error={form?.messages?.suppliedBankingDetails}
            onChange={handleChange}
            items={[
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]}
          />
        </FormRow>
      </Form>
    </section>
  );
};

export default EventDetails;
