import React from 'react';

import OrganisationBadge from '../badge/OrganisationBadge';
import { ReactComponent as CirclePlusIcon } from '../../../icons/circle-plus-icon.svg';
import { ReactComponent as ChevronRightIcon } from '../../../icons/chevron-right-icon.svg';

export const switchOrganisationItems = (organisations, handleSwitchOrganisation, navigate) => [
  ...(organisations ?? []).map((organisation) => ({
    text: (
      <span className="organisation-switch__organisation-item-name">
        {organisation.name}
        <ChevronRightIcon />
      </span>
    ),
    icon: <OrganisationBadge name={organisation.name} imgSrc={organisation.image} small />,
    onClick: () => handleSwitchOrganisation(organisation)
  })),
  {
    text: (
      <span className="organisation-switch__organisation-create-new">Create New Organisation</span>
    ),
    icon: (
      <span className="organisation-switch__organisation-create-new-icon">
        <CirclePlusIcon />
      </span>
    ),
    bordered: true,
    onClick: () => navigate('/organisations/add')
  }
];
