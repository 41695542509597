export const LOAD_ENTRIES = '[ENTRY_STORE] Load entries';
export const SET_ENTRIES = '[ENTRY_STORE] Set entries';
export const loadEntries = (id, onSuccess) => ({
  type: LOAD_ENTRIES,
  id,
  onSuccess
});

export const DOWNLOAD_ENTRIES = '[ENTRY_STORE] Download entries';
export const downloadEntries = (id, onSuccess) => ({
  type: DOWNLOAD_ENTRIES,
  id,
  onSuccess
});

export const LOAD_ENTRY = '[ENTRY_STORE] Load entry';
export const SET_ENTRY = '[ENTRY_STORE] Set entry';
export const loadEntry = (id, entryId, onSuccess) => ({
  type: LOAD_ENTRY,
  id,
  entryId,
  onSuccess
});

export const REJECT_ENTRY = '[ENTRY_STORE] Reject entry';
export const rejectEntry = (id, entryId, payload) => ({
  type: REJECT_ENTRY,
  id,
  entryId,
  payload
});

export const ACCEPT_ENTRY = '[ENTRY_STORE] Accept entry';
export const acceptEntry = (id, entryId) => ({
  type: ACCEPT_ENTRY,
  id,
  entryId
});
