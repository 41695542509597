import { ReactComponent as HomeIcon } from '../../../icons/home-icon.svg';
import { ReactComponent as EventIcon } from '../../../icons/events-icon.svg';
import { ReactComponent as ResultsIcon } from '../../../icons/results-icon.svg';
import { ReactComponent as CompetitionsIcon } from '../../../icons/competitions-icon.svg';
import { ReactComponent as EntriesIcon } from '../../../icons/entries-icon.svg';
import { ReactComponent as TemplatesIcon } from '../../../icons/template-icon.svg';

export const sidebarItems = [
  {
    title: 'Dashboard',
    link: '/dashboard',
    icon: <HomeIcon />
  },
  {
    title: 'Competitions',
    link: '/competitions',
    icon: <CompetitionsIcon />
  },
  {
    title: 'Events',
    link: '/events',
    icon: <EventIcon />
  },
  {
    title: 'Results',
    link: '/results',
    icon: <ResultsIcon />
  },
  {
    title: 'Entries',
    link: '/entries',
    icon: <EntriesIcon />
  },
  {
    title: 'Templates',
    link: '/templates',
    icon: <TemplatesIcon />
  }
];
