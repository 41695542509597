import { convertToLocaleTimeString, isUndefined } from '@neslotech/utils';

export const serverify = (form, bankingDetailsForm) => ({
  name: form.name,
  start_date: form.startDate,
  end_date: form.endDate,
  start_time: convertToLocaleTimeString(form.startTime),
  end_time: convertToLocaleTimeString(form.endTime),
  competition_id: form?.competition?.id,
  venue: form.venue,
  image: form?.image,
  entry_start_date: form.entryStartDate,
  entry_end_date: form.entryEndDate,
  attendee_count: form.attendees,
  price: form.price,
  refundable: form.refundable,
  description: form.description,
  national: form.national,
  visible_entries: form.visibleEntries,
  supplied_banking_details: form.bankingDetails,
  banking_detail_attributes: serverifyBankingDetails(form, bankingDetailsForm),
  class_allocations_attributes: serverifyClasses(form?.classes ?? []),
  member_type_allocations_attributes: servifyMembers(form?.members ?? []),
  additional_logos: form.additionalLogos.map((logo) => ({
    ...logo,
    name: logo.name.split('.')[0]
  })),
  supporting_documents: form.supportingDocuments.map((document) => ({
    ...document,
    name: document.name.split('.')[0]
  }))
});

const servifyMembers = (form) => {
  return form.map((item) => ({
    id: item.id,
    member_type_id: item.memberTypeId,
    _destroy: item.selected === false ? '1' : undefined
  }));
};

const serverifyClasses = (form) => {
  return form.map((item) => ({
    id: item.id,
    competition_class_id: item.competitionClassId,
    _destroy: item.selected === false ? '1' : undefined
  }));
};

const serverifyBankingDetails = (form, bankingDetailsForm) => {
  if (
    form.suppliedBankingDetails ||
    (isUndefined(form.suppliedBankingDetails) && isUndefined(bankingDetailsForm))
  ) {
    return undefined;
  }

  return {
    id: bankingDetailsForm?.id,
    account_type: bankingDetailsForm?.bankAccountType,
    account_number: bankingDetailsForm?.bankAccountNumber,
    bank_name: bankingDetailsForm?.bankName,
    branch_number: bankingDetailsForm?.branchNumber
  };
};
