import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, getProfileInitials, ProfileImage } from '@neslotech/eventhub-ui-kit';

import Badge from '../badge/Badge';

import './organisation-section.scss';

const OrganisersSection = ({ organisation }) => {
  const navigate = useNavigate();

  return (
    <section className="organisation-section">
      <Card>
        <section className="organisation-section__section">
          <header>
            <h4>Organisers</h4>
          </header>
          <section className="organisation-section__badges">
            {organisation?.organisers?.map((organiser) => (
              <Badge
                onImageClick={() => navigate(`/organisations/organisers/${organiser.id}`)}
                key={organiser.id}
                image={
                  <ProfileImage
                    fitted
                    small
                    imgSrc={organiser.image}
                    initials={getProfileInitials(organiser)}
                  />
                }
              />
            ))}
          </section>
        </section>
      </Card>
    </section>
  );
};

export default OrganisersSection;
