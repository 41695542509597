import axios from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL } from '@neslotech/utils';

import {
  addSystemNotice,
  LOAD_CLASS_TYPES,
  LOAD_SPORTS,
  SET_CLASS_TYPES,
  SET_SPORTS
} from '../actions/system.actions';

import { getLoadClassTypesRequest, getLoadSportsRequest } from '../tools/api/sport.endpoints';

export function* performLoadSports() {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadSportsRequest();

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_SPORTS, sports: data });
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error ?? 'Failed to load sports.', SNACK_CRITICAL));
  }
}

export function* watchForLoadSportsRequest() {
  yield takeLatest(LOAD_SPORTS, performLoadSports);
}

export function* performLoadClassTypes({ sportId }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadClassTypesRequest(sportId);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_CLASS_TYPES, classTypes: data });
  } catch ({ response }) {
    yield put(
      addSystemNotice(response.data.error ?? 'Failed to load class types.', SNACK_CRITICAL)
    );
  }
}

export function* watchForLoadClassTypesRequest() {
  yield takeLatest(LOAD_CLASS_TYPES, performLoadClassTypes);
}

export default function* systemSaga() {
  yield all([watchForLoadSportsRequest(), watchForLoadClassTypesRequest()]);
}
