import React from 'react';

import { TextArea } from '@neslotech/eventhub-ui-kit';

import '../add-competition.scss';

const CompetitionDescription = ({ form, handleChange }) => (
  <section className="add-competition-description">
    <TextArea
      name="description"
      placeholder="Please enter a description for your competition."
      value={form?.description}
      onChange={handleChange}
    />
  </section>
);

export default CompetitionDescription;
