import React from 'react';

import { Button, ButtonGroup, Form, FormRow, Input, RadioGroup } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import './datepicker-properties.scss';

const layoutOptions = [
  {
    label: 'Full Width',
    value: true
  },
  {
    label: 'Half Width',
    value: false
  }
];

const requiredOptions = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

const rules = {
  validates: {
    label: ['isPresent'],
    placeholder: ['isPresent']
  }
};

const formify = ({ id, type, placeholder, label, filled, required }) => ({
  id,
  type,
  placeholder,
  label,
  filled,
  required
});

const serverify = ({ id, type, placeholder, label, filled, required }) => ({
  id,
  type,
  placeholder,
  label,
  filled,
  required
});

const DatepickerProperties = ({ element, onSave, onCancel }) => {
  const [form, setForm] = useFormState(formify(element), rules);

  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleSave = () => {
    const payload = serverify(form);
    onSave(payload);
  };

  return (
    <article className="datepicker-properties">
      <Form>
        <FormRow>
          <Input
            name="label"
            placeholder="Label Text"
            label="Label Text"
            onChange={handleChange}
            value={form?.label}
            error={form?.messages?.label}
          />
        </FormRow>
        <FormRow>
          <Input
            name="placeholder"
            placeholder="Placeholder Text"
            label="Placeholder Text"
            onChange={handleChange}
            value={form?.placeholder}
            error={form?.messages?.placeholder}
          />
        </FormRow>
        <FormRow>
          <RadioGroup
            name="filled"
            items={layoutOptions}
            onChange={handleChange}
            label="Layout Options"
            value={form?.filled}
          />
        </FormRow>
        <FormRow>
          <RadioGroup
            name="required"
            items={requiredOptions}
            onChange={handleChange}
            label="Required field?"
            value={form?.required}
          />
        </FormRow>
      </Form>
      <ButtonGroup centered>
        <Button label="Save" disabled={!form?.valid} onClick={handleSave} />
        <Button secondary label="Cancel" onClick={onCancel} />
      </ButtonGroup>
    </article>
  );
};

export default DatepickerProperties;
