import React from 'react';

import { Form, FormAction, FormRow, Input } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import './organisation-details.scss';

const rules = {
  validates: {
    name: ['isPresent'],
    emailAddress: ['isPresent', 'isEmail'],
    contactNumber: ['isNumeric'],
    physicalAddress: ['isPresent']
  }
};

const serverify = ({ name, emailAddress, contactNumber, physicalAddress }) => ({
  name,
  email_address: emailAddress,
  phone_number: contactNumber,
  physical_address: physicalAddress
});

const OrganisationDetails = ({ onSave }) => {
  const [form, setForm] = useFormState(undefined, rules);
  const handleChange = (newState) => setForm({ ...form, ...newState });

  const onSubmit = () => {
    const payload = serverify(form);
    onSave(payload);
  };

  return (
    <section className="organisation-details">
      <Form>
        <FormRow>
          <Input
            name="name"
            label="Organisation Name"
            placeholder="Organisation Name"
            value={form?.name}
            error={form?.messages?.name}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <Input
            name="emailAddress"
            label="Email Address"
            placeholder="Email Address"
            value={form?.emailAddress}
            error={form?.messages?.emailAddress}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <Input
            name="contactNumber"
            label="Contact Number"
            placeholder="Contact Number"
            value={form?.contactNumber}
            error={form?.messages?.contactNumber}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <Input
            name="physicalAddress"
            label="Physical Address"
            placeholder="Physical Address"
            value={form?.physicalAddress}
            error={form?.messages?.physicalAddress}
            onChange={handleChange}
          />
        </FormRow>
        <FormAction label="Create Organisation" onClick={onSubmit} disabled={!form?.valid} />
      </Form>
    </section>
  );
};

export default OrganisationDetails;
