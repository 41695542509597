import axios from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { DRAFT_STATUS, ENTRY_FORM_TYPE, POINTS_CONFIG_TYPE } from '@neslotech/eventhub-ui-kit';
import { SNACK_CRITICAL } from '@neslotech/utils';

import {
  LOAD_ENTRY_FORM_TEMPLATES,
  LOAD_POINTS_CONFIG_TEMPLATES,
  LOAD_TEMPLATE,
  LOAD_TEMPLATES,
  loadEntryFormTemplates,
  loadPointsConfigTemplates,
  loadTemplate,
  REMOVE_TEMPLATE,
  SAVE_TEMPLATE,
  SET_ENTRY_FORM_TEMPLATES,
  SET_POINTS_CONFIG_TEMPLATES,
  SET_TEMPLATE,
  SET_TEMPLATES,
  UPDATE_TEMPLATE
} from '../actions/template.actions';
import { addSystemNotice } from '../actions/system.actions';

import {
  getLoadTemplateRequest,
  getLoadTemplatesRequest,
  getRemoveTemplateRequest,
  getSaveTemplateRequest,
  getUpdateTemplateRequest
} from '../tools/api/template.endpoints';

export function* performSaveTemplate({ id, payload }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getSaveTemplateRequest(id, payload);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    if (payload.status === DRAFT_STATUS) {
      yield put(addSystemNotice('Your template has been saved as a draft successfully.'));
    } else {
      yield put(addSystemNotice(`Template ${payload.name} has been saved successfully.`));
    }

    yield put(loadPointsConfigTemplates(id));
    yield put(loadEntryFormTemplates(id));
  } catch ({ response }) {
    if (response.status === 403) {
      yield put(addSystemNotice('You are forbidden to view this resource.', SNACK_CRITICAL));
    } else {
      yield put(
        addSystemNotice(
          response.data.error ?? payload.status === DRAFT_STATUS
            ? 'Failed to save template as draft.'
            : `Failed to save ${payload.name} as template.`,
          SNACK_CRITICAL
        )
      );
    }
  }
}

export function* watchForSaveTemplateRequest() {
  yield takeLatest(SAVE_TEMPLATE, performSaveTemplate);
}

export function* performLoadTemplates({ id, onComplete }) {
  try {
    const [endpoint, requestOptions] = getLoadTemplatesRequest(id);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_TEMPLATES, templates: data });
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error ?? 'Failed to load templates.', SNACK_CRITICAL));
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForLoadTemplates() {
  yield takeLatest(LOAD_TEMPLATES, performLoadTemplates);
}

export function* performLoadPointsConfigTemplates({ id }) {
  try {
    const [endpoint, requestOptions] = getLoadTemplatesRequest(id, POINTS_CONFIG_TYPE);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_POINTS_CONFIG_TEMPLATES, pointsConfigTemplates: data });
  } catch ({ response }) {
    yield put(
      addSystemNotice(
        response.data.error ?? 'Failed to load points config templates.',
        SNACK_CRITICAL
      )
    );
  }
}

export function* watchForLoadPointsConfigTemplates() {
  yield takeLatest(LOAD_POINTS_CONFIG_TEMPLATES, performLoadPointsConfigTemplates);
}

export function* performLoadEntryFormTemplates({ id }) {
  try {
    const [endpoint, requestOptions] = getLoadTemplatesRequest(id, ENTRY_FORM_TYPE);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_ENTRY_FORM_TEMPLATES, entryFormTemplates: data });
  } catch ({ response }) {
    yield put(
      addSystemNotice(response.data.error ?? 'Failed to load entry form templates.', SNACK_CRITICAL)
    );
  }
}

export function* watchForLoadEntryFormTemplates() {
  yield takeLatest(LOAD_ENTRY_FORM_TEMPLATES, performLoadEntryFormTemplates);
}

export function* performLoadTemplate({ id, templateId, onComplete }) {
  try {
    const [endpoint, requestOptions] = getLoadTemplateRequest(id, templateId);

    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_TEMPLATE, template: data });
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error ?? 'Failed to load template.', SNACK_CRITICAL));
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForLoadTemplate() {
  yield takeLatest(LOAD_TEMPLATE, performLoadTemplate);
}

export function* performUpdateTemplate({ id, templateId, payload }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getUpdateTemplateRequest(id, templateId, payload);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('Successfully updated template.'));

    yield put(loadPointsConfigTemplates(id));
    yield put(loadEntryFormTemplates(id));
    yield put(loadTemplate(id, templateId));
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error ?? 'Failed to update template.', SNACK_CRITICAL));
  }
}

export function* watchForUpdateTemplateRequest() {
  yield takeLatest(UPDATE_TEMPLATE, performUpdateTemplate);
}

export function* performRemoveTemplate({ id, templateId, navigate }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getRemoveTemplateRequest(id, templateId);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('Successfully removed template.'));

    if (navigate) {
      yield call(navigate, '/templates');
    }
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error ?? 'Failed to remove template.', SNACK_CRITICAL));
  }
}

export function* watchForRemoveTemplateRequest() {
  yield takeLatest(REMOVE_TEMPLATE, performRemoveTemplate);
}

export default function* templateSaga() {
  yield all([
    watchForSaveTemplateRequest(),
    watchForLoadPointsConfigTemplates(),
    watchForLoadEntryFormTemplates(),
    watchForUpdateTemplateRequest(),
    watchForLoadTemplates(),
    watchForLoadTemplate(),
    watchForRemoveTemplateRequest()
  ]);
}
