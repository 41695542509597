import React, { useEffect } from 'react';

import { DetailsSection, TextArea } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';
import { deepClone } from '@neslotech/utils';

import DynamicPointsConfigTemplate from '../../../dynamic-templates/DynamicPointsConfigTemplate';

import './points-config-template.scss';

const formify = (template) => ({
  places: template?.positions ? deepClone(template.positions) : [],
  pointsDescription: template?.description ?? ''
});

const PointsConfigTemplate = ({ editMode, template, onChange }) => {
  const [form, setForm] = useFormState(formify(template), { validates: {} });

  const handleChange = (newState) => {
    setForm({ ...form, ...newState });
    onChange({ ...form, ...newState });
  };

  useEffect(() => {
    setForm(formify(template));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  useEffect(() => {
    if (!editMode) {
      setForm(formify(template));
      onChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  return (
    <article className="points-config-template">
      <DynamicPointsConfigTemplate editable={editMode} form={form} handleChange={handleChange} />
      <section className="points-config-template__description">
        <DetailsSection title="" subtitle="Points configuration description">
          <TextArea
            disabled={!editMode}
            name="pointsDescription"
            placeholder="Type a small description about how the points configuration works for your participants."
            onChange={handleChange}
            value={form?.pointsDescription}
          />
        </DetailsSection>
      </section>
    </article>
  );
};

export default PointsConfigTemplate;
