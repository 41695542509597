import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@neslotech/eventhub-ui-kit';

import { ReactComponent as SuccessIcon } from '../../../images/success-icon.svg';

import './success.scss';

const Success = () => (
  <section className="success">
    <SuccessIcon />
    <h3>Good news!</h3>
    <p>
      Your account has been created successfully. Please check your emails to verify your account
      and login.
    </p>
    <p>
      If you have not recieved a verification email, click the button below to resend a new one.
    </p>
    <Link to="/">
      <Button label="Back to Login" />
    </Link>
  </section>
);

export default Success;
