import React, { useState } from 'react';
import { useFormState } from '@neslotech/hooks';

import {
  Button,
  ContextCard,
  ContextInfoList,
  DetailsSection,
  Form,
  FormRow,
  Input,
  toBase64
} from '@neslotech/eventhub-ui-kit';
import { getClassNames } from '@neslotech/utils';

import { ReactComponent as DefaultImage } from '../../../images/details-default-image.svg';
import { ReactComponent as EditIcon } from '../../../icons/blue-edit-icon.svg';

import OrganisersSection from '../section/OrganisationSection';

import './organisation-details.scss';

const rules = {
  validates: {
    name: ['isPresent'],
    emailAddress: ['isPresent', 'isEmail'],
    contactNumber: ['isNumeric', 'isPresent'],
    physicalAddress: ['isPresent'],
    suburb: ['isPresent'],
    postalCode: ['isPresent']
  }
};

const serverify = ({
  name,
  emailAddress,
  contactNumber,
  physicalAddress,
  suburb,
  postalCode,
  image
}) => ({
  name,
  email_address: emailAddress,
  phone_number: contactNumber,
  physical_address: physicalAddress,
  image,
  suburb,
  postal_code: postalCode
});

const formify = (organisation) => ({
  name: organisation?.name,
  emailAddress: organisation?.email_address,
  contactNumber: organisation?.phone_number,
  image: organisation?.image,
  physicalAddress: organisation?.physical_address,
  suburb: organisation?.suburb,
  postalCode: organisation?.postal_code
});

const OrganisationForm = ({ handleChange, form, editMode }) => (
  <section className="organisation-form">
    <Form>
      <FormRow fluidSpaced>
        <Input
          name="name"
          label="Organisation Name"
          placeholder="Organisation Name"
          value={form?.name}
          error={form?.messages?.name}
          onChange={handleChange}
          disabled={!editMode}
        />
        <Input
          name="emailAddress"
          label="Email Address"
          placeholder="Email Address"
          value={form?.emailAddress}
          error={form?.messages?.emailAddress}
          onChange={handleChange}
          disabled={!editMode}
        />
      </FormRow>
      <FormRow fluidSpaced>
        <section className="organisation-details__address-wrapper">
          <Input
            name="physicalAddress"
            label="Physical Address"
            placeholder="Physical Address"
            value={form?.physicalAddress}
            error={form?.messages?.physicalAddress}
            onChange={handleChange}
            disabled={!editMode}
          />
          <Input
            name="suburb"
            placeholder="Suburb"
            value={form?.suburb}
            error={form?.messages?.suburb}
            onChange={handleChange}
            disabled={!editMode}
          />
          <Input
            name="postalCode"
            placeholder="Postal Code"
            value={form?.postalCode}
            error={form?.messages?.postalCode}
            onChange={handleChange}
            disabled={!editMode}
          />
        </section>
        <Input
          name="contactNumber"
          label="Contact Number"
          placeholder="Contact Number"
          value={form?.contactNumber}
          error={form?.messages?.contactNumber}
          onChange={handleChange}
          disabled={!editMode}
        />
      </FormRow>
    </Form>
  </section>
);

const OrganisationDetails = ({ organisation, onSave, setShowModal }) => {
  const [form, setForm] = useFormState(formify(organisation), rules);
  const [img, setImg] = useState(organisation?.image);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleFileChange = async (file) => {
    const base64 = await toBase64(file);
    setForm({ ...form, image: base64 });
    setImg(base64);
  };

  const onSubmit = () => {
    setLoading(true);
    const payload = serverify(form);
    onSave(payload, () => {
      setEditMode(false);
      setLoading(false);
    });
  };

  const handleDiscard = () => {
    setForm(undefined);
    setImg(undefined);
  };

  return (
    <section className="organisation-details">
      <section className="organisation-details__content-wrapper">
        <section className="organisation-details__card-space">
          <ContextCard
            editMode={editMode}
            image={img ? <img src={img} alt="organisation-logo" /> : <DefaultImage />}
            onFileChange={handleFileChange}
          >
            <ContextInfoList term="Created By" value={organisation.created_by} />
            <ContextInfoList
              term="Date Created"
              value={new Date(organisation.created_at).toLocaleDateString()}
            />
          </ContextCard>
          <OrganisersSection organisation={organisation} />
        </section>
        <section
          className={getClassNames('organisation-details__form-wrapper', { grey: editMode })}
        >
          <DetailsSection
            title="Organisation Details"
            icon={<EditIcon onClick={() => setEditMode(true)} />}
          >
            <OrganisationForm editMode={editMode} handleChange={handleChange} form={form} />
          </DetailsSection>
          <footer className="organisation-details__actions">
            {editMode && (
              <>
                <Button
                  label="Save"
                  disabled={!form?.valid || !editMode}
                  onClick={onSubmit}
                  loading={loading}
                />
                <Button
                  label="Clear Page"
                  secondaryDanger
                  disabled={!editMode}
                  onClick={handleDiscard}
                />
              </>
            )}
            {!editMode && <Button label="Delete" danger onClick={() => setShowModal(true)} />}
          </footer>
        </section>
      </section>
    </section>
  );
};

export default OrganisationDetails;
