import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import { removeOrganisation } from '../../../../actions/organisation.actions';

import DeleteOrganisation from '../../../../components/organisation/delete/DeleteOrganisation';

const DeleteOrganisationContainer = ({ showModal, setShowModal, organisationId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClose = () => setShowModal(false);

  const organisations = useSelector(({ profile_store }) => profile_store.profile?.organisations);

  const handleDelete = () => {
    dispatch(removeOrganisation(organisationId, handleOrganisationChange));
    onClose();
  };

  const handleOrganisationChange = () => {
    if (organisations) {
      navigate('/organisation-selection');
    } else {
      navigate('/organisations/add');
    }
  };

  return (
    <>
      {showModal && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Organisation">
            <DeleteOrganisation
              onCancelClick={onClose}
              onConfirmClick={handleDelete}
              onOrganisationChange={handleOrganisationChange}
            />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default DeleteOrganisationContainer;
