import React from 'react';

import { Button, ButtonGroup, Form, FormRow, Input, RadioGroup } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import './section-properties.scss';

const layoutOptions = [
  {
    label: 'Left to Right',
    value: true
  },
  {
    label: 'Top to Bottom',
    value: false
  }
];

const rules = {
  validates: {
    title: ['isPresent']
  }
};

const formify = ({ id, type, title, fluid }) => ({
  id,
  type,
  title,
  fluid: fluid ?? false
});

const serverify = ({ id, type, title, fluid }) => ({
  id,
  type,
  title,
  fluid
});

const SectionProperties = ({ element, onSave, onCancel }) => {
  const [form, setForm] = useFormState(formify(element), rules);

  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleSave = () => {
    const payload = serverify(form);
    onSave(payload);
  };

  return (
    <article className="section-properties">
      <Form>
        <FormRow>
          <Input
            name="title"
            placeholder="Section Heading"
            label="Section Heading"
            onChange={handleChange}
            value={form?.title}
            error={form?.messages?.title}
          />
        </FormRow>
        <FormRow>
          <RadioGroup
            name="fluid"
            items={layoutOptions}
            onChange={handleChange}
            label="Layout Options"
            value={form?.fluid}
          />
        </FormRow>
      </Form>
      <ButtonGroup centered>
        <Button label="Save" disabled={!form?.valid} onClick={handleSave} />
        <Button secondary label="Cancel" onClick={onCancel} />
      </ButtonGroup>
    </article>
  );
};

export default SectionProperties;
