import { ENTRY_FORM_TYPE } from '@neslotech/eventhub-ui-kit';

export const formify = (entity) => ({
  required: entity?.required,
  entryForm: entity?.entryForm
});

export const serverify = (form) => ({
  entry_form: form.entryForm
});

export const serverifyTemplate = (form, status) => ({
  id: form.templateId,
  name: form.template,
  template_type: ENTRY_FORM_TYPE,
  status,
  data: form.entryForm
});
