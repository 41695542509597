import React from 'react';

import { Button, ButtonGroup, TextArea } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import './cancel-competition.scss';

const rules = {
  validates: {
    reason: ['isPresent']
  }
};

const serverify = ({ reason }) => ({
  reason
});

const CancelCompetition = ({ competition, onConfirmClick, onCancelClick }) => {
  const [form, setForm] = useFormState(undefined, rules);

  const handleChange = (newForm) => setForm({ ...form, ...newForm });

  const handleConfirm = () => {
    const payload = serverify(form);
    onConfirmClick(payload);
  };

  return (
    <section className="cancel-competition">
      <section className="cancel-competition__message">
        <p>
          Are you sure you want to cancel <span>{competition.name}</span>?
        </p>
        <p>
          Please note an emailer will be sent out to all registered participants informing them of
          the reason for the competition being cancelled.
        </p>
      </section>
      <section className="cancel-competition__form">
        <TextArea
          shadowed
          name="reason"
          rows={2}
          placeholder="Please provide a reason for cancelling this competition."
          onChange={handleChange}
          value={form?.reason}
        />
      </section>
      <ButtonGroup>
        <Button label="Confirm" disabled={!form?.valid} onClick={handleConfirm} />
        <Button label="Cancel" onClick={onCancelClick} secondary />
      </ButtonGroup>
    </section>
  );
};

export default CancelCompetition;
