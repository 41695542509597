import React from 'react';

import { Button, ButtonGroup, TextArea } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import './postpone-event.scss';

const rules = {
  validates: {
    reason: ['isPresent']
  }
};

const serverify = ({ reason }) => ({
  reason
});

const PostponeReason = ({ event, onConfirmClick, onCancelClick }) => {
  const [form, setForm] = useFormState(undefined, rules);

  const handleChange = (newForm) => setForm({ ...form, ...newForm });

  const handleConfirm = () => {
    const payload = serverify(form);
    onConfirmClick(payload);
  };

  return (
    <section className="postpone-event">
      <section className="postpone-event__message">
        <p>
          Are you sure you want to postpone <span>{event.name}</span>?
        </p>
        <p>
          Postponing this event will carry the event information over to the chosen date. Please
          note an emailer will be sent out to all registered participants informing them of the
          reason for the event being postponed.
        </p>
      </section>
      <section className="postpone-event__form">
        <TextArea
          shadowed
          name="reason"
          rows={5}
          placeholder="Please provide a reason for postponing this event."
          onChange={handleChange}
          value={form?.reason}
        />
      </section>
      <ButtonGroup>
        <Button label="Confirm" disabled={!form?.valid} onClick={handleConfirm} />
        <Button label="Cancel" onClick={onCancelClick} secondary />
      </ButtonGroup>
    </section>
  );
};

export default PostponeReason;
