import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { noOp } from '@neslotech/utils';

import { getProfileInitials, ProfileImage, Tooltip } from '@neslotech/eventhub-ui-kit';

import { ReactComponent as PlusIcon } from '../../icons/circle-plus-icon.svg';
import { ReactComponent as EntriesIcon } from '../../icons/dashboard-entries.svg';
import { ReactComponent as RequestsIcon } from '../../icons/dashboard-requests.svg';
import { ReactComponent as CancelledIcon } from '../../icons/dashboard-cancelled.svg';
import { ReactComponent as TemplatesIcon } from '../../icons/dashboard-templates.svg';

import Badge from '../organisation/badge/Badge';
import DashboardCard from '../card/DashboardCard';
import UnverifiedMessage from '../unverified/UnverifiedMessage';

import './dashboard.scss';

const Dashboard = ({ organisation, onAddOrganiserClick, verified }) => {
  const navigate = useNavigate();

  const renderOrganiserBadges = useMemo(() => {
    const hasMultipleOrganisers = (organisation?.organisers ?? []).length > 3;

    return (
      <section className="dashboard__badges">
        {(organisation?.organisers ?? []).map((organiser, index) => {
          if (index > 2) {
            return null;
          }

          return (
            <Badge
              small
              onImageClick={
                verified ? () => navigate(`/organisations/organisers/${organiser?.id}`) : noOp
              }
              key={organiser?.id}
              image={
                <ProfileImage
                  fitted
                  small
                  imgSrc={organiser?.image}
                  initials={getProfileInitials(organiser)}
                />
              }
            />
          );
        })}
        {hasMultipleOrganisers && (
          <section className="dashboard__extras">
            <p>{organisation.organisers.length - 3}</p>
          </section>
        )}
        {verified && (
          <Tooltip parentId="add-organiser" content="Add New Organiser">
            <span id="add-organiser">
              <PlusIcon onClick={onAddOrganiserClick} />
            </span>
          </Tooltip>
        )}
      </section>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation?.organisers]);

  return (
    <article className="dashboard">
      <section className="dashboard__actions">
        <header className="dashboard__header">
          <h4 className="dashboard__title">{organisation?.name}</h4>
          {renderOrganiserBadges}
        </header>
        <main className="dashboard__cards">
          <DashboardCard
            disabled={!verified}
            path="/entries"
            title="View All Entries"
            icon={<EntriesIcon />}
          />
          <DashboardCard
            disabled={!verified}
            path="/requests"
            title="Entry Requests"
            icon={<RequestsIcon />}
          />
          <DashboardCard
            disabled={!verified}
            path="/cancelled"
            title="Cancelled Entries"
            icon={<CancelledIcon />}
          />
          <DashboardCard
            disabled={!verified}
            path="/templates"
            title="Manage Templates"
            icon={<TemplatesIcon />}
          />
        </main>
      </section>
      <section className="dashboard__upcoming">{!verified && <UnverifiedMessage />}</section>
    </article>
  );
};

export default Dashboard;
