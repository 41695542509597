import React from 'react';

import { noOp } from '@neslotech/utils';

import { ReactComponent as LogoutIcon } from '../../../icons/logout-icon.svg';
import { ReactComponent as ProfileIcon } from '../../../icons/profile-icon.svg';
import { ReactComponent as CreditCardIcon } from '../../../icons/credit-card-icon.svg';
import { ReactComponent as OrganisationDetailsIcon } from '../../../icons/organisation-details-icon.svg';

export const dropdownItems = (navigate, auth) => [
  {
    text: 'My Profile',
    icon: <ProfileIcon />,
    onClick: () => navigate('/profile')
  },
  {
    text: 'Organisation Details',
    icon: <OrganisationDetailsIcon />,
    onClick: () => navigate('/organisations/details')
  },
  {
    text: 'Bank Details',
    icon: <CreditCardIcon />,
    onClick: () => navigate('/organisations/banking-details')
  },
  {
    text: 'Log Out',
    icon: <LogoutIcon />,
    onClick: auth?.onLogout ?? noOp
  },
  {
    text: 'About the app',
    bordered: true,
    onClick: noOp
  }
];
