import React from 'react';

import { Form, FormAction, FormRow, PasswordInput } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import './reset-password.scss';

const rules = {
  validates: {
    password: ['isPresent'],
    passwordConfirm: ['isPresent', 'arePasswordsEqual']
  },

  arePasswordsEqual(value, form) {
    if (value !== form?.password) {
      return 'Passwords do not match';
    }
  }
};

const serverify = ({ password, passwordConfirm }) => ({
  password,
  password_confirm: passwordConfirm
});

const ResetPassword = ({ onResetPassword }) => {
  const [form, setForm] = useFormState(undefined, rules);
  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleSubmit = () => {
    const payload = serverify(form);
    onResetPassword(payload);
  };

  return (
    <section className="reset-password">
      <Form>
        <FormRow>
          <PasswordInput
            name="password"
            label="New Password"
            placeholder="Enter your new password"
            onChange={handleChange}
            value={form?.password}
            error={form?.messages?.password}
          />
        </FormRow>
        <FormRow>
          <PasswordInput
            name="passwordConfirm"
            label="Confirm Your Password"
            placeholder="Confirm your password"
            onChange={handleChange}
            value={form?.passwordConfirm}
            error={form?.messages?.passwordConfirm}
          />
        </FormRow>
        <FormAction label="Confirm" disabled={!form?.valid} onClick={handleSubmit} />
      </Form>
    </section>
  );
};

export default ResetPassword;
