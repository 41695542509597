import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ENTRY_FORM_TYPE, Loader, PageLayout } from '@neslotech/eventhub-ui-kit';
import { noOp } from '@neslotech/utils';

import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';
import { WizardContext } from '../../../../contexts/Wizard.context';

import { loadTemplate, updateTemplate } from '../../../../actions/template.actions';

import OrganizerLayout from '../../../../components/layout/organizer/OrganizerLayout';
import TemplateDetails from '../../../../components/templates/details/TemplateDetails';
import RemoveTemplateContainer from '../remove/RemoveTemplate.container';

const serverify = (data, type) =>
  type === ENTRY_FORM_TYPE
    ? data
    : {
        positions: data?.places ?? [],
        description: data?.pointsDescription
      };

const TemplateContainer = () => {
  const dispatch = useDispatch();
  const { id: templateId } = useParams();

  const { id } = useOrganisationContext();

  const template = useSelector(({ template_store }) => template_store.template);
  const stateLoading = useSelector(({ template_store }) => template_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(loadTemplate(id, templateId, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, templateId]);

  const pageTitle = useMemo(() => {
    return template?.template_type === ENTRY_FORM_TYPE
      ? 'Entry Form Template Details'
      : 'Point Configuration Template Details';
  }, [template?.template_type]);

  const handleUpdate = (updatedData) => {
    const payload = {
      ...template,
      data: serverify(updatedData, template.template_type)
    };

    dispatch(updateTemplate(id, templateId, payload));
  };

  return (
    <>
      <OrganizerLayout gutterless>
        <PageLayout title={pageTitle} back fixed>
          {loading && <Loader filled />}
          {!loading && (
            <WizardContext.Provider value={{ handleEntityChange: noOp }}>
              <TemplateDetails
                template={template}
                onUpdate={handleUpdate}
                onRemove={() => setShowDeleteModal(true)}
              />
            </WizardContext.Provider>
          )}
        </PageLayout>
      </OrganizerLayout>
      <RemoveTemplateContainer
        template={template}
        setShowModal={setShowDeleteModal}
        show={showDeleteModal}
      />
    </>
  );
};

export default TemplateContainer;
