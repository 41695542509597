import { SNACK_SUCCESS } from '@neslotech/utils';

export const ADD_SYSTEM_NOTICE = '[SYSTEM_STORE] Add system notice';
export const addSystemNotice = (title, alertType = SNACK_SUCCESS) => ({
  type: ADD_SYSTEM_NOTICE,
  title,
  alertType
});

export const REMOVE_SYSTEM_NOTICE = '[SYSTEM_STORE] Remove system notice';
export const removeSystemNotice = (id) => ({
  type: REMOVE_SYSTEM_NOTICE,
  id
});

export const LOAD_SPORTS = '[SYSTEM_STORE] Load sports';
export const SET_SPORTS = '[SYSTEM_STORE] Set sports';
export const loadSports = () => ({
  type: LOAD_SPORTS
});

export const LOAD_CLASS_TYPES = '[SYSTEM_STORE] Load class types';
export const SET_CLASS_TYPES = '[SYSTEM_STORE] Set class types';
export const loadClassTypes = (sportId) => ({
  type: LOAD_CLASS_TYPES,
  sportId
});
