import { deepClone, isEmpty, isValuePresent } from '@neslotech/utils';
import { YES } from '@neslotech/eventhub-ui-kit';

const DEFAULT_TYPES = [
  {
    name: '',
    price: ''
  }
];

export const formifyDetails = (data) => ({
  name: data?.name,
  competitionType: data?.competition_type,
  startDate: data?.start_date ? new Date(data?.start_date) : '',
  endDate: data?.end_date ? new Date(data?.end_date) : '',
  entryStartDate: data?.start_date ? new Date(data?.entry_start_date) : '',
  entryEndDate: data?.end_date ? new Date(data?.entry_end_date) : '',
  sport: data?.sport,
  sportId: data?.sport_id,
  suppliedBankingDetails: data?.supplied_banking_details,
  national: data?.national,
  visibleEntries: data?.visible_entries,
  paid: data?.paid,
  regulated: data?.regulated,
  description: data?.description,
  registrationFee: (data?.registration_fee ?? '').replace('R', ''),
  regulationNumber: data?.regulation_number,
  bankingDetails: formifyBankingDetails(data?.banking_detail),
  classes: formifyClasses(data?.classes ?? deepClone(DEFAULT_TYPES)),
  members: formifyMembers(data?.members ?? deepClone(DEFAULT_TYPES))
});

const formifyClasses = (data) => {
  return data.map((item) => ({
    id: item.id,
    name: item.name,
    classTypeId: item.class_type_id,
    firstClassPrice: (item.first_class_price ?? '0').replace('R', ''),
    secondClassPrice: (item.second_class_price ?? '0').replace('R', ''),
    firstClassFamilyPrice: (item.first_class_family_price ?? '0').replace('R', ''),
    secondClassFamilyPrice: (item.second_class_family_price ?? '0').replace('R', '')
  }));
};

const formifyMembers = (data) => {
  return data.map((item) => ({
    id: item.id,
    name: item.name,
    price: item.price.replace('R', ''),
    limit: item.limit
  }));
};

const formifyBankingDetails = (data) => {
  if (!data) {
    return undefined;
  }

  return {
    id: data.id,
    bankAccountType: data.account_type,
    bankAccountNumber: data.account_number,
    bankName: data.bank_name,
    branchNumber: data.branch_number
  };
};

export const formifyPointsConfig = (data) => ({
  id: data?.id,
  required: !isEmpty(data?.positions) ? YES : undefined,
  description: data?.description,
  places: data?.positions
});

export const formifyEntryForm = (data) => ({
  required: isValuePresent(data?.entry_form) ? YES : undefined,
  entryForm: data?.entry_form
});
