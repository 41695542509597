import axios from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { CREATE, REGISTER } from '@neslotech/eventhub-ui-kit';
import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import { addSystemNotice } from '../actions/system.actions';
import {
  CREATE_ORGANISATION,
  LOAD_BANKING_DETAILS,
  EDIT_ORGANISATION,
  LOAD_ORGANISATION,
  SET_BANKING_DETAILS,
  REMOVE_ORGANISATION,
  SET_ORGANISATION,
  EDIT_BANKING_DETAILS,
  LOAD_ORGANISER,
  SET_ORGANISER,
  INVITE_ORGANISERS,
  REMOVE_ORGANISER
} from '../actions/organisation.actions';

import {
  getCreateOrganisationsRequest,
  getLoadOrganisationRequest,
  getLoadOrganiserRequest,
  getRemoveOrganiserRequest,
  getBankingDetailsRequest,
  getEditBankingDetailsRequest,
  getEditOrganisationRequest,
  getRemoveOrganisationRequest,
  getInviteOrganisersRequest
} from '../tools/api/organisation.endpoints';
import { retrieveProfile } from '../actions/profile.action';

export function* performCreateOrganisation({ payload, navigate, context, onSuccess }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getCreateOrganisationsRequest(payload);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    // navigate to success page
    if (context === REGISTER) {
      yield call(navigate, `/register/${payload.user_id}/success`);
    } else if (context === CREATE) {
      yield put(addSystemNotice('Organisation created successfully.', SNACK_SUCCESS));
    }
    if (onSuccess) {
      yield call(onSuccess, data.id);
    }
  } catch ({ response }) {
    yield put(
      addSystemNotice(
        response?.data?.error ?? 'Failed to save organisation details.',
        SNACK_CRITICAL
      )
    );
  }
}

export function* watchForCreateOrganisationRequest() {
  yield takeLatest(CREATE_ORGANISATION, performCreateOrganisation);
}

export function* performLoadOrganisation({ id, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadOrganisationRequest(id);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    // set organisations on the state
    yield put({ type: SET_ORGANISATION, organisation: data });
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to load organisation.', SNACK_CRITICAL)
    );
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForLoadOrganisationRequest() {
  yield takeLatest(LOAD_ORGANISATION, performLoadOrganisation);
}

export function* performLoadOrganiser({ id, organiserId, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadOrganiserRequest(id, organiserId);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    // set organiser on the state
    yield put({ type: SET_ORGANISER, organiser: data });
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to load organiser.', SNACK_CRITICAL)
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadOrganiserRequest() {
  yield takeLatest(LOAD_ORGANISER, performLoadOrganiser);
}

export function* performRemoveOrganiser({ id, organiserId, onSuccess }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getRemoveOrganiserRequest(id, organiserId);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('The organiser has been removed.', SNACK_SUCCESS));
    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.message ?? 'Failed to remove the organiser.', SNACK_CRITICAL)
    );
  }
}

export function* watchForRemoveOrganiserRequest() {
  yield takeLatest(REMOVE_ORGANISER, performRemoveOrganiser);
}

export function* performLoadBankingDetails({ id, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getBankingDetailsRequest(id);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    // set banking details on the state
    yield put({ type: SET_BANKING_DETAILS, bankingDetails: data });
    if (onComplete) {
      yield call(onComplete);
    }
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to load banking details.', SNACK_CRITICAL)
    );
  }
}

export function* watchForLoadBankingDetailsRequest() {
  yield takeLatest(LOAD_BANKING_DETAILS, performLoadBankingDetails);
}

export function* performEditBankingDetails({ id, bankingDetailsId, payload, onSuccess }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getEditBankingDetailsRequest(id, bankingDetailsId, payload);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    // set banking details on the state
    yield put({ type: SET_BANKING_DETAILS, bankingDetails: data });
    if (onSuccess) {
      yield call(onSuccess);
    }
    yield put(addSystemNotice('Your banking details has been updated.', SNACK_SUCCESS));
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to edit banking details.', SNACK_CRITICAL)
    );
  }
}

export function* watchForEditBankingDetailsRequest() {
  yield takeLatest(EDIT_BANKING_DETAILS, performEditBankingDetails);
}

export function* performEditOrganisation({ id, payload, onSuccess }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getEditOrganisationRequest(id, payload);

    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('Your organisation has been updated.', SNACK_SUCCESS));

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.message ?? 'Failed to update organisation.', SNACK_CRITICAL)
    );
  }
}

export function* watchForEditOrganisationRequest() {
  yield takeLatest(EDIT_ORGANISATION, performEditOrganisation);
}

export function* performRemoveOrganisation({ id, onSuccess }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getRemoveOrganisationRequest(id);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('The organisation has been removed.', SNACK_SUCCESS));

    yield put(retrieveProfile(onSuccess));
  } catch ({ response }) {
    yield put(
      addSystemNotice(
        response?.data?.message ?? 'Failed to remove the organisation.',
        SNACK_CRITICAL
      )
    );
  }
}

export function* watchForRemoveOrganisationRequest() {
  yield takeLatest(REMOVE_ORGANISATION, performRemoveOrganisation);
}

export function* performInviteOrganisers({ id, emails, onSuccess }) {
  try {
    for (let email of emails) {
      // get endpoint and http request options
      const [endpoint, requestOptions] = getInviteOrganisersRequest(id, email);

      // make the request, no need to check the response
      yield call(axios, endpoint, requestOptions);
    }

    yield put(addSystemNotice('Successfully invited organiser users.', SNACK_SUCCESS));

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to invite new organisers.', SNACK_CRITICAL)
    );
  }
}

export function* watchForInviteOrganisersRequest() {
  yield takeLatest(INVITE_ORGANISERS, performInviteOrganisers);
}

export default function* organisationSaga() {
  yield all([
    watchForCreateOrganisationRequest(),
    watchForLoadOrganisationRequest(),
    watchForEditOrganisationRequest(),
    watchForRemoveOrganisationRequest(),
    watchForLoadBankingDetailsRequest(),
    watchForEditBankingDetailsRequest(),
    watchForLoadOrganiserRequest(),
    watchForInviteOrganisersRequest(),
    watchForRemoveOrganiserRequest()
  ]);
}
