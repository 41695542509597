import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Loader, PageLayout } from '@neslotech/eventhub-ui-kit';

import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';
import { loadOrganiser } from '../../../../actions/organisation.actions';

import OrganizerLayout from '../../../../components/layout/organizer/OrganizerLayout';
import OrganiserDetails from '../../../../components/organiser/details/OrganiserDetails';
import RemoveOrganiserContainer from '../remove/RemoveOrganiser.container';

const OrganiserDetailsContainer = () => {
  const dispatch = useDispatch();
  const { id: organiserId } = useParams();

  const { id } = useOrganisationContext();
  const organiser = useSelector(({ organisation_store }) => organisation_store.organiser);
  const stateLoading = useSelector(({ organisation_store }) => organisation_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(loadOrganiser(id, organiserId, () => setLoading(false)));
  }, [dispatch, id, organiserId]);

  return (
    <>
      <OrganizerLayout gutterless>
        <PageLayout title="Organiser Details" back fixed>
          {loading && <Loader filled />}
          {!loading && <OrganiserDetails organiser={organiser} setShowModal={setShowModal} />}
        </PageLayout>
      </OrganizerLayout>
      <RemoveOrganiserContainer
        organiserId={organiser?.id}
        id={id}
        show={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default OrganiserDetailsContainer;
