export const LOAD_EVENTS = '[EVENT_STORE] Load events';
export const SET_EVENTS = '[EVENT_STORE] Set events';
export const loadEvents = (id, onSuccess) => ({
  type: LOAD_EVENTS,
  id,
  onSuccess
});

export const LOAD_EVENT = '[EVENT_STORE] Load event';
export const SET_EVENT = '[EVENT_STORE] Set event';
export const loadEvent = (id, competitionId, eventId, onSuccess) => ({
  type: LOAD_EVENT,
  id,
  competitionId,
  eventId,
  onSuccess
});

export const CLEAR_EVENT = '[EVENT_STORE] Clear event';
export const clearEvent = () => ({
  type: CLEAR_EVENT
});

export const LOAD_EVENT_SCHEDULE = '[EVENT_STORE] Load event schedule';
export const SET_EVENT_SCHEDULE = '[EVENT_STORE] Set event schedules';
export const loadEventSchedule = (eventId, onSuccess) => ({
  type: LOAD_EVENT_SCHEDULE,
  eventId,
  onSuccess
});

export const CANCEL_EVENT = '[EVENT_STORE] Cancel event';
export const cancelEvent = (id, competitionId, eventId, payload) => ({
  type: CANCEL_EVENT,
  id,
  competitionId,
  eventId,
  payload
});

export const REMOVE_EVENT = '[EVENT_STORE] Remove event';
export const removeEvent = (id, competitionId, eventId, navigate) => ({
  type: REMOVE_EVENT,
  id,
  competitionId,
  eventId,
  navigate
});

export const REINSTATE_EVENT = '[EVENT_STORE] Reinstate event';
export const reinstateEvent = (id, competitionId, eventId) => ({
  type: REINSTATE_EVENT,
  id,
  competitionId,
  eventId
});

export const POSTPONE_EVENT = '[EVENT_STORE] Postpone event';
export const postponeEvent = (id, competitionId, eventId, payload) => ({
  type: POSTPONE_EVENT,
  id,
  competitionId,
  eventId,
  payload
});

export const LOAD_EVENT_CLASSES = '[EVENT_STORE] Load event classes';
export const SET_EVENT_CLASSES = '[EVENT_STORE] Set event classes';
export const loadEventClasses = (eventId, onSuccess) => ({
  type: LOAD_EVENT_CLASSES,
  eventId,
  onSuccess
});

export const CREATE_EVENT_SCHEDULE = '[EVENT_STORE] Create event schedule';
export const createEventSchedule = (id, payload) => ({
  type: CREATE_EVENT_SCHEDULE,
  id,
  payload
});

export const UPDATE_EVENT_SCHEDULE = '[EVENT_STORE] Update event schedule';
export const updateEventSchedule = (id, scheduleId, payload) => ({
  type: UPDATE_EVENT_SCHEDULE,
  id,
  scheduleId,
  payload
});

export const SAVE_EVENT_DETAILS = '[EVENT_STORE] Save event details';
export const saveEventDetails = (id, competitionId, payload, onSuccess, onError) => ({
  type: SAVE_EVENT_DETAILS,
  id,
  competitionId,
  payload,
  onSuccess,
  onError
});

export const UPDATE_EVENT_DETAILS = '[EVENT_STORE] Update event details';
export const updateEventDetails = (id, competitionId, eventId, payload, onSuccess, onError) => ({
  type: UPDATE_EVENT_DETAILS,
  id,
  competitionId,
  eventId,
  payload,
  onSuccess,
  onError
});

export const SAVE_EVENT_POINTS_CONFIG = '[EVENT_STORE] Save event points configuration';
export const saveEventPointsConfig = (id, competitionId, eventId, payload, onSuccess, onError) => ({
  type: SAVE_EVENT_POINTS_CONFIG,
  id,
  competitionId,
  eventId,
  payload,
  onSuccess,
  onError
});

export const SAVE_EVENT_ENTRY_FORM = '[EVENT_STORE] Save event entry form';
export const saveEventEntryForm = (id, competitionId, eventId, payload, onSuccess, onError) => ({
  type: SAVE_EVENT_ENTRY_FORM,
  id,
  competitionId,
  eventId,
  payload,
  onSuccess,
  onError
});

export const SAVE_EVENT_DRAFT = '[EVENT_STORE] Save event as a draft';
export const saveEventDraft = (id, competitionId, eventId, navigate, onError) => ({
  type: SAVE_EVENT_DRAFT,
  id,
  competitionId,
  eventId,
  navigate,
  onError
});

export const PUBLISH_EVENT = '[EVENT_STORE] Publish event';
export const publishEvent = (id, competitionId, eventId, navigate, onError, onDuplicate) => ({
  type: PUBLISH_EVENT,
  id,
  competitionId,
  eventId,
  navigate,
  onError,
  onDuplicate
});
