import React from 'react';

import {
  Card,
  Form,
  FormRow,
  getProfileInitials,
  Input,
  ProfileImage
} from '@neslotech/eventhub-ui-kit';
import { getClassNames } from '@neslotech/utils';

import { ReactComponent as PencilIcon } from '../../../icons/blue-edit-icon.svg';

import './personal-details.scss';

const PersonalDetailsSection = ({
  user,
  form,
  handleChange,
  editMode,
  setEditMode,
  handleFileChange
}) => (
  <Card>
    <section className="personal-details">
      <section className="personal-details__icon">
        <ProfileImage
          imgSrc={form.image}
          initials={getProfileInitials(user)}
          editMode={editMode}
          handleFileChange={handleFileChange}
        />
      </section>
      <section className={getClassNames('personal-details__form', { editing: editMode })}>
        <header>
          <h4>Personal Details</h4>
          <PencilIcon onClick={() => setEditMode(true)} />
        </header>
        <Form>
          <FormRow fluid>
            <Input
              name="firstName"
              placeholder="First Name"
              label="First Name"
              value={form?.firstName}
              disabled={!editMode}
              onChange={handleChange}
              error={form?.messages?.firstName}
            />
            <Input
              name="lastName"
              placeholder="Last Name"
              label="Last Name"
              value={form?.lastName}
              disabled={!editMode}
              onChange={handleChange}
              error={form?.messages?.lastName}
            />
          </FormRow>
          <FormRow>
            <Input
              name="phoneNumber"
              placeholder="+27 123456789"
              label="Phone Number"
              value={form?.phoneNumber}
              disabled={!editMode}
              onChange={handleChange}
              error={form?.messages?.phoneNumber}
            />
          </FormRow>
        </Form>
      </section>
    </section>
  </Card>
);

export default PersonalDetailsSection;
