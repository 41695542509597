import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Accordion,
  Button,
  ButtonGroup,
  DetailsSection,
  DRAFT_STATUS,
  Form,
  FormRow,
  NONE,
  PUBLISHED_STATUS,
  Select,
  TextArea,
  USE_TEMPLATE,
  YES
} from '@neslotech/eventhub-ui-kit';
import { deepClone } from '@neslotech/utils';
import { useFormState } from '@neslotech/hooks';

import { DEFAULT_PLACES } from '../../../../tools/constants';
import { formify, serverifyTemplate, serverify } from './PointsConfiguration.helper';

import { loadPointsConfigTemplates } from '../../../../actions/template.actions';

import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';
import { useWizardContext } from '../../../../hooks/useWizardContext';

import SaveConfigTemplateContainer from '../../../../containers/organizer/competitions/templates/SaveConfigTemplate.container';
import UpdateConfigTemplateContainer from '../../../../containers/organizer/competitions/templates/UpdateConfigTemplate.container';

import DynamicPointsConfigTemplate from '../../../dynamic-templates/DynamicPointsConfigTemplate';

import './points-configuration.scss';

const rules = {
  validates: {
    required: ['isPresent']
  }
};

const Requirements = ({ templates, form, handleChange }) => {
  const templateItems = useMemo(() => {
    return templates.map((template) => ({
      text: template.name,
      value: template.name,
      onClick: () => {
        const data = template.data;
        handleChange({
          templateId: template.id,
          template: template.name,
          places: data.positions,
          description: data.description
        });
      }
    }));
  }, [handleChange, templates]);

  return (
    <Form>
      <FormRow>
        <Select
          name="required"
          placeholder="Select an option that suits your competitions needs"
          label="Do you require points configuration?"
          value={form?.required}
          items={[
            {
              text: YES,
              value: YES,
              onClick: () =>
                handleChange({
                  required: YES,
                  template: undefined,
                  templateId: undefined,
                  description: undefined,
                  places: deepClone(DEFAULT_PLACES)
                })
            },
            {
              text: NONE,
              value: NONE,
              onClick: () =>
                handleChange({
                  required: NONE,
                  places: undefined,
                  description: undefined,
                  template: undefined,
                  templateId: undefined
                })
            },
            {
              text: USE_TEMPLATE,
              value: USE_TEMPLATE,
              onClick: () =>
                handleChange({ required: USE_TEMPLATE, template: undefined, templateId: undefined })
            }
          ]}
        />
      </FormRow>
      {form.required === USE_TEMPLATE && (
        <FormRow>
          <Select
            name="template"
            placeholder="Select one of your saved templates"
            label="Choose template"
            value={form?.template}
            items={templateItems}
          />
        </FormRow>
      )}
    </Form>
  );
};

const PointsConfiguration = ({ entityKey }) => {
  const dispatch = useDispatch();

  const { id } = useOrganisationContext();
  const { context, entity, onNextStep } = useWizardContext();

  const [form, setForm] = useFormState(formify(entity.points_configuration), rules);
  const [template, setTemplate] = useState();
  const [templateToUpdate, setTemplateToUpdate] = useState();

  const handleChange = (newState) => {
    setForm({ ...form, ...newState });
  };

  const templates = useSelector(({ template_store }) => template_store.pointsConfigTemplates);

  useEffect(() => {
    if (form.required === USE_TEMPLATE) {
      dispatch(loadPointsConfigTemplates(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, form.required]);

  const handleDiscard = () => {
    const updatedEntity = {
      ...entity,
      template: undefined,
      templateId: undefined,
      places: undefined,
      description: undefined,
      required: undefined
    };
    setForm(formify(updatedEntity));
  };

  const handleSaveTemplateAsDraft = () => {
    const payload = serverifyTemplate(form, DRAFT_STATUS);
    setTemplate(payload);
  };

  const handleSaveTemplate = () => {
    const payload = serverifyTemplate(form, PUBLISHED_STATUS);
    setTemplate(payload);
  };

  const handleUpdateTemplate = () => {
    const payload = serverifyTemplate(form, PUBLISHED_STATUS);
    setTemplateToUpdate(payload);
  };

  const handleNextStep = () => {
    const payload = serverify(form);
    onNextStep(entityKey, payload);
  };

  const shouldShowTemplate =
    form?.required === YES || (form?.required === USE_TEMPLATE && form?.template);
  const formValid = form?.valid && (form.places ?? []).every((item) => !!item.points);
  return (
    <>
      <section className="add-points-configuration">
        <Accordion title="Point Configuration Requirements" open>
          <section className="add-points-configuration__requirements">
            <Requirements templates={templates} form={form} handleChange={handleChange} />
          </section>
        </Accordion>
        <section className="add-points-configuration__template">
          {shouldShowTemplate && (
            <DynamicPointsConfigTemplate editMode form={form} handleChange={handleChange} />
          )}
        </section>
        {shouldShowTemplate && (
          <section className="add-points-configuration__description">
            <DetailsSection title="" subtitle="Points configuration description">
              <TextArea
                name="description"
                placeholder="Type a small description about how the points configuration works for your participants."
                value={form?.description}
                onChange={handleChange}
              />
            </DetailsSection>
          </section>
        )}
      </section>
      <section className="wizard-layout__actions">
        {form?.required && (
          <>
            <ButtonGroup fluid spaced>
              {(shouldShowTemplate || form?.required === NONE) && (
                <Button disabled={!formValid} label="Next Step" onClick={handleNextStep} />
              )}
              {form?.required === YES && (
                <Button
                  disabled={!formValid}
                  label="Save Template as Draft"
                  longText
                  hollow
                  onClick={handleSaveTemplateAsDraft}
                />
              )}
              {form?.required === USE_TEMPLATE && form?.templateId && (
                <Button
                  hollow
                  disabled={!formValid}
                  label="Update Template"
                  onClick={handleUpdateTemplate}
                />
              )}
              {(form?.required === YES ||
                (form?.required === USE_TEMPLATE && form?.templateId)) && (
                <Button
                  hollow
                  longText
                  disabled={!formValid}
                  label="Save as New Template"
                  onClick={handleSaveTemplate}
                />
              )}
            </ButtonGroup>
            {shouldShowTemplate && (
              <Button label="Clear Page" secondaryDanger onClick={handleDiscard} />
            )}
          </>
        )}
      </section>
      <SaveConfigTemplateContainer
        context={context}
        template={template}
        setShowModal={setTemplate}
        show={template}
      />
      <UpdateConfigTemplateContainer
        context={context}
        template={templateToUpdate}
        setShowModal={setTemplateToUpdate}
        show={templateToUpdate}
      />
    </>
  );
};

export default PointsConfiguration;
