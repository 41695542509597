import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PageLayout } from '@neslotech/eventhub-ui-kit';

import { saveTemplate } from '../../../../actions/template.actions';

import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';

import OrganizerLayout from '../../../../components/layout/organizer/OrganizerLayout';
import AddPointsConfigTemplate from '../../../../components/templates/add/AddPointsConfigTemplate';

const AddPointsConfigurationTemplate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useOrganisationContext();

  const onAdd = (payload) => {
    dispatch(saveTemplate(id, payload));
    navigate('/templates');
  };

  return (
    <OrganizerLayout gutterless>
      <PageLayout title="Create Points Configuration Template" back fixed>
        <AddPointsConfigTemplate onAdd={onAdd} />
      </PageLayout>
    </OrganizerLayout>
  );
};

export default AddPointsConfigurationTemplate;
