import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Loader, PageLayout } from '@neslotech/eventhub-ui-kit';

import { loadEvent } from '../../../../actions/event.actions';

import {
  DETAILS_KEY,
  ENTRY_FORM_KEY,
  POINTS_CONFIG_KEY,
  PREVIEW_KEY
} from '../../../../tools/constants';

import FormBuilderProvider from '../../../../providers/FormBuilder.provider';
import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';

import OrganizerLayout from '../../../../components/layout/organizer/OrganizerLayout';
import Wizard from '../../../../components/wizard/Wizard';
import PointsConfiguration from '../../../../components/events/add/points-configuration/PointsConfiguration';
import EntryForm from '../../../../components/events/add/entry-form/EntryForm';
import Preview from '../../../../components/events/add/preview/Preview';
import AddEvent from '../../../../components/events/add/AddEvent';

import EventWizardProvider from '../../../../providers/wizard/EventWizard.provider';

const steps = [
  {
    key: DETAILS_KEY,
    index: 1,
    label: 'Edit your event',
    component: AddEvent
  },
  {
    key: POINTS_CONFIG_KEY,
    index: 2,
    label: 'Edit points configuration',
    component: PointsConfiguration
  },
  {
    key: ENTRY_FORM_KEY,
    index: 3,
    label: 'Edit entry form',
    component: EntryForm
  },
  {
    key: PREVIEW_KEY,
    index: 4,
    label: 'Preview',
    component: Preview
  }
];

const EditEventContainer = () => {
  const dispatch = useDispatch();

  const { competitionId, id: eventId } = useParams();
  const { id } = useOrganisationContext();

  const event = useSelector(({ event_store }) => event_store.event);
  const stateLoading = useSelector(({ event_store }) => event_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  const subtitle = useMemo(() => {
    return event?.competition && `Linked to: ${event.competition.name}`;
  }, [event]);

  useEffect(() => {
    dispatch(loadEvent(id, competitionId, eventId, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, competitionId, eventId]);

  return (
    <OrganizerLayout gutterless>
      <PageLayout title="Edit Event" subtitle={subtitle} fixed back>
        {loading && <Loader filled />}
        {!loading && (
          <EventWizardProvider>
            <FormBuilderProvider existingEntity={event}>
              <Wizard steps={steps} />
            </FormBuilderProvider>
          </EventWizardProvider>
        )}
      </PageLayout>
    </OrganizerLayout>
  );
};

export default EditEventContainer;
