import React, { useMemo } from 'react';

import { AddAction, Input, Select, Table } from '@neslotech/eventhub-ui-kit';
import { generateId } from '@neslotech/utils';

import { useWizardContext } from '../../../../hooks/useWizardContext';

import { ReactComponent as RemoveIcon } from '../../../../icons/x-icon.svg';

import '../add-competition.scss';

export const tableHeaders = [
  { Header: '', accessor: 'icon', disableSortBy: true },
  { Header: 'Class Name', accessor: 'name' },
  { Header: '1st Class', accessor: 'firstClassPrice' },
  { Header: '2nd Class', accessor: 'secondClassPrice' },
  { Header: '1st Class (Family Member)', accessor: 'firstClassFamilyPrice' },
  { Header: '2nd Class (Family Member)', accessor: 'secondClassFamilyPrice' }
];

const columnModifiers = {
  icon: {
    hugged: true,
    small: true
  },
  name: {
    thin: true,
    small: true
  },
  firstClassPrice: {
    thin: true,
    small: true
  },
  secondClassPrice: {
    thin: true,
    small: true
  },
  firstClassFamilyPrice: {
    thin: true,
    small: true
  },
  secondClassFamilyPrice: {
    thin: true,
    small: true
  }
};

const EMPTY_CLASS = () => ({
  name: '',
  firstClassPrice: '',
  secondClassPrice: '',
  firstClassFamilyPrice: '',
  secondClassFamilyPrice: '',
  _id: generateId()
});

const CompetitionClasses = ({ form, handleChange }) => {
  const { classTypes } = useWizardContext();

  const mappedData = useMemo(() => {
    const handleValueChange = (index, value) => {
      const classes = form?.classes ?? [];
      let allocation = form?.classes[index] ?? {};
      allocation = {
        ...allocation,
        ...value
      };
      classes[index] = allocation;

      handleChange({ classes });
    };

    return (form?.classes ?? [])
      .filter((item) => !item._destroy)
      .map((allocation, index) => {
        const mappedTypes = (classTypes ?? []).map((classType) => ({
          text: classType.name,
          value: classType.id,
          onClick: () =>
            handleValueChange(index, { classTypeId: classType.id, name: classType.name })
        }));

        return {
          name: (
            <Select
              borderless
              name="name"
              placeholder="MX50cc"
              items={mappedTypes}
              value={allocation.name}
            />
          ),
          firstClassPrice: (
            <Input
              borderless
              type="number"
              name="firstClassPrice"
              placeholder="850"
              prefix="R"
              value={allocation.firstClassPrice}
              autoComplete="off"
              onChange={(val) => handleValueChange(index, val)}
            />
          ),
          secondClassPrice: (
            <Input
              borderless
              type="number"
              name="secondClassPrice"
              placeholder="850"
              prefix="R"
              value={allocation.secondClassPrice}
              autoComplete="off"
              onChange={(val) => handleValueChange(index, val)}
            />
          ),
          firstClassFamilyPrice: (
            <Input
              borderless
              type="number"
              name="firstClassFamilyPrice"
              placeholder="550"
              prefix="R"
              value={allocation.firstClassFamilyPrice}
              autoComplete="off"
              onChange={(val) => handleValueChange(index, val)}
            />
          ),
          secondClassFamilyPrice: (
            <Input
              borderless
              type="number"
              name="secondClassFamilyPrice"
              placeholder="550"
              prefix="R"
              value={allocation.secondClassFamilyPrice}
              autoComplete="off"
              onChange={(val) => handleValueChange(index, val)}
            />
          ),
          icon: <RemoveIcon onClick={() => removeRow(allocation)} />
        };
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.classes, handleChange]);

  const addNewRow = () => {
    const classes = form?.classes ?? [];
    classes.push(EMPTY_CLASS());
    handleChange({ classes });
  };

  const removeRow = (allocation) => {
    const classes = (form?.classes ?? []).reduce((accum, currentClass) => {
      if (currentClass._id === allocation._id) {
        currentClass._destroy = '1';
      }

      return [...accum, currentClass];
    }, []);
    handleChange({ classes });
  };

  return (
    <section className="add-competition-classes">
      <Table
        columnModifiers={columnModifiers}
        headerModifiers={columnModifiers}
        cols={tableHeaders}
        rowData={mappedData}
      />
      <AddAction title="Add New Class" onClick={addNewRow} />
    </section>
  );
};

export default CompetitionClasses;
