const JPEG = 'jpeg';
const SVG = 'svg';
const PNG = 'png';
const WEBP = 'webp';
const PDF = 'pdf';
const DOCX = 'docx';
const MP4 = 'mp4';
const HTML = 'html';
const XLS = 'xls';
const TXT = 'txt';

const JPEG_TYPE = 'image/jpeg';
const SVG_TYPE = 'image/svg+xml';
const PNG_TYPE = 'image/png';
const WEBP_TYPE = 'image/webp';
const PDF_TYPE = 'application/pdf';
const DOCX_TYPE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const MP4_TYPE = 'video/mp4';
const HTML_TYPE = 'text/html';
const XLS_TYPE = 'application/vnd.ms-excel';
const TXT_TYPE = 'text/plain';

export const mapFileTypes = (types) => {
  if (!types) {
    return undefined;
  }

  return Object.entries(types)
    .filter(([_, value]) => !!value)
    .map(([key]) => {
      switch (key) {
        case JPEG:
          return JPEG_TYPE;
        case SVG:
          return SVG_TYPE;
        case PNG:
          return PNG_TYPE;
        case WEBP:
          return WEBP_TYPE;
        case PDF:
          return PDF_TYPE;
        case DOCX:
          return DOCX_TYPE;
        case MP4:
          return MP4_TYPE;
        case HTML:
          return HTML_TYPE;
        case XLS:
          return XLS_TYPE;
        case TXT:
          return TXT_TYPE;
        default:
          return '';
      }
    })
    .filter((type) => type !== '');
};

export const mapFileTypesToObject = (types) => {
  if (!types) {
    return {};
  }

  return types.reduce((accum, type) => {
    switch (type) {
      case JPEG_TYPE:
        accum[JPEG] = true;
        break;
      case SVG_TYPE:
        accum[SVG] = true;
        break;
      case PNG_TYPE:
        accum[PNG] = true;
        break;
      case WEBP_TYPE:
        accum[WEBP] = true;
        break;
      case PDF_TYPE:
        accum[PDF] = true;
        break;
      case DOCX_TYPE:
        accum[DOCX] = true;
        break;
      case MP4_TYPE:
        accum[MP4] = true;
        break;
      case HTML_TYPE:
        accum[HTML] = true;
        break;
      case XLS_TYPE:
        accum[XLS] = true;
        break;
      case TXT_TYPE:
        accum[TXT] = true;
        break;
      default:
        return accum;
    }

    return accum;
  }, {});
};
