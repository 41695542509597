import { deepClone } from '@neslotech/utils';
import { POINTS_CONFIG_TYPE } from '@neslotech/eventhub-ui-kit';

import { DEFAULT_PLACES } from '../../../../tools/constants';

export const formify = (entity) => ({
  id: entity?.id,
  required: entity?.required,
  description: entity?.description,
  places: entity?.places ?? deepClone(DEFAULT_PLACES)
});

export const serverify = (form) => ({
  points_configuration_attributes: {
    id: form?.id,
    positions_attributes: form?.places,
    description: form?.description
  }
});

export const serverifyTemplate = (form, status) => ({
  id: form.templateId,
  name: form.template,
  template_type: POINTS_CONFIG_TYPE,
  status,
  data: {
    positions: form.places,
    description: form.description
  }
});
