import {
  SET_ENTRY_FORM_TEMPLATES,
  SET_POINTS_CONFIG_TEMPLATES,
  SET_TEMPLATE,
  SET_TEMPLATES
} from '../actions/template.actions';

export const initialState = {
  templates: [],
  pointsConfigTemplates: [],
  entryFormTemplates: [],
  template: undefined
};

export function templateReducer(state = initialState, action) {
  switch (action.type) {
    case SET_POINTS_CONFIG_TEMPLATES:
      const { pointsConfigTemplates } = action;
      return {
        ...state,
        pointsConfigTemplates
      };
    case SET_ENTRY_FORM_TEMPLATES:
      const { entryFormTemplates } = action;
      return {
        ...state,
        entryFormTemplates
      };
    case SET_TEMPLATES:
      const { templates } = action;
      return {
        ...state,
        templates
      };
    case SET_TEMPLATE:
      const { template } = action;
      return {
        ...state,
        template
      };
    default:
      return state;
  }
}
