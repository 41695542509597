import React from 'react';
import { bool, string } from 'prop-types';

import { getOrganisationInitials } from '@neslotech/eventhub-ui-kit';
import { getClassNames } from '@neslotech/utils';

import './organisation-badge.scss';

const renderImage = (name, imgSrc, small, medium, round) => {
  if (!imgSrc) {
    return (
      <div className={getClassNames('organisation-badge__default', { small, medium, round })}>
        <label>{getOrganisationInitials(name)}</label>
      </div>
    );
  }

  return <img className="organisation-badge__img" src={imgSrc} alt="Organisation badge" />;
};

const OrganisationBadge = ({ name, imgSrc, medium, round, small }) => {
  return (
    <section className={getClassNames('organisation-badge', { small, medium, round })}>
      {renderImage(name, imgSrc, small, medium, round)}
    </section>
  );
};

OrganisationBadge.defaultProps = {
  imgSrc: undefined,
  small: false
};

OrganisationBadge.propTypes = {
  name: string,
  imgSrc: string,
  small: bool
};

export default OrganisationBadge;
