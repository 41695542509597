import React, { useMemo } from 'react';

import { noOp } from '@neslotech/utils';
import { Button, ButtonGroup, Card, Loader, Tabs } from '@neslotech/eventhub-ui-kit';

import { useScheduleContext } from '../../../hooks/useScheduleContext';

import { ReactComponent as ListItemIcon } from '../../../icons/list-item.svg';
import { ReactComponent as ClockIcon } from '../../../icons/arrow-clock-icon.svg';

import ScheduleSection from '../section/ScheduleSection';

import './preview-schedule.scss';

const PreviewSchedule = () => {
  const { loading, event, schedule, stateSchedule } = useScheduleContext();

  const memoizedSchedule = useMemo(() => {
    return stateSchedule ?? schedule;
  }, [stateSchedule, schedule]);

  const tabs = useMemo(() => {
    const classScheduleTabs = (memoizedSchedule?.competitionClassSchedules ?? []).map(
      (classSchedule) => ({
        key: `schedule-tab-${classSchedule.competitionClass.id}`,
        label: classSchedule.competitionClass.name,
        icon: <ListItemIcon />,
        onClick: noOp,
        colorBlip: classSchedule.color
      })
    );

    const timeslotTabs = (memoizedSchedule?.timeslots ?? []).map((slot) => ({
      key: slot.id,
      label: slot.name,
      icon: <ClockIcon />,
      onClick: noOp,
      colorBlip: slot.color
    }));

    return [...classScheduleTabs, ...timeslotTabs];
  }, [memoizedSchedule]);

  return (
    <>
      {loading && <Loader filled />}
      {!loading && (
        <article className="preview-schedule">
          <article className="preview-schedule__content">
            <aside>
              <h4>Classes in Schedule</h4>
              <Tabs tabs={tabs} vertical disableActive={true} />
              <section className="preview-schedule__actions">
                <Card>
                  <ButtonGroup spaced>
                    <Button label="Publish Schedule" longText />
                    <Button label="Save Draft" hollow />
                    <Button label="Discard" secondaryDanger />
                  </ButtonGroup>
                </Card>
              </section>
            </aside>
            <section className="preview-schedule__info-section">
              <ScheduleSection event={event} schedule={memoizedSchedule} />
            </section>
          </article>
        </article>
      )}
    </>
  );
};

export default PreviewSchedule;
