import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import { postponeCompetition } from '../../../../actions/competition.actions';
import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';

import PostponeReason from '../../../../components/competitions/postpone/PostponeReason';
import PostponeDate from '../../../../components/competitions/postpone/PostponeDate';

const REASON = 'reason';
const DATE = 'date';

const rules = {
  validates: {
    reason: ['isPresent']
  }
};

const PostponeCompetitionContainer = ({ competition, show, setShowModal }) => {
  const dispatch = useDispatch();
  const { id } = useOrganisationContext();

  const [form, setForm] = useFormState(undefined, rules);
  const [context, setContext] = useState(REASON);

  const onClose = () => {
    setContext(REASON);
    setShowModal(false);
  };

  const handleReasonChange = (payload) => {
    setContext(DATE);
    setForm(payload);
  };

  const handleDateChange = (payload) => {
    dispatch(postponeCompetition(id, competition.id, { ...form, ...payload }));
    onClose();
  };

  const title = useMemo(() => {
    return context === REASON ? 'Postpone Competition' : 'Choose a Date';
  }, [context]);

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout large onClose={onClose} title={title}>
            {context === REASON && (
              <PostponeReason
                competition={competition}
                onConfirmClick={handleReasonChange}
                onCancelClick={onClose}
              />
            )}
            {context === DATE && (
              <PostponeDate onConfirmClick={handleDateChange} onCancelClick={onClose} />
            )}
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default PostponeCompetitionContainer;
