import React from 'react';

import { Button, ButtonGroup } from '@neslotech/eventhub-ui-kit';

import './delete-organisation.scss';

const DeleteOrganisation = ({ onConfirmClick, onCancelClick, onOrganisationChange }) => (
  <section className="delete-organisation">
    <section className="delete-organisation__message">
      <p>Deleting this organisation will result in a loss of all captured information</p>
    </section>
    <ButtonGroup>
      <Button label="Confirm" onClick={onConfirmClick} />
      <Button label="Cancel" onClick={onCancelClick} secondary />
    </ButtonGroup>
  </section>
);

export default DeleteOrganisation;
