import React from 'react';

import { Button, ButtonGroup } from '@neslotech/eventhub-ui-kit';

import './update-template.scss';

const UpdateTemplate = ({ onConfirmClick, onCancelClick }) => {
  const handleConfirm = () => {
    onConfirmClick();
  };

  return (
    <section className="update-template">
      <section className="update-template__message">
        <p>
          Updating this template will mean that all future uses of this template will have the
          changes you have just applied to it.
        </p>
        <p>Any existing events or competitions making use of this template will not be effected.</p>
      </section>
      <ButtonGroup>
        <Button label="Confirm" onClick={handleConfirm} />
        <Button label="Cancel" onClick={onCancelClick} secondary />
      </ButtonGroup>
    </section>
  );
};

export default UpdateTemplate;
