import React from 'react';

import {
  Button,
  ButtonGroup,
  Form,
  FormRow,
  Input,
  RadioGroup,
  TextArea
} from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import './text-area-properties.scss';

const requiredOptions = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

const rules = {
  validates: {
    label: ['isPresent'],
    placeholder: ['isPresent']
  }
};

const formify = ({
  id,
  type,
  placeholder,
  label,
  filled,
  required,
  subtitle,
  value,
  informational
}) => ({
  id,
  type,
  placeholder,
  label,
  filled,
  required,
  subtitle,
  value,
  informational
});

const serverify = ({
  id,
  type,
  placeholder,
  label,
  filled,
  required,
  subtitle,
  value,
  informational
}) => ({
  id,
  type,
  placeholder,
  label,
  filled,
  required,
  subtitle,
  value,
  informational
});

const TextAreaProperties = ({ element, onSave, onCancel }) => {
  const [form, setForm] = useFormState(formify(element), rules);

  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleSave = () => {
    const payload = serverify(form);
    onSave(payload);
  };

  return (
    <article className="text-area-properties">
      <Form>
        <FormRow>
          <Input
            name="label"
            placeholder="Heading Text"
            label="Heading Text"
            onChange={handleChange}
            value={form?.label}
            error={form?.messages?.label}
          />
        </FormRow>
        <FormRow>
          <Input
            name="subtitle"
            placeholder="Description Heading"
            label="Description Heading"
            onChange={handleChange}
            value={form?.subtitle}
            error={form?.messages?.subtitle}
          />
        </FormRow>
        <FormRow>
          <Input
            name="placeholder"
            placeholder="Placeholder Text"
            label="Placeholder Text"
            onChange={handleChange}
            value={form?.placeholder}
            error={form?.messages?.placeholder}
          />
        </FormRow>
        <FormRow>
          <TextArea
            name="value"
            placeholder="Enter description"
            label="Description"
            onChange={handleChange}
            value={form?.value}
            error={form?.messages?.value}
          />
        </FormRow>
        <FormRow>
          <RadioGroup
            name="required"
            items={requiredOptions}
            onChange={handleChange}
            label="Required field?"
            value={form?.required}
          />
        </FormRow>
        <FormRow>
          <RadioGroup
            name="informational"
            items={requiredOptions}
            onChange={handleChange}
            label="Is this informational?"
            value={form?.informational}
          />
        </FormRow>
      </Form>
      <ButtonGroup centered>
        <Button label="Save" disabled={!form?.valid} onClick={handleSave} />
        <Button secondary label="Cancel" onClick={onCancel} />
      </ButtonGroup>
    </article>
  );
};

export default TextAreaProperties;
