import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { toBase64 } from '@neslotech/eventhub-ui-kit';
import { noOp, scrollIntoView } from '@neslotech/utils';

import { DETAILS_KEY, ENTRY_FORM_KEY, POINTS_CONFIG_KEY } from '../../tools/constants';
import {
  formifyDetails,
  formifyEntryForm,
  formifyPointsConfig
} from './EventWizard.provider.helpers';

import { WizardContext } from '../../contexts/Wizard.context';
import { useOrganisationContext } from '../../hooks/useOrganisationContext';

import {
  publishEvent,
  removeEvent,
  saveEventDetails,
  saveEventDraft,
  saveEventEntryForm,
  saveEventPointsConfig,
  updateEventDetails
} from '../../actions/event.actions';

import WizardLayout from '../../components/layout/wizard/WizardLayout';

const EventWizardProvider = ({ onDuplicateFound, children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { competitionId } = useParams();

  const { id } = useOrganisationContext();

  const [activeStep, setActiveStep] = useState(1);
  const [errorsOnStep, setErrorsOnStep] = useState();
  const [image, setImage] = useState();

  const event = useSelector(({ event_store }) => event_store.event);
  const entity = useMemo(() => {
    return {
      id: event?.id,
      image: event?.image,
      details: formifyDetails(event),
      points_configuration: formifyPointsConfig(event?.points_configuration),
      entry_form: formifyEntryForm(event)
    };
  }, [event]);

  useEffect(() => {
    setImage(entity?.image);
  }, [entity?.image]);

  const saveDetails = (payload, onComplete) => {
    if (entity?.id) {
      dispatch(
        updateEventDetails(
          id,
          payload.competition_id ?? competitionId,
          entity.id,
          { ...payload, id: entity.id, image },
          () => {
            onCompleteProcessing();
            onComplete();
          },
          (data) => {
            handleProcessingError(data);
            onComplete();
          }
        )
      );
    } else {
      dispatch(
        saveEventDetails(
          id,
          payload.competition_id ?? competitionId,
          { ...payload, image },
          () => {
            onCompleteProcessing();
            onComplete();
          },
          (data) => {
            handleProcessingError(data);
            onComplete();
          }
        )
      );
    }
  };

  const savePointsConfig = (payload, onComplete) => {
    dispatch(
      saveEventPointsConfig(
        id,
        competitionId ?? entity.details.competition_id,
        entity?.id,
        payload,
        () => {
          onCompleteProcessing();
          onComplete();
        },
        (data) => {
          handleProcessingError(data);
          onComplete();
        }
      )
    );
  };

  const saveEntryForm = (payload, onComplete) => {
    dispatch(
      saveEventEntryForm(
        id,
        competitionId ?? entity.details.competition_id,
        entity?.id,
        payload,
        () => {
          onCompleteProcessing();
          onComplete();
        },
        (data) => {
          handleProcessingError(data);
          onComplete();
        }
      )
    );
  };

  const handleProcessingError = (data) => {
    scrollIntoView('stepper');
    setErrorsOnStep(data);
  };

  const onCompleteProcessing = () => {
    scrollIntoView('stepper');
    setActiveStep(activeStep + 1);
  };

  const handleNextStep = (key, payload, onComplete = noOp) => {
    setErrorsOnStep();

    switch (key) {
      default:
      case DETAILS_KEY:
        saveDetails(payload, onComplete);
        break;
      case POINTS_CONFIG_KEY:
        savePointsConfig(payload, onComplete);
        break;
      case ENTRY_FORM_KEY:
        saveEntryForm(payload, onComplete);
        break;
    }
  };

  const handlePreviousStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleStepChange = (index) => {
    setActiveStep(index);
  };

  const handleDiscard = () => {
    dispatch(removeEvent(id, entity.details.competition_id, entity.id, navigate));
  };

  const handleSaveAsDraft = () => {
    dispatch(
      saveEventDraft(id, entity.details.competition_id, entity.id, navigate, handleProcessingError)
    );
  };

  const handlePublish = () => {
    dispatch(
      publishEvent(
        id,
        entity.details.competition_id,
        entity.id,
        navigate,
        handleProcessingError,
        onDuplicateFound
      )
    );
  };

  const handleFileChange = async (file) => {
    const base64 = await toBase64(file);
    setImage(base64);
  };

  const value = {
    context: 'event',
    activeStep,
    entity,
    errors: errorsOnStep,
    onStepChange: handleStepChange,
    onNextStep: handleNextStep,
    onPreviousStep: handlePreviousStep,
    onDiscard: handleDiscard,
    onSaveAsDraft: handleSaveAsDraft,
    onPublish: handlePublish
  };

  return (
    <WizardContext.Provider value={value}>
      <WizardLayout activeStep={activeStep} image={image} handleFileChange={handleFileChange}>
        {children}
      </WizardLayout>
    </WizardContext.Provider>
  );
};

export default EventWizardProvider;
