import { generateId } from '@neslotech/utils';

import {
  ADD_SYSTEM_NOTICE,
  REMOVE_SYSTEM_NOTICE,
  SET_CLASS_TYPES,
  SET_SPORTS
} from '../actions/system.actions';

export const initialState = {
  notices: [],
  sports: [],
  classTypes: []
};

export function systemReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_SYSTEM_NOTICE:
      const { title, alertType } = action;
      return {
        ...state,
        notices: [...state.notices, { id: generateId(), title, alertType }]
      };
    case REMOVE_SYSTEM_NOTICE:
      const { id } = action;
      return {
        ...state,
        notices: state.notices.filter((notice) => notice.id !== id)
      };
    case SET_SPORTS:
      const { sports } = action;
      return {
        ...state,
        sports
      };
    case SET_CLASS_TYPES:
      const { classTypes } = action;
      return {
        ...state,
        classTypes
      };
    default:
      return state;
  }
}
