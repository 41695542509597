import React, { useState } from 'react';

import {
  Button,
  ButtonGroup,
  DRAFT_STATUS,
  Input,
  POINTS_CONFIG_TYPE,
  PUBLISHED_STATUS
} from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import { DEFAULT_PLACES } from '../../../tools/constants';

import PointsConfigTemplate from '../details/points-config/PointsConfigTemplate';

import './add-points-config-template.scss';

const rules = {
  validates: {
    name: ['isPresent']
  }
};

const formify = () => ({
  description: '',
  positions: DEFAULT_PLACES
});

const serverify = (form) => ({
  name: form.name,
  template_type: POINTS_CONFIG_TYPE,
  data: {
    positions: form.places ?? DEFAULT_PLACES,
    description: form.pointsDescription
  }
});

const AddPointsConfigTemplate = ({ onAdd }) => {
  const [template] = useState(formify());
  const [form, setForm] = useFormState(undefined, rules);

  const handleChange = (newState) => {
    setForm({ ...form, ...newState });
  };

  const handleSubmit = () => {
    const payload = serverify(form);
    payload.status = PUBLISHED_STATUS;
    onAdd(payload);
  };

  const handleDraftSubmit = () => {
    const payload = serverify(form);
    payload.status = DRAFT_STATUS;
    onAdd(payload);
  };

  return (
    <article className="add-points-configuration-template">
      <header>
        <Input
          value={form?.name}
          placeholder="Enter your template name"
          name="name"
          onChange={handleChange}
          error={form?.messages?.name}
        />
      </header>
      <main className="add-points-configuration-template__content">
        <PointsConfigTemplate editMode={true} template={template} onChange={handleChange} />
      </main>
      <footer className="add-points-configuration-template__actions">
        <ButtonGroup fluid>
          <Button label="Save as Template" disabled={!form?.valid} onClick={handleSubmit} />
          <Button
            label="Save as Draft"
            disabled={!form?.valid}
            hollow
            onClick={handleDraftSubmit}
          />
        </ButtonGroup>
      </footer>
    </article>
  );
};

export default AddPointsConfigTemplate;
