import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AddAction,
  ENTRY_FORM_TYPE,
  filterElement,
  POINTS_CONFIG_TYPE,
  searchElement,
  Table,
  Tabs,
  useFilters
} from '@neslotech/eventhub-ui-kit';

import { ReactComponent as PointsConfigIcon } from '../../icons/points-config-icon.svg';
import { ReactComponent as EntryFormsIcon } from '../../icons/entry-forms-icon.svg';

import './templates-list.scss';

const headers = [
  {
    Header: 'Template Name',
    accessor: 'name'
  },
  {
    Header: 'Created By',
    accessor: 'created_by'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'Date Created',
    accessor: 'created_at'
  }
];

const modifiers = {
  status: {
    capitalize: true
  }
};

const TemplatesList = ({ templates }) => {
  const navigate = useNavigate();
  const { search, filters, filtering, searching, setFilterDefaults } = useFilters();

  const [activeTab, setActiveTab] = useState(POINTS_CONFIG_TYPE);

  const tabs = useMemo(() => {
    return [
      {
        key: POINTS_CONFIG_TYPE,
        icon: <PointsConfigIcon />,
        label: 'Points Configuration',
        onClick: () => setActiveTab(POINTS_CONFIG_TYPE)
      },
      {
        key: ENTRY_FORM_TYPE,
        icon: <EntryFormsIcon />,
        label: 'Entry Forms',
        onClick: () => setActiveTab(ENTRY_FORM_TYPE)
      }
    ];
  }, []);

  useEffect(
    () => {
      setFilterDefaults({ status: '' });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [templates]
  );

  const filteredTemplates = useMemo(() => {
    return templates
      .filter((template) => template.template_type === activeTab)
      .filter(
        (template) =>
          searchElement(template.name, search) ||
          searchElement(template.status, search) ||
          searchElement(template.created_by, search)
      )
      .filter((template) => filterElement(template, filters));
  }, [activeTab, filters, search, templates]);

  const emptyMessage = useMemo(() => {
    return filtering || searching
      ? 'There are no templates listed under that name.'
      : 'This table is empty. There are no templates listed yet.';
  }, [filtering, searching]);

  const actionTitle = useMemo(() => {
    return activeTab === POINTS_CONFIG_TYPE
      ? 'Create New Point Configuration Template'
      : 'Create New Entry Form Template';
  }, [activeTab]);

  const onAdd = () => {
    if (activeTab === POINTS_CONFIG_TYPE) {
      navigate('points-configuration/add');
    } else {
      navigate('entry-form/add');
    }
  };

  return (
    <article className="templates-list">
      <header className="templates-list__tabs">
        <Tabs tabs={tabs} />
      </header>
      <section className="templates-list__table">
        <Table
          cols={headers}
          columnModifiers={modifiers}
          rowData={filteredTemplates}
          emptyTitle="No Templates Found"
          emptySubtitle={emptyMessage}
        />
      </section>
      <footer className="templates-list__add">
        <AddAction title={actionTitle} onClick={onAdd} />
      </footer>
    </article>
  );
};

export default TemplatesList;
