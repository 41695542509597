export const CREATE_ORGANISATION = '[ORGANISATION_STORE] Create organisation';
export const createOrganisation = (payload, navigate, context, onSuccess) => ({
  type: CREATE_ORGANISATION,
  payload,
  navigate,
  context,
  onSuccess
});

export const LOAD_ORGANISATION = '[ORGANISATION_STORE] Load organisation';
export const SET_ORGANISATION = '[ORGANISATION_STORE] Set organisation';
export const loadOrganisation = (id, onComplete) => ({
  type: LOAD_ORGANISATION,
  id,
  onComplete
});

export const LOAD_BANKING_DETAILS = '[ORGANISATION_STORE] Load banking details';
export const SET_BANKING_DETAILS = '[ORGANISATION_STORE] Set banking details';
export const loadBankingDetails = (id, onComplete) => ({
  type: LOAD_BANKING_DETAILS,
  id,
  onComplete
});

export const EDIT_BANKING_DETAILS = '[ORGANISATION_STORE] Edit banking details';
export const editBankingDetails = (id, bankingDetailsId, payload, onSuccess) => ({
  type: EDIT_BANKING_DETAILS,
  id,
  bankingDetailsId,
  payload,
  onSuccess
});

export const EDIT_ORGANISATION = '[ORGANISATION_STORE] Edit organisation';
export const editOrganisation = (id, payload, onSuccess) => ({
  type: EDIT_ORGANISATION,
  id,
  payload,
  onSuccess
});

export const SET_ORGANISER = '[ORGANISATION_STORE] Set organiser';
export const LOAD_ORGANISER = '[ORGANISATION_STORE] Load organiser';
export const loadOrganiser = (id, organiserId, onComplete) => ({
  type: LOAD_ORGANISER,
  id,
  organiserId,
  onComplete
});

export const REMOVE_ORGANISER = '[ORGANISATION_STORE] Remove organiser';
export const removeOrganiser = (id, organiserId, onSuccess) => ({
  type: REMOVE_ORGANISER,
  id,
  organiserId,
  onSuccess
});

export const REMOVE_ORGANISATION = '[ORGANISATION_STORE] Remove organisation';
export const removeOrganisation = (id, onSuccess) => ({
  type: REMOVE_ORGANISATION,
  id,
  onSuccess
});

export const INVITE_ORGANISERS = '[ORGANISATION_STORE] Invite new organisers';
export const inviteOrganisers = (id, emails, onSuccess) => ({
  type: INVITE_ORGANISERS,
  id,
  emails,
  onSuccess
});
