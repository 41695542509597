import React from 'react';

import {
  AddAction,
  Button,
  ButtonGroup,
  Form,
  FormRow,
  Input,
  RadioGroup
} from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';
import { generateId } from '@neslotech/utils';

import './checkbox-properties.scss';

const layoutOptions = [
  {
    label: 'Full Width',
    value: true
  },
  {
    label: 'Half Width',
    value: false
  }
];

const requiredOptions = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

const rules = {
  validates: {
    items: ['isPresent']
  }
};

const DEFAULT_ITEMS = [{ id: generateId(), name: '', label: '' }];

const formify = ({ id, type, description, label, filled, required, items }) => ({
  id,
  type,
  description,
  label,
  filled,
  required,
  items: items ?? DEFAULT_ITEMS
});

const serverify = ({ id, type, description, label, filled, required, items }) => ({
  id,
  type,
  description,
  label,
  filled,
  required,
  items
});

const CheckboxProperties = ({ element, onSave, onCancel }) => {
  const [form, setForm] = useFormState(formify(element), rules);

  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleNameChange = (id, value) => {
    const items = form?.items;
    const item = items.find((item) => item.id === id);
    item.name = value.replace(' ', '_').toLowerCase();

    setForm({ ...form, items });
  };

  const handleLabelChange = (id, value) => {
    const items = form?.items;
    const item = items.find((item) => item.id === id);
    item.label = value;

    setForm({ ...form, items });
  };

  const addItem = () => {
    const items = form?.items;
    items.push({ id: generateId(), name: '', label: '' });

    setForm({ ...form, items });
  };

  const handleSave = () => {
    const payload = serverify(form);
    onSave(payload);
  };

  return (
    <article className="checkbox-properties">
      <Form>
        <FormRow>
          <Input
            name="label"
            placeholder="Label Text"
            label="Label Text"
            onChange={handleChange}
            value={form?.label}
          />
        </FormRow>
        <FormRow>
          <Input
            name="description"
            placeholder="Description"
            label="Description"
            onChange={handleChange}
            value={form?.description}
          />
        </FormRow>
        {form?.items.map((item) => (
          <React.Fragment key={item.id}>
            <FormRow>
              <Input
                placeholder="Checkbox Name"
                label="Checkbox Name"
                onChange={(value) => handleNameChange(item.id, value)}
                value={item.name}
              />
            </FormRow>
            <FormRow>
              <Input
                placeholder="Checkbox Label"
                label="Checkbox Label"
                onChange={(value) => handleLabelChange(item.id, value)}
                value={item.label}
              />
            </FormRow>
          </React.Fragment>
        ))}
        <section className="checkbox-properties__add">
          <AddAction title="Add checkbox" onClick={addItem} />
        </section>
        <FormRow>
          <RadioGroup
            name="filled"
            items={layoutOptions}
            onChange={handleChange}
            label="Layout Options"
            value={form?.filled}
          />
        </FormRow>
        <FormRow>
          <RadioGroup
            name="required"
            items={requiredOptions}
            onChange={handleChange}
            label="Required field?"
            value={form?.required}
          />
        </FormRow>
      </Form>
      <ButtonGroup centered>
        <Button label="Save" disabled={!form?.valid} onClick={handleSave} />
        <Button secondary label="Cancel" onClick={onCancel} />
      </ButtonGroup>
    </article>
  );
};

export default CheckboxProperties;
