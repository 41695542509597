import React from 'react';

import { PageLayout } from '@neslotech/eventhub-ui-kit';

import ScheduleProvider from '../../../../providers/Schedule.provider';
import PreviewSchedule from '../../../../components/schedule/preview/PreviewSchedule';
import OrganizerLayout from '../../../../components/layout/organizer/OrganizerLayout';

const PreviewScheduleContainer = () => (
  <OrganizerLayout gutterless>
    <PageLayout back title="Schedule Preview" fixed>
      <ScheduleProvider>
        <PreviewSchedule />
      </ScheduleProvider>
    </PageLayout>
  </OrganizerLayout>
);

export default PreviewScheduleContainer;
