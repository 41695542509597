import React, { useEffect } from 'react';

import { deepClone } from '@neslotech/utils';

import { useFormBuilderContext } from '../../../../hooks/useFormBuilderContext';

import FormBuilder from '../../../form-builder/FormBuilder';

import './entry-form-template.scss';

const EntryFormTemplate = ({ onChange, template, editMode }) => {
  const { sections, onSetSections } = useFormBuilderContext();

  useEffect(() => {
    onChange(sections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);

  useEffect(() => {
    onSetSections(deepClone(template));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  useEffect(() => {
    if (!editMode) {
      onChange();
      onSetSections(deepClone(template));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  return (
    <article className="entry-form-template">
      <FormBuilder editable={editMode} showTitle={false} />
    </article>
  );
};

export default EntryFormTemplate;
