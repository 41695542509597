import React from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { noOp } from '@neslotech/utils';

import { Button, getOrganisationInitials } from '@neslotech/eventhub-ui-kit';

import { ReactComponent as ChevronRightIcon } from '../../../../icons/chevron-right-icon.svg';

import './organisation-selection.scss';

const OrganisationSelectionButton = ({ name, image, domain, onClick }) => (
  <button className="organisation-selection__button" onClick={onClick}>
    <div className="organisation-selection__button-ellipse">
      {image ? <img src={image} alt={name} /> : getOrganisationInitials(name)}
    </div>
    <div className="organisation-selection__button-text">
      <label>{name}</label>
      <small>{domain}</small>
    </div>
    <ChevronRightIcon />
  </button>
);

const OrganisationSelection = ({ organisations, onSelectOrganisation }) => {
  const navigate = useNavigate();

  return (
    <section className="organisation-selection">
      <section className="organisation-selection__organisations">
        {organisations.map((organisation) => (
          <OrganisationSelectionButton
            key={organisation.id}
            name={organisation.name}
            image={organisation.image}
            domain={organisation.domain}
            onClick={() => onSelectOrganisation(organisation.id)}
          />
        ))}
      </section>
      <Button
        label="Create New Organisation"
        longText
        onClick={() => navigate('/organisations/add')}
      />
    </section>
  );
};

OrganisationSelection.defaultProps = {
  organisations: [],
  onSelectOrganisation: noOp
};

OrganisationSelection.propTypes = {
  organisations: arrayOf(
    shape({
      id: number,
      name: string
    })
  ),
  onSelectOrganisation: func
};

export default OrganisationSelection;
