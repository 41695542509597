import React from 'react';
import { useDispatch } from 'react-redux';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import { updateTemplate } from '../../../../actions/template.actions';

import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';

import UpdateTemplate from '../../../../components/competitions/templates/UpdateTemplate';

const UpdateConfigTemplateContainer = ({ template, show, setShowModal, context }) => {
  const dispatch = useDispatch();

  const { id } = useOrganisationContext();

  const onClose = () => setShowModal(false);

  const handleConfirm = () => {
    dispatch(updateTemplate(id, template?.id, template, context));
    onClose();
  };

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout onClose={onClose} title="Update Template">
            <UpdateTemplate onConfirmClick={handleConfirm} onCancelClick={onClose} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default UpdateConfigTemplateContainer;
