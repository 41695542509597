import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, FilterLayout, Loader, PageLayout, RadioGroup } from '@neslotech/eventhub-ui-kit';
import { noOp } from '@neslotech/utils';

import { loadCompetitions } from '../../../actions/competition.actions';
import { useOrganisationContext } from '../../../hooks/useOrganisationContext';

import { ReactComponent as ChevronRightIcon } from '../../../icons/chevron-right-icon.svg';

import OrganizerLayout from '../../../components/layout/organizer/OrganizerLayout';
import CompetitionsList from '../../../components/competitions/CompetitionsList';

const menuItems = (handleChange, filters) => [
  {
    text: 'Sport',
    onClick: noOp,
    suffix: <ChevronRightIcon />,
    expandable: true,
    expandedItems: (
      <RadioGroup
        onChange={handleChange}
        value={filters?.sport}
        name="sport"
        items={[
          { label: 'Motocross', value: 'Motocross' },
          { label: 'Go-Karting', value: 'Go-Karting' }
        ]}
      />
    )
  },
  {
    text: 'National/Regional',
    onClick: noOp,
    suffix: <ChevronRightIcon />,
    expandable: true,
    expandedItems: (
      <RadioGroup
        onChange={handleChange}
        value={filters?.national}
        name="national"
        items={[
          { label: 'National', value: true },
          { label: 'Regional', value: false }
        ]}
      />
    )
  }
];

const CompetitionsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, verified } = useOrganisationContext();

  const competitions = useSelector(({ competition_store }) => competition_store.competitions);
  const stateLoading = useSelector(({ competition_store }) => competition_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadCompetitions(id, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <OrganizerLayout>
      <PageLayout title="All Competitions">
        <FilterLayout
          menuItems={menuItems}
          searchPlaceholder="Search for a competition..."
          actions={
            <Button
              onClick={() => navigate('add')}
              label="Create Competition"
              longText
              disabled={!verified}
            />
          }
        >
          {loading && <Loader filled />}
          {!loading && <CompetitionsList competitions={competitions} verified={verified} />}
        </FilterLayout>
      </PageLayout>
    </OrganizerLayout>
  );
};

export default CompetitionsContainer;
