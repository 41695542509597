import React, { useEffect, useState } from 'react';

import { Button, DetailsSection } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import { useWizardContext } from '../../../hooks/useWizardContext';

import { serverify } from './AddCompetition.helpers';

import BankingDetailsForm from '../../banking-details/BankingDetailsForm';
import CompetitionDetails from './details/CompetitionDetails';
import CompetitionDescription from './details/CompetitionDescription';
import CompetitionClasses from './details/CompetitionClasses';
import CompetitionMembers from './details/CompetitionMembers';

import './add-competition.scss';

const rules = {
  validates: {
    name: ['isPresent'],
    competitionType: ['isPresent'],
    sport: ['isPresent'],
    startDate: ['isPresent'],
    endDate: ['isPresent', 'isAfterStart'],
    entryStartDate: ['isPresent'],
    entryEndDate: ['isPresent', 'isAfterEntryStart'],
    suppliedBankingDetails: ['isTrueOrFalse']
  },

  isTrueOrFalse(value) {
    if (value !== true && value !== false) {
      return 'This is required';
    }
  },

  isAfterStart(value, form) {
    if (value < form.startDate) {
      return 'The end date cannot be before the start date';
    }
  },

  isAfterEntryStart(value, form) {
    if (value < form.entryStartDate) {
      return 'The entry end date cannot be before the entry start date';
    }
  }
};

const bankingDetailsRules = {
  validates: {
    bankAccountType: ['isPresent'],
    bankName: ['isPresent'],
    bankAccountNumber: ['isPresent'],
    branchNumber: ['isPresent', 'branchNumberLength']
  },

  branchNumberLength(value) {
    if (String(value).length !== 6) {
      return 'Branch number must be 6 characters in length';
    }
  }
};

const classesSubtitle =
  'Please list the class(es) and the cost of the class(es) that you would like to include in your event, ' +
  'organisers will be able to select these class(es) when creating a competition and participants will be able to select the ' +
  'predefined class(es) upon entering for an event.';

const membersSubtitle =
  'Please list the members that participants may need to accompany participants to an event, ' +
  'participants will be able to select the predefined members upon entering for an event.';

const AddCompetition = ({ entityKey }) => {
  const { entity, onNextStep } = useWizardContext();
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useFormState(entity.details, rules);
  const [bankingDetailsForm, setBankingDetailsForm] = useFormState(
    entity.details?.bankingDetails,
    bankingDetailsRules
  );

  useEffect(() => {
    if (form.suppliedBankingDetails === false && !bankingDetailsForm) {
      setBankingDetailsForm(entity.details?.bankingDetails ?? {});
    } else if (form.suppliedBankingDetails && !!bankingDetailsForm) {
      setBankingDetailsForm(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankingDetailsForm, form.suppliedBankingDetails]);

  const handleChange = (newState) => setForm({ ...form, ...newState });
  const handleBankingDetailChange = (newState) =>
    setBankingDetailsForm({ ...bankingDetailsForm, ...newState });

  const handleNextStep = () => {
    setLoading(true);
    const payload = serverify(form, bankingDetailsForm);
    onNextStep(entityKey, payload, () => setLoading(false));
  };

  const isBankingDetailFormInvalid = !bankingDetailsForm ? false : !bankingDetailsForm.valid;
  return (
    <>
      <section className="add-competition">
        <DetailsSection
          title="Competition Details"
          subtitle="Please input all specified details for your competition"
        >
          <CompetitionDetails form={form} handleChange={handleChange} />
        </DetailsSection>
        {form.suppliedBankingDetails === false && (
          <DetailsSection title="Add Banking Details">
            <BankingDetailsForm
              entity={bankingDetailsForm}
              handleChange={handleBankingDetailChange}
            />
          </DetailsSection>
        )}
        <DetailsSection title="Competition Description">
          <CompetitionDescription form={form} handleChange={handleChange} />
        </DetailsSection>
        {form.sport && (
          <DetailsSection title="List the classes in the competition" subtitle={classesSubtitle}>
            <CompetitionClasses form={form} handleChange={handleChange} />
          </DetailsSection>
        )}
        <DetailsSection title="Additional Members" subtitle={membersSubtitle}>
          <CompetitionMembers form={form} handleChange={handleChange} />
        </DetailsSection>
      </section>
      <section className="wizard-layout__actions">
        <Button
          loading={loading}
          disabled={!form?.valid || isBankingDetailFormInvalid}
          label="Next Step"
          onClick={handleNextStep}
        />
      </section>
    </>
  );
};

export default AddCompetition;
