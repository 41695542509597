import React from 'react';

import { Card, Form, FormRow, Input, ProfileImage } from '@neslotech/eventhub-ui-kit';

import './organiser-personal-details.scss';

const OrganiserForm = ({ organiser }) => (
  <Form>
    <FormRow fluidSpaced>
      <Input label="First Name" placeholder="First Name" value={organiser?.first_name} disabled />
      <Input label="Last Name" placeholder="Last Name" value={organiser?.last_name} disabled />
    </FormRow>
    <FormRow>
      <Input
        label="Phone Number"
        placeholder="+27 123456789"
        value={organiser?.phone_number}
        disabled
      />
    </FormRow>
  </Form>
);

const OrganiserPersonalDetails = ({ organiser }) => (
  <Card>
    <section className="organiser-personal-details">
      <section className="organiser-personal-details__icon">
        <ProfileImage imgSrc={organiser?.image} />
      </section>
      <section className="organiser-personal-details__form">
        <h4 className="organiser-personal-details__form-title">Personal Details</h4>
        <OrganiserForm organiser={organiser} />
      </section>
    </section>
  </Card>
);

export default OrganiserPersonalDetails;
