export const getDenomination = (index) => {
  if (index >= 11 && index <= 19) {
    return `${index}th place`;
  }

  switch (index % 10) {
    case 1:
      return `${index}st place`;
    case 2:
      return `${index}nd place`;
    case 3:
      return `${index}rd place`;
    default:
      return `${index}th place`;
  }
};

export const DEFAULT_PLACES = [
  { place: getDenomination('1'), points: '' },
  { place: getDenomination('2'), points: '' },
  { place: getDenomination('3'), points: '' },
  { place: getDenomination('4'), points: '' }
];

// stepper constants
export const DETAILS_KEY = 'details';
export const POINTS_CONFIG_KEY = 'points_configuration';
export const ENTRY_FORM_KEY = 'entry_form';
export const PREVIEW_KEY = 'preview';
