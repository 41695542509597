import React from 'react';
import { useDispatch } from 'react-redux';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';
import { cancelCompetition } from '../../../../actions/competition.actions';

import CancelCompetition from '../../../../components/competitions/cancel/CancelCompetition';

const CancelCompetitionContainer = ({ competition, show, setShowModal }) => {
  const dispatch = useDispatch();
  const { id } = useOrganisationContext();

  const onClose = () => setShowModal(false);

  const handleConfirm = (payload) => {
    dispatch(cancelCompetition(id, competition.id, payload));
    onClose();
  };

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout large onClose={onClose} title="Cancel Competition">
            <CancelCompetition
              competition={competition}
              onConfirmClick={handleConfirm}
              onCancelClick={onClose}
            />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default CancelCompetitionContainer;
