import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

import { API_HOST } from './index';

const getOrganisationsEndpoint = () => `${API_HOST}/organisations`;
export const getCreateOrganisationsRequest = (payload) => [
  getOrganisationsEndpoint(),
  getHttpPostOptions(payload)
];

const getOrganisationEndpoint = (id) => `${API_HOST}/organisations/${id}`;
export const getLoadOrganisationRequest = (id) => [
  getOrganisationEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

const getOrganiserEndpoint = (id, organiserId) =>
  `${API_HOST}/organisations/${id}/users/${organiserId}`;
export const getLoadOrganiserRequest = (id, organiserId) => [
  getOrganiserEndpoint(id, organiserId),
  getHttpGetOptions(getAuthHeaders())
];

export const getRemoveOrganiserRequest = (id, organiserId) => [
  getOrganiserEndpoint(id, organiserId),
  getHttpDeleteOptions(getAuthHeaders())
];

const getBankingDetailsEndpoint = (id) => `${API_HOST}/organisations/${id}/banking-details`;
export const getBankingDetailsRequest = (id) => [
  getBankingDetailsEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

const getEditBankingDetailsEndpoint = (id, bankingDetailsId) =>
  `${API_HOST}/organisations/${id}/banking-details/${bankingDetailsId}`;
export const getEditBankingDetailsRequest = (id, bankingDetailsId, payload) => [
  getEditBankingDetailsEndpoint(id, bankingDetailsId),
  getHttpPutOptions(payload, getAuthHeaders())
];

export const getEditOrganisationRequest = (id, payload) => [
  getOrganisationEndpoint(id),
  getHttpPutOptions(payload, getAuthHeaders())
];

export const getRemoveOrganisationRequest = (id) => [
  getOrganisationEndpoint(id),
  getHttpDeleteOptions(getAuthHeaders())
];

const getUsersEndpoint = () => `${API_HOST}/users`;
export const getInviteOrganisersRequest = (id, email) => [
  getUsersEndpoint(),
  getHttpPostOptions({ organisation_id: id, email, role: 'organizer' }, getAuthHeaders())
];
