import React from 'react';

import { Button, ButtonGroup, Form, FormRow, Input } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import './rename-document.scss';

const formify = (document) => ({
  name: document.name.split('.')[0]
});

const rules = {
  validates: {
    name: ['isPresent']
  }
};

const RenameDocument = ({ selectedDocument, onClose, onConfirm }) => {
  const [form, setForm] = useFormState(formify(selectedDocument), rules);
  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleConfirm = () => {
    onConfirm(form.name);
  };

  return (
    <article className="rename-document">
      <p className="rename-document__message">What would you like to name your file?</p>
      <section className="rename-document__form">
        <Form>
          <FormRow>
            <Input
              name="name"
              label="File Name"
              placeholder="Enter file name"
              onChange={handleChange}
              value={form?.name}
              error={form?.messages?.name}
            />
          </FormRow>
          <ButtonGroup>
            <Button label="Confirm" disabled={!form?.valid} onClick={handleConfirm} />
            <Button label="Cancel" onClick={onClose} secondary />
          </ButtonGroup>
        </Form>
      </section>
    </article>
  );
};

export default RenameDocument;
