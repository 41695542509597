import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  APPROVED_STATUS,
  AWAITING_VERIFICATION_STATUS,
  FilterLayout,
  Loader,
  PageLayout,
  PAYMENT_REQUIRED_STATUS,
  RadioGroup
} from '@neslotech/eventhub-ui-kit';
import { noOp } from '@neslotech/utils';

import { downloadEntries, loadEntries } from '../../../actions/entry.actions';

import { useOrganisationContext } from '../../../hooks/useOrganisationContext';

import { ReactComponent as ChevronRightIcon } from '../../../icons/chevron-right-icon.svg';

import OrganizerLayout from '../../../components/layout/organizer/OrganizerLayout';
import EntriesList from '../../../components/entries/EntriesList';

const menuItems = (handleChange, filters) => [
  {
    text: 'Type',
    onClick: noOp,
    suffix: <ChevronRightIcon />,
    expandable: true,
    expandedItems: (
      <RadioGroup
        onChange={handleChange}
        value={filters?.type}
        name="type"
        items={[
          { label: 'Event', value: 'Event' },
          { label: 'Competition', value: 'Competition' }
        ]}
      />
    )
  },
  {
    text: 'Status',
    onClick: noOp,
    suffix: <ChevronRightIcon />,
    expandable: true,
    expandedItems: (
      <RadioGroup
        onChange={handleChange}
        value={filters?.status}
        name="status"
        items={[
          { label: 'Approved', value: APPROVED_STATUS },
          { label: 'Payment Required', value: PAYMENT_REQUIRED_STATUS },
          { label: 'Awaiting Verification', value: AWAITING_VERIFICATION_STATUS }
        ]}
      />
    )
  }
];

const EntriesContainer = () => {
  const dispatch = useDispatch();

  const { id } = useOrganisationContext();

  const entries = useSelector(({ entry_store }) => entry_store.entries);
  const stateLoading = useSelector(({ entry_store }) => entry_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadEntries(id, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onDownload = () => {
    dispatch(downloadEntries(id));
  };

  return (
    <OrganizerLayout>
      <PageLayout title="Manage Entries">
        <FilterLayout searchPlaceholder="Search entries..." menuItems={menuItems}>
          {loading && <Loader filled />}
          {!loading && <EntriesList entries={entries} onDownload={onDownload} />}
        </FilterLayout>
      </PageLayout>
    </OrganizerLayout>
  );
};

export default EntriesContainer;
