import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { toBase64 } from '@neslotech/eventhub-ui-kit';
import { noOp, scrollIntoView } from '@neslotech/utils';

import { DETAILS_KEY, ENTRY_FORM_KEY, POINTS_CONFIG_KEY } from '../../tools/constants';
import {
  formifyDetails,
  formifyEntryForm,
  formifyPointsConfig
} from './CompetitionWizard.provider.helpers';

import { WizardContext } from '../../contexts/Wizard.context';
import { useOrganisationContext } from '../../hooks/useOrganisationContext';

import {
  publishCompetition,
  removeCompetition,
  saveCompetitionDetails,
  saveCompetitionDraft,
  saveCompetitionEntryForm,
  saveCompetitionPointsConfig,
  updateCompetitionDetails
} from '../../actions/competition.actions';
import { loadClassTypes, loadSports } from '../../actions/system.actions';

import WizardLayout from '../../components/layout/wizard/WizardLayout';

const CompetitionWizardProvider = ({ onDuplicateFound, children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useOrganisationContext();

  const [activeStep, setActiveStep] = useState(1);
  const [errorsOnStep, setErrorsOnStep] = useState();
  const [image, setImage] = useState();

  const competition = useSelector(({ competition_store }) => competition_store.competition);
  const sports = useSelector(({ system_store }) => system_store.sports);
  const classTypes = useSelector(({ system_store }) => system_store.classTypes);

  const entity = useMemo(() => {
    return {
      id: competition?.id,
      image: competition?.image,
      details: formifyDetails(competition),
      points_configuration: formifyPointsConfig(competition?.points_configuration),
      entry_form: formifyEntryForm(competition)
    };
  }, [competition]);

  useEffect(() => {
    dispatch(loadSports());
    setImage(entity?.image);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity?.image]);

  const saveDetails = (payload, onComplete) => {
    if (entity?.id) {
      dispatch(
        updateCompetitionDetails(
          id,
          entity.id,
          { ...payload, id: entity.id, image },
          () => {
            onCompleteProcessing();
            onComplete();
          },
          (data) => {
            handleProcessingError(data);
            onComplete();
          }
        )
      );
    } else {
      dispatch(
        saveCompetitionDetails(
          id,
          { ...payload, image },
          () => {
            onCompleteProcessing();
            onComplete();
          },
          (data) => {
            handleProcessingError(data);
            onComplete();
          }
        )
      );
    }
  };

  const savePointsConfig = (payload, onComplete) => {
    dispatch(
      saveCompetitionPointsConfig(
        id,
        entity?.id,
        payload,
        () => {
          onCompleteProcessing();
          onComplete();
        },
        (data) => {
          handleProcessingError(data);
          onComplete();
        }
      )
    );
  };

  const saveEntryForm = (payload, onComplete) => {
    dispatch(
      saveCompetitionEntryForm(
        id,
        entity?.id,
        payload,
        () => {
          onCompleteProcessing();
          onComplete();
        },
        (data) => {
          handleProcessingError(data);
          onComplete();
        }
      )
    );
  };

  const handleProcessingError = (data) => {
    scrollIntoView('stepper');
    setErrorsOnStep(data);
  };

  const onCompleteProcessing = () => {
    scrollIntoView('stepper');
    setActiveStep(activeStep + 1);
  };

  const handleNextStep = (key, payload, onComplete = noOp) => {
    setErrorsOnStep();

    switch (key) {
      default:
      case DETAILS_KEY:
        saveDetails(payload, onComplete);
        break;
      case POINTS_CONFIG_KEY:
        savePointsConfig(payload, onComplete);
        break;
      case ENTRY_FORM_KEY:
        saveEntryForm(payload, onComplete);
        break;
    }
  };

  const handlePreviousStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleStepChange = (index) => {
    setActiveStep(index);
  };

  const handleDiscard = () => {
    dispatch(removeCompetition(id, entity.id, navigate));
  };

  const handleSaveAsDraft = () => {
    dispatch(saveCompetitionDraft(id, entity.id, navigate, handleProcessingError));
  };

  const handlePublish = () => {
    dispatch(publishCompetition(id, entity.id, navigate, handleProcessingError, onDuplicateFound));
  };

  const handleFileChange = async (file) => {
    const base64 = await toBase64(file);
    setImage(base64);
  };

  const handleSportChange = (sport) => {
    dispatch(loadClassTypes(sport.id));
  };

  const value = {
    context: 'competition',
    activeStep,
    entity,
    sports,
    classTypes,
    errors: errorsOnStep,
    onStepChange: handleStepChange,
    onNextStep: handleNextStep,
    onPreviousStep: handlePreviousStep,
    onDiscard: handleDiscard,
    onSaveAsDraft: handleSaveAsDraft,
    onPublish: handlePublish,
    onSetSport: handleSportChange
  };

  return (
    <WizardContext.Provider value={value}>
      <WizardLayout activeStep={activeStep} image={image} handleFileChange={handleFileChange}>
        {children}
      </WizardLayout>
    </WizardContext.Provider>
  );
};

export default CompetitionWizardProvider;
