import React, { useMemo } from 'react';

import { capitalise } from '@neslotech/utils';
import {
  Button,
  ButtonGroup,
  DRAFT_STATUS,
  POINTS_CONFIG_TYPE,
  PUBLISHED_STATUS,
  TextArea
} from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import './save-config-template.scss';

const rules = {
  validates: {
    name: ['isPresent']
  }
};

const serverify = (form) => ({
  name: form.name,
  template_type: POINTS_CONFIG_TYPE
});

const SaveConfigTemplate = ({ onConfirmClick, onCancelClick, status }) => {
  const [form, setForm] = useFormState(undefined, rules);

  const statusContext = useMemo(() => {
    return status === PUBLISHED_STATUS ? 'template' : status;
  }, [status]);

  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleConfirm = () => {
    const payload = serverify(form);
    onConfirmClick(payload);
  };

  const statusCheck = useMemo(() => {
    if (status === DRAFT_STATUS) {
      return (
        <p>
          What would you like to name your points configuration draft? This template will be saved
          in a draft form and will not be able to be used until it has been published.
        </p>
      );
    } else {
      return (
        <p>
          What would you like to name your points configuration? This template will be saved in a
          published form.
        </p>
      );
    }
  }, [status]);

  return (
    <section className="save-config-template">
      <section className="save-config-template__message">{statusCheck}</section>
      <section className="save-config-template__form">
        <TextArea
          label={`Points Configuration ${capitalise(statusContext)} Name`}
          shadowed
          name="name"
          rows={3}
          placeholder={`Enter point configuration ${statusContext} name`}
          onChange={handleChange}
          value={form?.name}
        />
      </section>
      <ButtonGroup>
        <Button label="Confirm" disabled={!form?.valid} onClick={handleConfirm} />
        <Button label="Cancel" onClick={onCancelClick} secondary />
      </ButtonGroup>
    </section>
  );
};

export default SaveConfigTemplate;
