import { getHttpGetOptions, getHttpPatchOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

import { API_HOST } from './index';

const getEntriesEndpoint = (id) => `${API_HOST}/organisations/${id}/entries`;
export const getLoadEntriesRequest = (id) => [
  getEntriesEndpoint(id),
  getHttpGetOptions({ ...getAuthHeaders(), Accept: 'application/json' })
];

const getEntriesCsvEndpoint = (id) => `${getEntriesEndpoint(id)}.csv`;
export const getDownloadEntriesRequest = (id) => [
  getEntriesCsvEndpoint(id),
  { ...getHttpGetOptions({ ...getAuthHeaders(), Accept: 'text/csv' }), responseType: 'blob' }
];

const getEntryEndpoint = (id, entryId) => `${API_HOST}/organisations/${id}/entries/${entryId}`;
export const getLoadEntryRequest = (id, entryId) => [
  getEntryEndpoint(id, entryId),
  getHttpGetOptions(getAuthHeaders())
];

const getRejectEntryEndpoint = (id, entryId) =>
  `${API_HOST}/organisations/${id}/entries/${entryId}/reject`;
export const getRejectEntryRequest = (id, entryId, payload) => [
  getRejectEntryEndpoint(id, entryId),
  getHttpPatchOptions(payload, getAuthHeaders())
];

const getAcceptEntryEndpoint = (id, entryId) =>
  `${API_HOST}/organisations/${id}/entries/${entryId}/accept`;
export const getAcceptEntryRequest = (id, entryId) => [
  getAcceptEntryEndpoint(id, entryId),
  getHttpPatchOptions(undefined, getAuthHeaders())
];
