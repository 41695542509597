import React from 'react';

import { Button } from '@neslotech/eventhub-ui-kit';

import { ReactComponent as DefaultCardImage } from '../../images/default-card-image.svg';

import './event-card.scss';

const EventCard = ({ entity, type, onClick }) => (
  <section className="event-card">
    <div className="event-card__image">
      {entity.image ? <img src={entity.image} alt={entity.name} /> : <DefaultCardImage />}
    </div>
    <div className="event-card__details">
      <div className="event-card__event-name">
        <h6>{entity.name}</h6>
        <h6>{entity.start_date}</h6>
      </div>
      <small>{entity.description}</small>
    </div>
    <div className="event-card__action">
      <Button label={`View ${type}`} tertiary onClick={onClick} />
    </div>
  </section>
);

export default EventCard;
