import React from 'react';

import AuthLayout from '../../../components/layout/auth/AuthLayout';
import Success from '../../../components/auth/success/Success';

const SuccessContainer = () => (
  <AuthLayout noLogo>
    <Success />
  </AuthLayout>
);

export default SuccessContainer;
