import React, { createRef, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  AddAction,
  Button,
  DetailsSection,
  DocumentDetail,
  FileUpload,
  FileUploadGroup,
  Modal,
  ModalLayout,
  toBase64
} from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';
import { generateId } from '@neslotech/utils';

import { serverify } from './AddEvent.helpers';

import {
  loadCompetition,
  loadCompetitionClasses,
  loadCompetitionMemberTypes
} from '../../../actions/competition.actions';

import { useOrganisationContext } from '../../../hooks/useOrganisationContext';
import { useWizardContext } from '../../../hooks/useWizardContext';

import EventDetailsRequirements from './details/requirements/EventDetailsRequirements';
import EventDetails from './details/EventDetails';
import EventDescription from './details/EventDescription';
import EventClasses from './details/EventClasses';
import EventMembers from './details/EventMembers';
import BankingDetailsForm from '../../banking-details/BankingDetailsForm';
import RenameDocument from './details/document/RenameDocument';

import './add-event.scss';

const rules = {
  validates: {
    type: ['isPresent'],
    name: ['isPresent'],
    startDate: ['isPresent'],
    endDate: ['isPresent', 'isAfterStart'],
    entryStartDate: ['isPresent'],
    entryEndDate: ['isPresent', 'isAfterEntryStart'],
    startTime: ['isPresent'],
    endTime: ['isPresent', 'isAfterStartTime'],
    suppliedBankingDetails: ['isTrueOrFalse']
  },

  isTrueOrFalse(value) {
    if (value !== true && value !== false) {
      return 'This is required';
    }
  },

  isAfterStart(value, form) {
    if (value < form.startDate) {
      return 'The end date cannot be before the start date';
    }
  },

  isAfterEntryStart(value, form) {
    if (value < form.entryStartDate) {
      return 'The entry end date cannot be before the entry start date';
    }
  },

  isAfterStartTime(value, form) {
    if (value < form.startTime) {
      return 'The end time cannot be before the start time';
    }
  }
};

const bankingDetailsRules = {
  validates: {
    bankAccountType: ['isPresent'],
    bankName: ['isPresent'],
    bankAccountNumber: ['isPresent'],
    branchNumber: ['isPresent', 'branchNumberLength']
  },

  branchNumberLength(value) {
    if (String(value).length !== 6) {
      return 'Branch number must be 6 characters in length';
    }
  }
};

const documentsMessage =
  'Upload supporting documents. Note: This will be visible under your event details.';

const classesMessage =
  'Please select the class(es) predefined from your competition that you would like to be listed in your event. ' +
  'A participant will be able to select these class(es) upon entering for an event.';

const membersMessage =
  'Please select the members that participants may need to accompany participants to an event, participants ' +
  'will be able to select the predefined members upon entering for an event.';

export const LINK = 'Link Event';
export const STANDALONE = 'Standalone Event';

const AddEvent = ({ entityKey }) => {
  const dispatch = useDispatch();
  const { competitionId } = useParams();

  const { id } = useOrganisationContext();
  const { entity, onNextStep } = useWizardContext();

  const [form, setForm] = useFormState(entity.details, rules);
  const [bankingDetailsForm, setBankingDetailsForm] = useFormState(
    entity.details?.bankingDetails,
    bankingDetailsRules
  );
  const [selectedDocument, setSelectedDocument] = useState();
  const [loading, setLoading] = useState(false);

  const logoRef = createRef();
  const documentRef = createRef();

  const handleChange = (newState) => setForm({ ...form, ...newState });
  const handleBankingDetailChange = (newState) =>
    setBankingDetailsForm({ ...bankingDetailsForm, ...newState });

  useEffect(() => {
    if (competitionId) {
      handleChange({ type: LINK, competition: { id: competitionId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitionId]);

  useEffect(() => {
    if (form?.competition?.id) {
      dispatch(loadCompetition(id, form?.competition?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.competition?.id]);

  useEffect(() => {
    if (form.suppliedBankingDetails === false && !bankingDetailsForm) {
      setBankingDetailsForm(entity.details?.bankingDetails ?? {});
    } else if (form.suppliedBankingDetails && !!bankingDetailsForm) {
      setBankingDetailsForm(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankingDetailsForm, form.suppliedBankingDetails]);

  const displayDetailsSections = useMemo(() => {
    return !!((form?.type === LINK && form?.competition) || form?.type === STANDALONE);
  }, [form?.type, form?.competition]);

  useEffect(() => {
    if (form?.competition) {
      dispatch(loadCompetitionClasses(id, form.competition.id));
      dispatch(loadCompetitionMemberTypes(id, form.competition.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.competition]);

  const handleNextStep = () => {
    setLoading(true);
    const payload = serverify(form, bankingDetailsForm);
    onNextStep(entityKey, payload, () => setLoading(false));
  };

  const onLogoChange = async (file) => {
    const base64 = await toBase64(file);
    handleChange({
      additionalLogos: [...form.additionalLogos, { id: generateId(), name: file.name, src: base64 }]
    });
    logoRef.current.reset();
  };

  const onDocumentChange = async (file) => {
    const base64 = await toBase64(file);
    handleChange({
      supportingDocuments: [
        ...form.supportingDocuments,
        { id: generateId(), name: file.name, src: base64 }
      ]
    });
    documentRef.current.reset();
  };

  const handleRemoveLogo = (id) => {
    handleChange({ additionalLogos: form.additionalLogos.filter((logo) => logo.id !== id) });
  };

  const handleRemoveDocument = (id) => {
    handleChange({
      supportingDocuments: form.supportingDocuments.filter((logo) => logo.id !== id)
    });
  };

  const handleNameChange = (updatedName) => {
    const reduceDocument = (accum, document) => {
      if (document.id === selectedDocument?.id) {
        document.name = `${updatedName}.${document.name.split('.')[1]}`;
      }

      accum.push(document);
      return accum;
    };

    const logos = form.additionalLogos.reduce(reduceDocument, []);
    const documents = form.supportingDocuments.reduce(reduceDocument, []);
    handleChange({ additionalLogos: logos, supportingDocuments: documents });
    setSelectedDocument(undefined);
  };

  const isBankingDetailFormInvalid = !bankingDetailsForm ? false : !bankingDetailsForm.valid;
  return (
    <>
      <section className="add-event">
        {!competitionId && <EventDetailsRequirements form={form} handleChange={handleChange} />}
        {displayDetailsSections && (
          <>
            <DetailsSection title="Event Details">
              <EventDetails form={form} handleChange={handleChange} />
            </DetailsSection>
            {form.suppliedBankingDetails === false && (
              <DetailsSection title="Add Banking Details">
                <BankingDetailsForm
                  entity={bankingDetailsForm}
                  handleChange={handleBankingDetailChange}
                />
              </DetailsSection>
            )}
            <DetailsSection title="Event Description">
              <EventDescription form={form} handleChange={handleChange} />
            </DetailsSection>
            <section className="add-event__documents">
              {form.additionalLogos.map((logo) => (
                <DocumentDetail
                  key={logo.id}
                  name={logo.name}
                  onRename={() => setSelectedDocument(logo)}
                  onRemove={() => handleRemoveLogo(logo.id)}
                />
              ))}
              <FileUpload fileRef={logoRef} onChange={onLogoChange}>
                <AddAction title="Add Event Logo" onClick={() => logoRef.current.click()} />
              </FileUpload>
            </section>
            <DetailsSection title="Upload Documents" subtitle={documentsMessage}>
              <FileUploadGroup
                description="Select a file to upload"
                label=""
                fileRef={documentRef}
                fileTypes={['application/pdf']}
                onClick={() => documentRef.current.click()}
                onChange={onDocumentChange}
              />
            </DetailsSection>
            <section className="add-event__documents">
              {form.supportingDocuments.map((document) => (
                <DocumentDetail
                  key={document.id}
                  name={document.name}
                  onRename={() => setSelectedDocument(document)}
                  onRemove={() => handleRemoveDocument(document.id)}
                />
              ))}
            </section>
            <DetailsSection title="Select classes for this event" subtitle={classesMessage}>
              <EventClasses form={form} handleChange={handleChange} />
            </DetailsSection>
            <DetailsSection title="Additional Members" subtitle={membersMessage}>
              <EventMembers form={form} handleChange={handleChange} />
            </DetailsSection>
          </>
        )}
      </section>
      <section className="wizard-layout__actions">
        <Button
          loading={loading}
          disabled={!form?.valid || isBankingDetailFormInvalid}
          label="Next Step"
          onClick={handleNextStep}
        />
      </section>
      {!!selectedDocument && (
        <Modal>
          <ModalLayout
            large
            onClose={() => setSelectedDocument(undefined)}
            title="Rename your file"
          >
            <RenameDocument
              selectedDocument={selectedDocument}
              onClose={() => setSelectedDocument(undefined)}
              onConfirm={handleNameChange}
            />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default AddEvent;
