import React, { useMemo } from 'react';

import { ContextCard } from '@neslotech/eventhub-ui-kit';
import { getClassNames } from '@neslotech/utils';

import { ReactComponent as DefaultImage } from '../../../images/details-default-image.svg';

import './wizard-layout.scss';

const WizardLayout = ({ image, children, activeStep, handleFileChange }) => {
  const isStepWithImage = useMemo(() => {
    return activeStep === 1 || activeStep === 4;
  }, [activeStep]);

  const canUploadImage = useMemo(() => {
    return activeStep === 1;
  }, [activeStep]);

  const isFullStep = useMemo(() => {
    return activeStep === 3;
  }, [activeStep]);

  return (
    <section className="wizard-layout">
      {isStepWithImage && (
        <aside className="wizard-layout__context">
          <ContextCard
            editMode={canUploadImage}
            onFileChange={handleFileChange}
            image={image ? <img src={image} alt="New upload" /> : <DefaultImage />}
            alt="Uploaded Image"
          />
        </aside>
      )}
      <section
        className={getClassNames('wizard-layout__content', {
          noMargin: !isStepWithImage,
          full: isFullStep
        })}
      >
        {children}
      </section>
    </section>
  );
};

export default WizardLayout;
