import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Accordion,
  Button,
  ButtonGroup,
  DRAFT_STATUS,
  Form,
  FormRow,
  NONE,
  PUBLISHED_STATUS,
  SAME_AS_COMPETITION,
  Select,
  USE_TEMPLATE,
  YES
} from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import { formify, serverify, serverifyTemplate } from './EntryForm.helper';

import { useWizardContext } from '../../../../hooks/useWizardContext';
import { useFormBuilderContext } from '../../../../hooks/useFormBuilderContext';
import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';

import { loadEntryFormTemplates } from '../../../../actions/template.actions';
import { loadCompetitionEntryForm } from '../../../../actions/competition.actions';

import SaveEntryFormTemplateContainer from '../../../../containers/organizer/competitions/templates/SaveEntryFormTemplate.container';
import UpdateEntryFormTemplateContainer from '../../../../containers/organizer/competitions/templates/UpdateEntryFormTemplate.container';

import FormBuilder from '../../../form-builder/FormBuilder';

import './entry-form.scss';

const rules = {
  validates: {
    required: ['isPresent']
  }
};

const Requirements = ({ templates, form, handleChange }) => {
  const { onSetSections, onActivateSection } = useFormBuilderContext();

  const templateItems = useMemo(() => {
    return templates.map((template) => ({
      text: template.name,
      value: template.name,
      onClick: () => {
        const data = template.data;
        handleChange({
          templateId: template.id,
          template: template.name,
          entryForm: data
        });
        onSetSections(data);
        onActivateSection(data[0]);
      }
    }));
  }, [handleChange, onActivateSection, onSetSections, templates]);

  return (
    <Form>
      <FormRow>
        <Select
          name="required"
          placeholder="Select an option that suits your competitions needs"
          label="Do you require an entry form?"
          value={form?.required}
          items={[
            {
              text: YES,
              value: YES,
              onClick: () => {
                handleChange({
                  required: YES,
                  template: undefined,
                  templateId: undefined
                });
                onSetSections([]);
              }
            },
            {
              text: NONE,
              value: NONE,
              onClick: () => {
                handleChange({
                  required: NONE,
                  template: undefined,
                  templateId: undefined,
                  entryForm: undefined
                });
                onSetSections([]);
              }
            },
            {
              text: SAME_AS_COMPETITION,
              value: SAME_AS_COMPETITION,
              onClick: () => {
                handleChange({
                  required: SAME_AS_COMPETITION,
                  template: undefined,
                  templateId: undefined
                });
                onSetSections([]);
              }
            },
            {
              text: USE_TEMPLATE,
              value: USE_TEMPLATE,
              onClick: () => {
                handleChange({
                  required: USE_TEMPLATE,
                  template: undefined,
                  templateId: undefined
                });
                onSetSections([]);
              }
            }
          ]}
        />
      </FormRow>
      {form.required === USE_TEMPLATE && (
        <FormRow>
          <Select
            name="template"
            placeholder="Select one of your saved templates"
            label="Choose template"
            value={form?.template}
            items={templateItems}
          />
        </FormRow>
      )}
    </Form>
  );
};

const EntryForm = ({ entityKey }) => {
  const dispatch = useDispatch();

  const { id } = useOrganisationContext();
  const { context, entity, onNextStep } = useWizardContext();
  const { onSetSections, onCloseSelector } = useFormBuilderContext();

  const [form, setForm] = useFormState(formify(entity.entry_form), rules);
  const [template, setTemplate] = useState();
  const [templateToUpdate, setTemplateToUpdate] = useState();

  const templates = useSelector(({ template_store }) => template_store.entryFormTemplates);

  useEffect(() => {
    if (form?.required === USE_TEMPLATE) {
      dispatch(loadEntryFormTemplates(id));
    } else if (form?.required === SAME_AS_COMPETITION) {
      dispatch(
        loadCompetitionEntryForm(id, entity.details.competition_id, (entryForm) => {
          handleChange({ entryForm });
          onSetSections(entryForm);
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, form?.required]);

  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleNextStep = () => {
    const payload = serverify(form);
    onNextStep(entityKey, payload);
  };

  const handleDiscard = () => {
    const updatedEntity = {
      ...entity,
      entryForm: undefined,
      required: undefined,
      template: undefined,
      templateId: undefined
    };
    onSetSections([]);
    setForm(formify(updatedEntity));
    onCloseSelector();
  };

  const handleSaveTemplateAsDraft = () => {
    const payload = serverifyTemplate(form, DRAFT_STATUS);
    setTemplate(payload);
  };

  const handleSaveTemplate = () => {
    const payload = serverifyTemplate(form, PUBLISHED_STATUS);
    setTemplate(payload);
  };

  const handleUpdateTemplate = () => {
    const payload = serverifyTemplate(form, PUBLISHED_STATUS);
    setTemplateToUpdate(payload);
  };

  const shouldShowBuilder =
    form.required === YES || (form?.required === USE_TEMPLATE && form?.templateId);
  const formValid = form?.valid && (form.entryForm ?? []).every((item) => !!item.type);

  return (
    <article className="add-entry-form">
      <Accordion title="Entry Form Requirements" open>
        <section className="add-entry-form__requirements">
          <Requirements templates={templates} form={form} handleChange={handleChange} />
        </section>
      </Accordion>
      <section className="add-entry-form__content">
        {shouldShowBuilder && (
          <>
            <h5 className="add-entry-form__title">Start Building Your Entry Form</h5>
            <p className="add-entry-form__subtitle">
              Click on add new section to start building your entry form. Then click on the widgets
              in the left hand panel. You can add multiple sections as you go.
            </p>
            <FormBuilder handleChange={handleChange} />
          </>
        )}
        <section className="add-entry-form__actions">
          {form?.required && (
            <>
              <ButtonGroup fluid spaced>
                {(shouldShowBuilder ||
                  form?.required === NONE ||
                  form?.required === SAME_AS_COMPETITION) && (
                  <Button disabled={!formValid} label="Next Step" onClick={handleNextStep} />
                )}
                {form?.required === YES && (
                  <Button
                    disabled={!formValid}
                    label="Save Template as Draft"
                    longText
                    hollow
                    onClick={handleSaveTemplateAsDraft}
                  />
                )}
                {form?.required === USE_TEMPLATE && form?.templateId && (
                  <Button
                    hollow
                    disabled={!formValid}
                    label="Update Template"
                    onClick={handleUpdateTemplate}
                  />
                )}
                {(form?.required === YES ||
                  (form?.required === USE_TEMPLATE && form?.templateId)) && (
                  <Button
                    hollow
                    longText
                    disabled={!formValid}
                    label="Save as New Template"
                    onClick={handleSaveTemplate}
                  />
                )}
              </ButtonGroup>
              {shouldShowBuilder && (
                <Button label="Clear Page" secondaryDanger onClick={handleDiscard} />
              )}
            </>
          )}
        </section>
      </section>
      <SaveEntryFormTemplateContainer
        context={context}
        template={template}
        setShowModal={setTemplate}
        show={template}
      />
      <UpdateEntryFormTemplateContainer
        context={context}
        templateId={templateToUpdate?.id}
        template={templateToUpdate}
        setShowModal={setTemplateToUpdate}
        show={templateToUpdate}
      />
    </article>
  );
};

export default EntryForm;
