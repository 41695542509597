import React from 'react';

import { Card, Form, FormRow, Input } from '@neslotech/eventhub-ui-kit';
import { capitalise } from '@neslotech/utils';

import './organiser-account-details.scss';

const OrganiserAccountDetails = ({ organiser }) => (
  <Card>
    <section className="organiser-account-details">
      <header>
        <h4>Account Details</h4>
      </header>
      <Form>
        <FormRow fluid>
          <Input
            placeholder="Example@eventhub.co.za"
            label="Email Address"
            value={organiser?.email}
            disabled
          />
          <Input
            placeholder="Organiser"
            label="User Role"
            value={capitalise(organiser?.role)}
            disabled
          />
        </FormRow>
        <FormRow>
          <Input
            placeholder="User Status"
            label="User Status"
            value={capitalise(organiser?.status)}
            disabled
          />
        </FormRow>
      </Form>
    </section>
  </Card>
);

export default OrganiserAccountDetails;
