import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Checkbox, Input, Table } from '@neslotech/eventhub-ui-kit';

import '../add-event.scss';

export const tableHeaders = [
  { Header: '', accessor: 'selected', disableSortBy: true },
  { Header: 'Class Name', accessor: 'name' },
  { Header: '1st Class', accessor: 'firstClassPrice' },
  { Header: '2nd Class', accessor: 'secondClassPrice' },
  { Header: '1st Class (Family Member)', accessor: 'firstClassFamilyPrice' },
  { Header: '2nd Class (Family Member)', accessor: 'secondClassFamilyPrice' }
];

const EventClasses = ({ form, handleChange }) => {
  const classes = useSelector(({ competition_store }) => competition_store.classes);

  const mappedData = useMemo(() => {
    const handleValueChange = (allocation, value) => {
      const matchingClass = classes.find((klass) => klass.id === allocation.id);
      let formClass = form.classes.find((klass) => klass.competitionClassId === matchingClass.id);
      if (formClass) {
        formClass = {
          ...formClass,
          selected: !formClass.selected
        };
        const index = form.classes.findIndex(
          (klass) => klass.competitionClassId === matchingClass.id
        );
        form.classes[index] = formClass;
      } else {
        formClass = {
          competitionClassId: matchingClass.id,
          ...value
        };
        form.classes.push(formClass);
      }

      handleChange({ classes: form.classes });
    };

    return (classes ?? []).map((allocation) => {
      const klass = form.classes.find((klass) => klass.competitionClassId === allocation.id);
      return {
        selected: (
          <Checkbox
            name="selected"
            checked={klass?.selected}
            onChange={(val) => handleValueChange(allocation, val)}
          />
        ),
        name: (
          <Input borderless name="name" placeholder="MX50cc" value={allocation.name} disabled />
        ),
        firstClassPrice: (
          <Input
            borderless
            name="firstClassPrice"
            placeholder="850"
            value={allocation.first_class_price}
            disabled
          />
        ),
        secondClassPrice: (
          <Input
            borderless
            name="secondClassPrice"
            placeholder="850"
            value={allocation.second_class_price}
            disabled
          />
        ),
        firstClassFamilyPrice: (
          <Input
            borderless
            name="firstClassFamilyPrice"
            placeholder="850"
            value={allocation.first_class_family_price}
            disabled
          />
        ),
        secondClassFamilyPrice: (
          <Input
            borderless
            name="secondClassFamilyPrice"
            placeholder="850"
            value={allocation.second_class_family_price}
            disabled
          />
        )
      };
    });
  }, [form?.classes, handleChange, classes]);

  return (
    <section className="add-event-classes">
      <Table cols={tableHeaders} rowData={mappedData} />
    </section>
  );
};

export default EventClasses;
