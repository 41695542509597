import {
  CLEAR_COMPETITION,
  SET_COMPETITION,
  SET_COMPETITION_CLASSES,
  SET_COMPETITION_MEMBER_TYPES,
  SET_COMPETITION_POINTS_CONFIGURATION,
  SET_COMPETITIONS
} from '../actions/competition.actions';

export const initialState = {
  competitions: [],
  competition: undefined,
  pointsConfiguration: undefined,
  classes: [],
  memberTypes: []
};

export const competitionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPETITIONS:
      const { competitions } = action;
      return {
        ...state,
        competitions
      };
    case SET_COMPETITION:
      const { competition } = action;
      return {
        ...state,
        competition
      };
    case SET_COMPETITION_CLASSES:
      const { classes } = action;
      return {
        ...state,
        classes
      };
    case SET_COMPETITION_MEMBER_TYPES:
      const { memberTypes } = action;
      return {
        ...state,
        memberTypes
      };
    case SET_COMPETITION_POINTS_CONFIGURATION:
      const { pointsConfiguration } = action;
      return {
        ...state,
        pointsConfiguration
      };
    case CLEAR_COMPETITION:
      return {
        ...state,
        competition: undefined
      };
    default:
      return state;
  }
};
