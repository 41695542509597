import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { register } from '../../../actions/auth.actions';

import AuthLayout from '../../../components/layout/auth/AuthLayout';
import Register from '../../../components/auth/register/Register';

const RegisterContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onRegister = (payload) => {
    dispatch(register(payload, navigate));
  };

  return (
    <AuthLayout title="Register below to continue">
      <Register onRegister={onRegister} />
    </AuthLayout>
  );
};

export default RegisterContainer;
