import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader, PageLayout } from '@neslotech/eventhub-ui-kit';

import {
  DETAILS_KEY,
  ENTRY_FORM_KEY,
  POINTS_CONFIG_KEY,
  PREVIEW_KEY
} from '../../../../tools/constants';

import { clearEvent } from '../../../../actions/event.actions';
import { clearCompetition, loadCompetitions } from '../../../../actions/competition.actions';

import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';
import FormBuilderProvider from '../../../../providers/FormBuilder.provider';
import EventWizardProvider from '../../../../providers/wizard/EventWizard.provider';

import OrganizerLayout from '../../../../components/layout/organizer/OrganizerLayout';
import Wizard from '../../../../components/wizard/Wizard';
import PointsConfiguration from '../../../../components/events/add/points-configuration/PointsConfiguration';
import EntryForm from '../../../../components/events/add/entry-form/EntryForm';
import Preview from '../../../../components/events/add/preview/Preview';
import AddEvent from '../../../../components/events/add/AddEvent';

import DuplicateEventContainer from '../duplicate/DuplicateEvent.container';

const steps = [
  {
    key: DETAILS_KEY,
    index: 1,
    label: 'Create an event',
    component: AddEvent
  },
  {
    key: POINTS_CONFIG_KEY,
    index: 2,
    label: 'Create points configuration',
    component: PointsConfiguration
  },
  {
    key: ENTRY_FORM_KEY,
    index: 3,
    label: 'Create entry form',
    component: EntryForm
  },
  {
    key: PREVIEW_KEY,
    index: 4,
    label: 'Preview',
    component: Preview
  }
];

const AddEventContainer = () => {
  const dispatch = useDispatch();

  const { id } = useOrganisationContext();

  const stateLoading = useSelector(({ event_store }) => event_store.loading);
  const competition = useSelector(({ competition_store }) => competition_store.competition);
  const [loading, setLoading] = useState(stateLoading ?? true);

  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [duplicateCompId, setDuplicateCompId] = useState();
  const [duplicateEventId, setDuplicateEventId] = useState();

  const subtitle = useMemo(() => {
    return competition && `Linked to: ${competition.name}`;
  }, [competition]);

  useEffect(() => {
    dispatch(clearEvent());
    dispatch(loadCompetitions(id, () => setLoading(false)));

    return dispatch(clearCompetition());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleDuplicate = (competitionId, eventId) => {
    setDuplicateCompId(competitionId);
    setDuplicateEventId(eventId);
    setShowDuplicateModal(true);
  };

  return (
    <>
      <OrganizerLayout gutterless>
        <PageLayout back title="Create New Event" subtitle={subtitle} fixed>
          <EventWizardProvider onDuplicateFound={handleDuplicate}>
            <FormBuilderProvider>
              {loading && <Loader filled />}
              {!loading && <Wizard steps={steps} />}
            </FormBuilderProvider>
          </EventWizardProvider>
        </PageLayout>
      </OrganizerLayout>
      <DuplicateEventContainer
        competitionId={duplicateCompId}
        eventId={duplicateEventId}
        setShowModal={setShowDuplicateModal}
        show={showDuplicateModal}
      />
    </>
  );
};

export default AddEventContainer;
