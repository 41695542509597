export const serverify = (form, bankingDetailsForm) => ({
  name: form?.name,
  competition_type: form?.competitionType,
  start_date: form?.startDate,
  end_date: form?.endDate,
  entry_start_date: form?.entryStartDate,
  entry_end_date: form?.entryEndDate,
  sport_id: form?.sportId,
  supplied_banking_details: form?.suppliedBankingDetails,
  national: form?.national,
  visible_entries: form?.visibleEntries,
  paid: form?.paid,
  regulated: form?.regulated,
  description: form?.description,
  registration_fee: form?.registrationFee,
  regulation_number: form?.regulationNumber,
  banking_detail_attributes: serverifyBankingDetails(form, bankingDetailsForm),
  classes_attributes: serverifyClasses(form?.classes ?? []),
  member_types_attributes: servifyMembers(form?.members ?? [])
});

const servifyMembers = (form) => {
  return form
    .filter((item) => !!item.name && !!item.price)
    .map((item) => ({
      id: item.id,
      name: item.name,
      price: item.price,
      limit: item.limit,
      _destroy: item._destroy
    }));
};

const serverifyClasses = (form) => {
  return form
    .filter((item) => !!item.name && !!item.firstClassPrice && !!item.secondClassPrice)
    .map((item) => ({
      id: item.id,
      class_type_id: item.classTypeId,
      first_class_price: item.firstClassPrice,
      second_class_price: item.secondClassPrice,
      first_class_family_price: item.firstClassFamilyPrice,
      second_class_family_price: item.secondClassFamilyPrice,
      _destroy: item._destroy
    }));
};

const serverifyBankingDetails = (form, bankingDetailsForm) => {
  if (form.suppliedBankingDetails) {
    return undefined;
  }

  return {
    id: bankingDetailsForm?.id,
    account_type: bankingDetailsForm?.bankAccountType,
    account_number: bankingDetailsForm?.bankAccountNumber,
    bank_name: bankingDetailsForm?.bankName,
    branch_number: bankingDetailsForm?.branchNumber
  };
};
