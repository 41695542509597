import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader, PageLayout } from '@neslotech/eventhub-ui-kit';

import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';
import { editOrganisation, loadOrganisation } from '../../../../actions/organisation.actions';

import OrganizerLayout from '../../../../components/layout/organizer/OrganizerLayout';
import OrganisationDetails from '../../../../components/organisation/details/OrganisationDetails';
import DeleteOrganisationContainer from '../delete/DeleteOrganisation.container';

const OrganisationDetailsContainer = () => {
  const dispatch = useDispatch();
  const { id } = useOrganisationContext();

  const organisation = useSelector(({ organisation_store }) => organisation_store.organisation);
  const stateLoading = useSelector(({ organisation_store }) => organisation_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);
  const [showModal, setShowModal] = useState(false);

  const handleSave = (payload, onSuccess) => {
    dispatch(editOrganisation(id, payload, onSuccess));
  };

  useEffect(() => {
    dispatch(loadOrganisation(id, () => setLoading(false)));
  }, [dispatch, id]);

  return (
    <>
      <OrganizerLayout gutterless>
        <PageLayout title="View Organisation Details" back fixed>
          {loading && <Loader filled />}
          {!loading && organisation && (
            <OrganisationDetails
              organisation={organisation}
              onSave={handleSave}
              setShowModal={setShowModal}
            />
          )}
        </PageLayout>
      </OrganizerLayout>
      <DeleteOrganisationContainer
        organisationId={id}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default OrganisationDetailsContainer;
