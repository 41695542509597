import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PageLayout } from '@neslotech/eventhub-ui-kit';

import ScheduleProvider from '../../../../providers/Schedule.provider';

import Schedule from '../../../../components/schedule/Schedule';
import OrganizerLayout from '../../../../components/layout/organizer/OrganizerLayout';

const AddScheduleContainer = () => {
  const event = useSelector(({ event_store }) => event_store.event);

  const subtitle = useMemo(() => {
    return event && `Linked to: ${event.name}`;
  }, [event]);

  return (
    <OrganizerLayout gutterless>
      <PageLayout back title="Create Schedule" subtitle={subtitle} fixed>
        <ScheduleProvider>
          <Schedule />
        </ScheduleProvider>
      </PageLayout>
    </OrganizerLayout>
  );
};

export default AddScheduleContainer;
