import React from 'react';
import ReactDOM from 'react-dom';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import 'react-datepicker/dist/react-datepicker.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

import authSaga from './sagas/auth.saga';
import organisationSaga from './sagas/organisation.saga';
import profileSaga from './sagas/profile.saga';
import competitionSaga from './sagas/competition.saga';
import eventSaga from './sagas/event.saga';
import templateSaga from './sagas/template.saga';
import entrySaga from './sagas/entry.saga';
import systemSaga from './sagas/system.saga';

import rootReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(authSaga);
sagaMiddleware.run(organisationSaga);
sagaMiddleware.run(profileSaga);
sagaMiddleware.run(competitionSaga);
sagaMiddleware.run(eventSaga);
sagaMiddleware.run(templateSaga);
sagaMiddleware.run(entrySaga);
sagaMiddleware.run(systemSaga);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
