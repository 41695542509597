import React, { useState } from 'react';

import { Button, DetailsSection, Form, FormRow, Input, Select } from '@neslotech/eventhub-ui-kit';

import { capitalise, getClassNames } from '@neslotech/utils';
import { useFormState } from '@neslotech/hooks';

import { ReactComponent as EditIcon } from '../../../icons/blue-edit-icon.svg';

import './banking-details.scss';

const rules = {
  validates: {
    accountType: ['isPresent'],
    accountNumber: ['isPresent', 'isNumeric', 'isAccountNumValid'],
    bankName: ['isPresent'],
    branchNumber: ['isPresent', 'isNumeric', 'isBranchNumValid']
  },
  isAccountNumValid(value) {
    if (value.length < 10 || value.length > 12) {
      return 'Account number is invalid';
    }
  },
  isBranchNumValid(value) {
    if (String(value).length !== 6) {
      return 'Branch number is invalid';
    }
  }
};

const formify = (bankingDetail) => {
  return {
    accountType: bankingDetail?.account_type,
    accountNumber: bankingDetail?.account_number,
    bankName: bankingDetail?.bank_name,
    branchNumber: bankingDetail?.branch_number
  };
};

const serverify = ({ accountType, accountNumber, bankName, branchNumber }) => ({
  account_type: accountType,
  account_number: accountNumber,
  bank_name: bankName,
  branch_number: branchNumber
});

const BankingDetailsForm = ({ form, handleChange, disabled }) => {
  const accountTypes = [
    {
      text: 'Cheque',
      value: 'cheque',
      onClick: () => handleChange({ accountType: 'cheque' })
    },
    {
      text: 'Savings',
      value: 'savings',
      onClick: () => handleChange({ accountType: 'savings' })
    },
    {
      text: 'Transmission',
      value: 'transmission',
      onClick: () => handleChange({ accountType: 'transmission' })
    }
  ];

  return (
    <Form>
      <FormRow fluidSpaced>
        <Select
          placeholder="Account Type"
          name="bankAccountType"
          label="Account Type"
          disabled={disabled}
          value={capitalise(form?.accountType)}
          error={form?.messages?.accountType}
          items={!disabled ? accountTypes : []}
        />
        <Input
          name="accountNumber"
          label="Account Number"
          placeholder="Enter your bank account number"
          disabled={disabled}
          value={form?.accountNumber}
          error={form?.messages?.accountNumber}
          onChange={handleChange}
        />
      </FormRow>
      <FormRow fluidSpaced>
        <Input
          name="bankName"
          placeholder="Bank Name"
          label="Bank Name"
          value={form?.bankName}
          error={form?.messages?.bankName}
          onChange={handleChange}
          disabled={disabled}
        />
        <Input
          name="branchNumber"
          label="Branch Number"
          placeholder="Enter your banks branch number"
          disabled={disabled}
          value={form?.branchNumber}
          error={form?.messages?.branchNumber}
          onChange={handleChange}
        />
      </FormRow>
    </Form>
  );
};

const BankingDetails = ({ bankingDetails, handleSave }) => {
  const [form, setForm] = useFormState(formify(bankingDetails), rules);
  const [editMode, setEditMode] = useState(false);

  const handleDiscard = () => {
    setForm(formify(bankingDetails));
    setEditMode(false);
  };

  const onSubmit = () => {
    const payload = serverify(form);
    handleSave(payload, () => setEditMode(false));
  };

  const handleChange = (newState) => setForm({ ...form, ...newState });

  return (
    <section className="banking-details">
      <section className="banking-details__content-wrapper">
        <section className={getClassNames('banking-details__form-wrapper', { grey: editMode })}>
          <DetailsSection
            title="Banking Details"
            icon={<EditIcon onClick={() => setEditMode(true)} />}
          >
            <BankingDetailsForm disabled={!editMode} handleChange={handleChange} form={form} />
          </DetailsSection>
          {editMode && (
            <footer className="banking-details__actions">
              <Button onClick={onSubmit} label="Save" disabled={!form?.valid} />
              <Button label="Cancel" secondaryDanger onClick={handleDiscard} />
            </footer>
          )}
        </section>
      </section>
    </section>
  );
};

export default BankingDetails;
