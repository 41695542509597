import React from 'react';

import { Form, FormRow, Input, Select } from '@neslotech/eventhub-ui-kit';
import { capitalise } from '@neslotech/utils';

import '../competitions/add/add-competition.scss';

const BankingDetailsForm = ({ entity, handleChange, disabled }) => {
  const accountTypes = [
    {
      text: 'Cheque',
      value: 'cheque',
      onClick: () => handleChange({ bankAccountType: 'cheque' })
    },
    {
      text: 'Savings',
      value: 'savings',
      onClick: () => handleChange({ bankAccountType: 'savings' })
    },
    {
      text: 'Transmission',
      value: 'transmission',
      onClick: () => handleChange({ bankAccountType: 'transmission' })
    }
  ];

  return (
    <section className="add-competition-banking-details">
      <Form>
        <FormRow fluidSpaced>
          <Select
            placeholder="Account Type"
            name="bankAccountType"
            label="Account Type"
            value={capitalise(entity?.bankAccountType)}
            error={entity?.messages?.bankAccountType}
            items={!disabled ? accountTypes : []}
            disabled={disabled}
          />
          <Input
            name="bankAccountNumber"
            placeholder="Account Number"
            type="number"
            label="Account Number"
            value={entity?.bankAccountNumber}
            error={entity?.messages?.bankAccountNumber}
            onChange={handleChange}
            disabled={disabled}
          />
        </FormRow>
        <FormRow fluidSpaced>
          <Input
            name="bankName"
            placeholder="Bank Name"
            label="Bank Name"
            value={entity?.bankName}
            error={entity?.messages?.bankName}
            onChange={handleChange}
            disabled={disabled}
          />
          <Input
            name="branchNumber"
            placeholder="Branch Number"
            type="number"
            label="Branch Number"
            value={entity?.branchNumber}
            error={entity?.messages?.branchNumber}
            onChange={handleChange}
            disabled={disabled}
          />
        </FormRow>
      </Form>
    </section>
  );
};

export default BankingDetailsForm;
