import React, { useMemo } from 'react';

import {
  AddAction,
  Button,
  ButtonGroup,
  Card,
  Colorpicker,
  Form,
  FormRow,
  Input,
  Loader,
  RadioGroup,
  Tabs
} from '@neslotech/eventhub-ui-kit';
import { isEmpty, toLongDateString } from '@neslotech/utils';

import { useScheduleContext } from '../../hooks/useScheduleContext';

import { ReactComponent as ListItemIcon } from '../../icons/list-item.svg';
import { ReactComponent as DescriptionIcon } from '../../icons/paragraph-icon.svg';
import { ReactComponent as LocationIcon } from '../../icons/location-icon.svg';
import { ReactComponent as CalendarIcon } from '../../icons/calendar-icon.svg';
import { ReactComponent as ClockIcon } from '../../icons/arrow-clock-icon.svg';

import ClassScheduleHeats from './heats/ClassScheduleHeats';
import AddClassScheduleHeat from './heats/add/AddClassScheduleHeat';
import AddScheduleTimeslot from './timeslots/add/AddScheduleTimeslot';

import './schedule.scss';

const radioItems = () => [
  {
    label: 'Email',
    value: 'email'
  },
  {
    label: 'SMS',
    value: 'sms'
  },
  {
    label: 'Both',
    value: 'both'
  }
];

const ClassScheduleForm = ({ classSchedule, onChange }) => (
  <Form>
    <FormRow>
      <Input
        labelPrefix={<DescriptionIcon />}
        name="description"
        label="Description"
        placeholder="Description (Optional)"
        value={classSchedule?.description}
        onChange={onChange}
        guttered
        borderless
        shadowed
      />
    </FormRow>
    <FormRow>
      <Input
        labelPrefix={<LocationIcon />}
        name="location"
        label="Class Location"
        placeholder="Class Location (Optional)"
        value={classSchedule?.location}
        onChange={onChange}
        guttered
        borderless
        shadowed
      />
    </FormRow>
    <FormRow>
      <Colorpicker
        label="Class Colour"
        name="color"
        value={classSchedule?.color}
        onChange={onChange}
      />
    </FormRow>
    <FormRow>
      <RadioGroup
        name="notificationType"
        label="Notification Type"
        value={classSchedule?.notificationType}
        onChange={onChange}
        items={radioItems()}
        fluid
      />
    </FormRow>
  </Form>
);

const Schedule = () => {
  const {
    schedule,
    event,
    loading,
    handleClassScheduleChange,
    setShowHeatForm,
    selectedClassSchedule,
    setSelectedClassSchedule,
    selectedTimeslot,
    setSelectedTimeslot,
    handleAddNewTimeslot,
    handleSaveSchedule,
    onSchedulePreview
  } = useScheduleContext();

  const classTabs = useMemo(() => {
    return (schedule?.competitionClassSchedules ?? []).map((classSchedule) => ({
      key: String(classSchedule.competitionClass.id),
      label: classSchedule.competitionClass.name,
      icon: <ListItemIcon />,
      onClick: () => {
        setSelectedClassSchedule(classSchedule);
        setSelectedTimeslot(undefined);
      }
    }));
  }, [schedule?.competitionClassSchedules, setSelectedClassSchedule, setSelectedTimeslot]);

  const additionalTimeslotTabs = useMemo(() => {
    return (schedule?.timeslots ?? []).map((slot) => ({
      key: slot.key,
      label: slot.name,
      icon: <ClockIcon />,
      onClick: () => {
        setSelectedTimeslot(slot);
        setSelectedClassSchedule(undefined);
      }
    }));
  }, [schedule?.timeslots, setSelectedTimeslot, setSelectedClassSchedule]);

  if (loading) {
    return <Loader filled />;
  }

  return (
    <article className="schedule">
      <p>
        Click on the class you would like to create a schedule for, you can go back and edit the
        schedule at any time by clicking on the class. Once all the classes have been scheduled you
        will be able to preview your events schedule. If you'd like to create times for anything
        other than your predefined classes, you can add a new timeslot underneath the class
        selector.
      </p>
      <article className="schedule__content">
        <aside>
          <Tabs tabs={classTabs} vertical disableActive={!selectedClassSchedule} />
          {!isEmpty(schedule?.timeslots) && (
            <section className="schedule__additional-slots">
              <label>Additional Time Slots</label>
              <Tabs tabs={additionalTimeslotTabs} vertical disableActive={!selectedTimeslot} />
            </section>
          )}
          <AddAction title="Add New Time Slot" onClick={handleAddNewTimeslot} />
          <section className="schedule__actions">
            <Card>
              <ButtonGroup spaced>
                <Button label="Preview Schedule" onClick={onSchedulePreview} longText />
                <Button label="Save Draft" hollow />
                <Button label="Discard" secondaryDanger />
              </ButtonGroup>
            </Card>
          </section>
        </aside>
        {selectedTimeslot && <AddScheduleTimeslot />}
        {!selectedTimeslot && (
          <section className="schedule__info-section">
            <Card noShadow>
              <h3>{selectedClassSchedule?.competitionClass?.name}</h3>
              <article className="schedule__date">
                <CalendarIcon />
                <p>{toLongDateString(event?.startDate)}</p>
              </article>
              <ClassScheduleForm
                classSchedule={selectedClassSchedule}
                onChange={handleClassScheduleChange}
              />
              <ClassScheduleHeats
                heats={selectedClassSchedule?.heats}
                onAdd={() => setShowHeatForm(true)}
              />
              <AddClassScheduleHeat />
              <article className="schedule__info-section-actions">
                <Button
                  label={`Save ${selectedClassSchedule?.competitionClass?.name} Schedule`}
                  onClick={handleSaveSchedule}
                  tertiary
                />
              </article>
            </Card>
          </section>
        )}
      </article>
    </article>
  );
};

export default Schedule;
