import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { CREATE, PageLayout } from '@neslotech/eventhub-ui-kit';

import { setOrganisationIdCookie } from '../../../../tools/organisation.util';

import { retrieveProfile } from '../../../../actions/profile.action';
import { createOrganisation } from '../../../../actions/organisation.actions';

import OrganizerLayout from '../../../../components/layout/organizer/OrganizerLayout';
import AddOrganisation from '../../../../components/organisation/add/AddOrganisation';

const AddOrganisationContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useSelector(({ profile_store }) => profile_store.profile);

  const setCreatedOrganisation = (id) => {
    setOrganisationIdCookie(id);
    dispatch(retrieveProfile());
    navigate('/organisations/details');
  };

  const onCreateOrganisation = (payload) => {
    payload.user_id = profile.id;
    dispatch(createOrganisation(payload, navigate, CREATE, setCreatedOrganisation));
  };

  return (
    <OrganizerLayout gutterless>
      <PageLayout back title="Create New Organisation" fixed>
        <AddOrganisation onSave={onCreateOrganisation} />
      </PageLayout>
    </OrganizerLayout>
  );
};

export default AddOrganisationContainer;
