import React, { useState } from 'react';
import { useFormState } from '@neslotech/hooks';

import {
  Button,
  ContextCard,
  DetailsSection,
  Form,
  FormRow,
  Input,
  toBase64
} from '@neslotech/eventhub-ui-kit';

import { ReactComponent as DefaultImage } from '../../../images/details-default-image.svg';

import './add-organisation.scss';

const rules = {
  validates: {
    name: ['isPresent'],
    emailAddress: ['isPresent', 'isEmail'],
    contactNumber: ['isNumeric', 'isPresent'],
    physicalAddress: ['isPresent'],
    suburb: ['isPresent'],
    postalCode: ['isPresent']
  }
};

const serverify = ({
  name,
  emailAddress,
  contactNumber,
  physicalAddress,
  suburb,
  postalCode,
  image
}) => ({
  name,
  email_address: emailAddress,
  phone_number: contactNumber,
  physical_address: physicalAddress,
  image,
  suburb,
  postal_code: postalCode
});

const OrganisationForm = ({ handleChange, form }) => (
  <section className="organisation-form">
    <Form>
      <FormRow fluidSpaced>
        <Input
          name="name"
          label="Organisation Name"
          placeholder="Organisation Name"
          value={form?.name}
          error={form?.messages?.name}
          onChange={handleChange}
        />
        <Input
          name="emailAddress"
          label="Email Address"
          placeholder="Email Address"
          value={form?.emailAddress}
          error={form?.messages?.emailAddress}
          onChange={handleChange}
        />
      </FormRow>
      <FormRow fluidSpaced>
        <section className="add-organisation__address-wrapper">
          <Input
            name="physicalAddress"
            label="Physical Address"
            placeholder="Physical Address"
            value={form?.physicalAddress}
            error={form?.messages?.physicalAddress}
            onChange={handleChange}
          />
          <Input
            name="suburb"
            placeholder="Suburb"
            value={form?.suburb}
            error={form?.messages?.suburb}
            onChange={handleChange}
          />
          <Input
            name="postalCode"
            placeholder="Postal Code"
            value={form?.postalCode}
            error={form?.messages?.postalCode}
            onChange={handleChange}
          />
        </section>
        <Input
          name="contactNumber"
          label="Contact Number"
          placeholder="Contact Number"
          value={form?.contactNumber}
          error={form?.messages?.contactNumber}
          onChange={handleChange}
        />
      </FormRow>
    </Form>
  </section>
);

const AddOrganisation = ({ onSave }) => {
  const [form, setForm] = useFormState(undefined, rules);
  const [img, setImg] = useState();

  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleFileChange = async (file) => {
    const base64 = await toBase64(file);
    setForm({ ...form, image: base64 });
    setImg(<img src={base64} alt="organisation" />);
  };

  const onSubmit = () => {
    const payload = serverify(form);
    onSave(payload);
  };

  const handleDiscard = () => {
    setForm(undefined);
    setImg(undefined);
  };

  return (
    <section className="add-organisation">
      <section className="add-organisation__content-wrapper">
        <ContextCard editMode image={img ?? <DefaultImage />} onFileChange={handleFileChange} />
        <section className="add-organisation__form-wrapper">
          <DetailsSection title="">
            <OrganisationForm handleChange={handleChange} form={form} />
          </DetailsSection>
          <footer className="add-organisation__actions">
            <Button label="Create" onClick={onSubmit} disabled={!form?.valid} />
            <Button label="Clear Page" secondaryDanger onClick={handleDiscard} />
          </footer>
        </section>
      </section>
    </section>
  );
};

export default AddOrganisation;
