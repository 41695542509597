import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { editBankingDetails, loadBankingDetails } from '../../../../actions/organisation.actions';

import { Loader, PageLayout } from '@neslotech/eventhub-ui-kit';

import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';
import BankingDetails from '../../../../components/organisation/banking-details/BankingDetails';
import OrganizerLayout from '../../../../components/layout/organizer/OrganizerLayout';

const BankingDetailsContainer = () => {
  const dispatch = useDispatch();
  const { id } = useOrganisationContext();

  const bankingDetails = useSelector(({ organisation_store }) => organisation_store.bankingDetails);
  const stateLoading = useSelector(({ organisation_store }) => organisation_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  const handleSave = (payload, onSuccess) => {
    dispatch(editBankingDetails(id, bankingDetails.id, payload, onSuccess));
  };

  useEffect(() => {
    dispatch(loadBankingDetails(id, () => setLoading(false)));
  }, [dispatch, id]);

  return (
    <OrganizerLayout gutterless>
      <PageLayout title="Banking Details" back fixed>
        {loading && <Loader filled />}
        {!loading && <BankingDetails handleSave={handleSave} bankingDetails={bankingDetails} />}
      </PageLayout>
    </OrganizerLayout>
  );
};

export default BankingDetailsContainer;
