import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ACTIVE_STATUS,
  Button,
  CANCELLED_STATUS,
  ContextDetails,
  DetailsSection,
  DocumentDetail,
  DRAFT_STATUS,
  Form,
  FormRow,
  Input,
  RadioGroup,
  Select,
  Table,
  TextArea,
  UPCOMING_STATUS
} from '@neslotech/eventhub-ui-kit';
import { capitalise, isEmpty, noOp } from '@neslotech/utils';
import { classesTableHeaders, membersTableHeaders } from './event-details.helpers';

import { ReactComponent as CalendarIcon } from '../../../icons/calendar-icon.svg';
import { ReactComponent as ClockIcon } from '../../../icons/clock-icon.svg';
import { ReactComponent as LocationIcon } from '../../../icons/location-icon.svg';
import { ReactComponent as EditIcon } from '../../../icons/blue-edit-icon.svg';

import FormCanvas from '../../form-builder/FormCanvas';
import ScheduleSection from '../../schedule/section/ScheduleSection';

import './event-details.scss';

const EventDetailsSection = ({ event }) => (
  <section className="event-details-section">
    <Form>
      <FormRow fluid>
        <Input placeholder="Event Name" label="Event Name" value={event?.name} disabled />
        <Input
          placeholder="Start and End Time"
          label="Start and End Time"
          action={<ClockIcon />}
          value={`${event.start_time} - ${event.end_time}`}
          disabled
        />
      </FormRow>
      <FormRow fluid>
        <Input
          placeholder="Start and End Date"
          label="Start and End Date"
          action={<CalendarIcon />}
          value={`${event.start_date} - ${event.end_date}`}
          disabled
        />
        <Input
          placeholder="Location"
          label="Location"
          action={<LocationIcon />}
          value={event.venue}
          disabled
        />
      </FormRow>
      <FormRow fluid>
        <Select
          label="Competition Type"
          placeholder="Competition Type"
          value={event.competition?.competition_type}
          disabled
        />
        <RadioGroup
          label="National or Regional Event"
          disabled
          fluid
          name="national"
          value={event.national}
          items={[
            { label: 'National', value: true },
            { label: 'Regional', value: false }
          ]}
        />
      </FormRow>
      <FormRow fluid>
        <Input
          placeholder="Entry Start and End Dates"
          label="Entry Start and End Dates"
          action={<CalendarIcon />}
          value={`${event.entry_start_date} - ${event.entry_end_date}`}
          disabled
        />
        <Select
          label="Amount of Attendees"
          placeholder="Amount of Attendees"
          value={event.attendee_count}
          disabled
        />
      </FormRow>
      <FormRow fluid>
        <RadioGroup
          label="Refundable event"
          disabled
          fluid
          name="refundable"
          value={event.refundable}
          items={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
        />
        <Input
          placeholder="Attendee Price"
          label="Attendee Price"
          prefix="R"
          value={event.price}
          disabled
        />
      </FormRow>
      <FormRow>
        <RadioGroup
          label="Do you want participants entries to be visible to other entrants?"
          disabled
          fluid
          name="visible_entries"
          value={event.visible_entries}
          items={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
        />
      </FormRow>
      <FormRow>
        <RadioGroup
          name="bankingDetails"
          label="Are you using your supplied banking details?"
          fluid
          value={event?.supplied_banking_details}
          items={[
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]}
        />
      </FormRow>
    </Form>
  </section>
);

const DescriptionSection = ({ event }) => (
  <DetailsSection title="Event Description">
    <section className="description-section">
      <Form>
        <FormRow>
          <TextArea placeholder="Enter Event Description" value={event.description} disabled />
        </FormRow>
      </Form>
    </section>
  </DetailsSection>
);

const mapAllocationsRows = (allocations = []) =>
  allocations.map(({ name, start_time, price }) => ({
    name,
    start_time,
    price
  }));

const mapMembersRows = (members = []) =>
  members.map(({ name, price, limit }) => ({
    name,
    price,
    limit
  }));

const ClassesSection = ({ event }) => (
  <DetailsSection title="Classes in the Event">
    <section className="classes-section">
      <Table cols={classesTableHeaders} rowData={mapAllocationsRows(event.classes)} />
    </section>
  </DetailsSection>
);

const MembersSection = ({ event }) => (
  <DetailsSection title="Member Selection">
    <section className="members-section">
      <Table cols={membersTableHeaders} rowData={mapMembersRows(event.members)} />
    </section>
  </DetailsSection>
);

const BankingDetailsSection = ({ event }) => (
  <DetailsSection title="Banking Details">
    <section className="event-details-section">
      <Form>
        <FormRow fluidSpaced>
          <Select
            placeholder="Account Type"
            name="bankAccountType"
            label="Account Type"
            value={capitalise(event?.banking_detail.account_type)}
            disabled
          />
          <Input
            placeholder="Bank Account Number"
            label="Account Number"
            value={event?.banking_detail?.account_number}
            disabled
          />
        </FormRow>
        <FormRow fluidSpaced>
          <Input
            placeholder="Bank Name"
            label="Bank Name"
            value={event?.banking_detail?.bank_name}
            disabled
          />
          <Input
            placeholder="Branch Number"
            label="Branch Number"
            value={event?.banking_detail?.branch_number}
            disabled
          />
        </FormRow>
      </Form>
    </section>
  </DetailsSection>
);

const tableHeaders = [
  { Header: 'Rider Place', accessor: 'place' },
  { Header: 'Points per place', accessor: 'points' }
];
const PointsConfigurationSection = ({ event }) => (
  <section className="add-preview__points-config">
    <DetailsSection title="Points Configuration Details">
      <Table cols={tableHeaders} rowData={event.points_configuration.positions} />
    </DetailsSection>
    <DetailsSection title="" subtitle="Points configuration description">
      <TextArea
        placeholder="Enter your description"
        value={event.points_configuration.description}
      />
    </DetailsSection>
  </section>
);

const EntryFormSection = () => (
  <section className="add-preview__entry-form">
    <FormCanvas />
  </section>
);

const EventDetails = ({
  event,
  schedule,
  onCancel,
  onDelete,
  onReinstate,
  onPostpone,
  onPublish,
  onCreateSchedule,
  verified
}) => {
  const navigate = useNavigate();

  const actions = useMemo(() => {
    switch (event?.status) {
      case ACTIVE_STATUS:
      case UPCOMING_STATUS:
        const upcomingActions = [
          <Button
            disabled={!verified}
            key="postpone"
            label="Postpone Event"
            hollow
            onClick={onPostpone}
          />,
          <Button
            disabled={!verified}
            key="cancel"
            label="Cancel Event"
            danger
            onClick={onCancel}
          />
        ];

        if (!!schedule) {
          return upcomingActions;
        }

        return [
          <Button
            disabled={!verified}
            key="createSchedule"
            label="Create Schedule"
            onClick={onCreateSchedule}
          />,
          ...upcomingActions
        ];
      case CANCELLED_STATUS:
        return [
          <Button
            disabled={!verified}
            key="reinstate"
            label="Reinstate Event"
            onClick={onReinstate}
          />,
          <Button
            disabled={!verified}
            key="remove"
            label="Remove Event"
            danger
            onClick={onDelete}
          />
        ];
      case DRAFT_STATUS:
        return [
          <Button
            key="publish"
            disabled={event?.competition?.status === 'draft' || !verified}
            label="Publish Event"
            onClick={onPublish}
          />,
          <Button
            disabled={!verified}
            key="remove"
            label="Remove Event"
            danger
            onClick={onDelete}
          />
        ];
      default:
        return [];
    }
  }, [
    event?.status,
    schedule,
    onCancel,
    onDelete,
    onReinstate,
    onPostpone,
    onPublish,
    onCreateSchedule,
    event?.competition?.status,
    verified
  ]);

  return (
    <article className="event-details">
      <ContextDetails context={event} actions={actions} />
      <section className="event-details__details">
        <DetailsSection
          title="Event Details"
          icon={<EditIcon onClick={verified ? () => navigate('edit') : noOp} />}
        >
          <EventDetailsSection event={event} />
        </DetailsSection>
        {event?.banking_detail && <BankingDetailsSection event={event} />}
        <DescriptionSection event={event} />
        <section className="event-details__documents">
          {(event?.additional_logos ?? []).map((logo) => (
            <DocumentDetail key={logo.id} name={logo.name} />
          ))}
        </section>
        <DetailsSection title="Supporting Documents" noCard>
          <section className="add-event__documents">
            {(event?.supporting_documents ?? []).map((document) => (
              <DocumentDetail key={document.id} name={document.name} />
            ))}
          </section>
        </DetailsSection>
        <ClassesSection event={event} />
        <MembersSection event={event} />
        {!isEmpty(event?.points_configuration?.positions) && (
          <PointsConfigurationSection event={event} />
        )}
        {event?.entry_form && <EntryFormSection />}
        {!!schedule && (
          <ScheduleSection title={`${event?.name} Schedule`} event={event} schedule={schedule} />
        )}
      </section>
    </article>
  );
};

export default EventDetails;
