import React from 'react';

import { Button, ButtonGroup, TextArea } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import './postpone-competition.scss';

const rules = {
  validates: {
    reason: ['isPresent']
  }
};

const serverify = ({ reason }) => ({
  reason
});

const PostponeReason = ({ competition, onConfirmClick, onCancelClick }) => {
  const [form, setForm] = useFormState(undefined, rules);

  const handleChange = (newForm) => setForm({ ...form, ...newForm });

  const handleConfirm = () => {
    const payload = serverify(form);
    onConfirmClick(payload);
  };

  return (
    <section className="postpone-competition">
      <section className="postpone-competition__message">
        <p>
          Are you sure you want to postpone <span>{competition.name}</span>?
        </p>
        <p>
          Postponing this competition will carry the competition information over to the chosen
          date. Please note an emailer will be sent out to all registered participants informing
          them of the reason for the competition being postponed.
        </p>
      </section>
      <section className="postpone-competition__form">
        <TextArea
          shadowed
          name="reason"
          rows={5}
          placeholder="Please provide a reason for postponing this competition."
          onChange={handleChange}
          value={form?.reason}
        />
      </section>
      <ButtonGroup>
        <Button label="Confirm" disabled={!form?.valid} onClick={handleConfirm} />
        <Button label="Cancel" onClick={onCancelClick} secondary />
      </ButtonGroup>
    </section>
  );
};

export default PostponeReason;
