import { API_HOST } from './index';

import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

const getTemplatesEndpoint = (id) => `${API_HOST}/organisations/${id}/templates`;
export const getSaveTemplateRequest = (id, payload) => [
  getTemplatesEndpoint(id),
  getHttpPostOptions(payload, getAuthHeaders())
];

const getTemplatesTypeEndpoint = (id, type) =>
  type ? `${getTemplatesEndpoint(id)}?type=${type}` : `${getTemplatesEndpoint(id)}`;
export const getLoadTemplatesRequest = (id, type) => [
  getTemplatesTypeEndpoint(id, type),
  getHttpGetOptions(getAuthHeaders())
];

const getTemplateEndpoint = (id, templateId) =>
  `${API_HOST}/organisations/${id}/templates/${templateId}`;
export const getLoadTemplateRequest = (id, templateId) => [
  getTemplateEndpoint(id, templateId),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateTemplateRequest = (id, templateId, payload) => [
  getTemplateEndpoint(id, templateId),
  getHttpPutOptions(payload, getAuthHeaders())
];

export const getRemoveTemplateRequest = (id, templateId) => [
  getTemplateEndpoint(id, templateId),
  getHttpDeleteOptions(getAuthHeaders())
];
