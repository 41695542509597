import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Checkbox, Input, Table } from '@neslotech/eventhub-ui-kit';

import '../add-event.scss';

export const tableHeaders = [
  { Header: '', accessor: 'selected', disableSortBy: true },
  { Header: 'Member Type', accessor: 'name' },
  { Header: 'Price', accessor: 'price' },
  { Header: 'Limit', accessor: 'limit' }
];

const EventMembers = ({ form, handleChange }) => {
  const memberTypes = useSelector(({ competition_store }) => competition_store.memberTypes);

  const mappedData = useMemo(() => {
    const handleValueChange = (member, value) => {
      const matchingMember = memberTypes.find((klass) => klass.id === member.id);
      let formType = (form?.members ?? []).find((type) => type.memberTypeId === matchingMember.id);
      if (formType) {
        formType = {
          ...formType,
          selected: !formType.selected
        };
        const index = form.members.findIndex((type) => type.memberTypeId === matchingMember.id);
        form.members[index] = formType;
      } else {
        formType = {
          memberTypeId: matchingMember.id,
          ...value
        };
        form.members.push(formType);
      }

      handleChange({ members: form.members });
    };

    return (memberTypes ?? []).map((type) => {
      const memberType = (form?.members ?? []).find(
        (memberType) => memberType.memberTypeId === type.id
      );
      return {
        selected: (
          <Checkbox
            name="selected"
            checked={memberType?.selected}
            onChange={(val) => handleValueChange(type, val)}
          />
        ),
        name: <Input borderless name="name" placeholder="Crew Member" value={type.name} disabled />,
        price: <Input borderless name="price" placeholder="50" value={type.price} disabled />,
        limit: <Input borderless name="limit" placeholder="5" value={type.limit} disabled />
      };
    });
  }, [form?.members, handleChange, memberTypes]);

  return (
    <section className="add-event-members">
      <Table cols={tableHeaders} rowData={mappedData} />
    </section>
  );
};

export default EventMembers;
