import axios from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import { setAuthTokenCookie, setRememberMeCookie } from '../tools/auth.util';

import {
  AUTHENTICATE,
  COMPLETE_PROFILE,
  REGISTER,
  REQUEST_PASSWORD_RESET,
  RESET_PASSWORD,
  VALIDATE_RESET_TOKEN,
  VALIDATE_VERIFICATION_TOKEN,
  VERIFY_EMAIL
} from '../actions/auth.actions';
import { addSystemNotice } from '../actions/system.actions';

import {
  getAuthenticationRequest,
  getCompleteProfileRequest,
  getForgotPasswordRequest,
  getRegisterRequest,
  getResetPasswordRequest,
  getValidateResetTokenRequest,
  getValidateVerificationTokenRequest,
  getVerifyEmailRequest
} from '../tools/api/auth.endpoints';

export function* performAuthentication({ credentials, rememberMe, navigate, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getAuthenticationRequest(credentials);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    const { auth_token } = data;
    yield call(setAuthTokenCookie, auth_token);

    if (rememberMe) {
      yield call(setRememberMeCookie, auth_token);
    }

    // navigate to organisation switch page
    yield call(navigate, '/organisation-selection');
  } catch (error) {
    yield put(addSystemNotice('The email address or password is incorrect.', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForAuthenticationRequest() {
  yield takeLatest(AUTHENTICATE, performAuthentication);
}

export function* performRequestPasswordReset({ payload, navigate }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getForgotPasswordRequest(payload);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('A password reset link has been sent to your email.', SNACK_SUCCESS));

    // navigate to login page
    yield call(navigate, '/');
  } catch ({ response }) {
    let message = 'An error occurred. Please contact the system admin.';
    if (response.status === 404) {
      message = 'The email address provided is invalid.';
    }

    yield put(addSystemNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForRequestPasswordReset() {
  yield takeLatest(REQUEST_PASSWORD_RESET, performRequestPasswordReset);
}

export function* performValidateResetToken({ token, navigate }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getValidateResetTokenRequest(token);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);
  } catch ({ response }) {
    yield put(addSystemNotice('Your password reset link is invalid.', SNACK_CRITICAL));
    yield call(navigate, `/password/${token}/expired`);
  }
}

export function* watchForValidateResetToken() {
  yield takeLatest(VALIDATE_RESET_TOKEN, performValidateResetToken);
}

export function* performResetPassword({ token, payload, navigate }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getResetPasswordRequest(token, payload);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('Your password has been reset successfully.', SNACK_SUCCESS));

    // navigate to the login page
    yield call(navigate, '/');
  } catch ({ response }) {
    let message = 'An error occurred. Please contact the system admin.';
    if (response.status === 404) {
      message = 'Your password reset link is invalid.';
    } else if (response.status === 422) {
      message = 'Your passwords do not match.';
    }

    yield put(addSystemNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForResetPassword() {
  yield takeLatest(RESET_PASSWORD, performResetPassword);
}

export function* performRegister({ payload, navigate }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getRegisterRequest(payload);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('Your account has been created successfully.', SNACK_SUCCESS));

    // navigate to the organisation details page
    const { id } = data.user;
    yield call(navigate, `/register/${id}/organisation-details`);
  } catch ({ response }) {
    yield put(
      addSystemNotice('An error occurred. Please contact the system admin.', SNACK_CRITICAL)
    );
  }
}

export function* watchForRegisterRequest() {
  yield takeLatest(REGISTER, performRegister);
}

export function* performValidateVerificationToken({ token, navigate }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getValidateVerificationTokenRequest(token);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);
  } catch ({ response }) {
    yield put(addSystemNotice('Your profile verification link is invalid.', SNACK_CRITICAL));
    yield call(navigate, `/profile/${token}/expired`);
  }
}

export function* watchForValidateVerificationToken() {
  yield takeLatest(VALIDATE_VERIFICATION_TOKEN, performValidateVerificationToken);
}

export function* performVerifyEmail({ token, payload, navigate }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getVerifyEmailRequest(token, payload);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    // navigate to the complete profile page
    yield call(navigate, `/profile/${token}/complete-profile`);
  } catch ({ response }) {
    let message = 'An error occurred. Please contact the system admin.';
    if (response.status === 404) {
      message = 'Your email address provided is invalid.';
    }

    yield put(addSystemNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForVerifyEmailRequest() {
  yield takeLatest(VERIFY_EMAIL, performVerifyEmail);
}

export function* performCompleteProfile({ token, payload, navigate }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getCompleteProfileRequest(token, payload);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('Your profile has been completed successfully.', SNACK_SUCCESS));

    // navigate to the login page
    yield call(navigate, '/');
  } catch ({ response }) {
    let message = 'An error occurred. Please contact the system admin.';
    if (response.status === 404) {
      message = 'Your complete profile token is invalid.';
    } else if (response.status === 422) {
      message = 'Your passwords do not match.';
    }

    yield put(addSystemNotice(message, SNACK_CRITICAL));
  }
}

export function* watchForCompleteProfileRequest() {
  yield takeLatest(COMPLETE_PROFILE, performCompleteProfile);
}

export default function* authSaga() {
  yield all([
    watchForAuthenticationRequest(),
    watchForRequestPasswordReset(),
    watchForValidateResetToken(),
    watchForResetPassword(),
    watchForRegisterRequest(),
    watchForValidateVerificationToken(),
    watchForVerifyEmailRequest(),
    watchForCompleteProfileRequest()
  ]);
}
