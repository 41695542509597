export const LOAD_COMPETITIONS = '[COMPETITION_STORE] Load competitions';
export const SET_COMPETITIONS = '[COMPETITION_STORE] Set competitions';
export const loadCompetitions = (id, onSuccess) => ({
  type: LOAD_COMPETITIONS,
  id,
  onSuccess
});

export const CLEAR_COMPETITION = '[COMPETITION_STORE] Clear competition';
export const clearCompetition = () => ({
  type: CLEAR_COMPETITION
});

export const LOAD_COMPETITION = '[COMPETITION_STORE] Load competition';
export const SET_COMPETITION = '[COMPETITION_STORE] Set competition';
export const loadCompetition = (id, competitionId, onSuccess) => ({
  type: LOAD_COMPETITION,
  id,
  competitionId,
  onSuccess
});

export const CANCEL_COMPETITION = '[COMPETITION_STORE] Cancel competition';
export const cancelCompetition = (id, competitionId, payload) => ({
  type: CANCEL_COMPETITION,
  id,
  competitionId,
  payload
});

export const REMOVE_COMPETITION = '[COMPETITION_STORE] Remove competition';
export const removeCompetition = (id, competitionId, navigate) => ({
  type: REMOVE_COMPETITION,
  id,
  competitionId,
  navigate
});

export const REINSTATE_COMPETITION = '[COMPETITION_STORE] Reinstate competition';
export const reinstateCompetition = (id, competitionId) => ({
  type: REINSTATE_COMPETITION,
  id,
  competitionId
});

export const POSTPONE_COMPETITION = '[COMPETITION_STORE] Postpone competition';
export const postponeCompetition = (id, competitionId, payload) => ({
  type: POSTPONE_COMPETITION,
  id,
  competitionId,
  payload
});

export const LOAD_COMPETITION_CLASSES = '[COMPETITION_STORE] Load competition classes';
export const SET_COMPETITION_CLASSES = '[COMPETITION_STORE] Set competition classes';
export const loadCompetitionClasses = (id, competitionId) => ({
  type: LOAD_COMPETITION_CLASSES,
  id,
  competitionId
});

export const LOAD_COMPETITION_MEMBER_TYPES = '[COMPETITION_STORE] Load competition member types';
export const SET_COMPETITION_MEMBER_TYPES = '[COMPETITION_STORE] Set competition member types';
export const loadCompetitionMemberTypes = (id, competitionId) => ({
  type: LOAD_COMPETITION_MEMBER_TYPES,
  id,
  competitionId
});

export const LOAD_COMPETITION_POINTS_CONFIGURATION =
  '[COMPETITION_STORE] Load competition points configuration';
export const SET_COMPETITION_POINTS_CONFIGURATION =
  '[COMPETITION_STORE] Set competition points configuration';
export const loadCompetitionPointsConfiguration = (id, competitionId, onSuccess) => ({
  type: LOAD_COMPETITION_POINTS_CONFIGURATION,
  id,
  competitionId,
  onSuccess
});

export const LOAD_COMPETITION_ENTRY_FORM = '[COMPETITION_STORE] Load competition entry form';
export const SET_COMPETITION_ENTRY_FORM = '[COMPETITION_STORE] Set competition entry form';
export const loadCompetitionEntryForm = (id, competitionId, onSuccess) => ({
  type: LOAD_COMPETITION_ENTRY_FORM,
  id,
  competitionId,
  onSuccess
});

export const SAVE_COMPETITION_DETAILS = '[COMPETITION_STORE] Save competition details';
export const saveCompetitionDetails = (id, payload, onSuccess, onError) => ({
  type: SAVE_COMPETITION_DETAILS,
  id,
  payload,
  onSuccess,
  onError
});

export const UPDATE_COMPETITION_DETAILS = '[COMPETITION_STORE] Update competition details';
export const updateCompetitionDetails = (id, competitionId, payload, onSuccess, onError) => ({
  type: UPDATE_COMPETITION_DETAILS,
  id,
  competitionId,
  payload,
  onSuccess,
  onError
});

export const SAVE_COMPETITION_POINTS_CONFIG =
  '[COMPETITION_STORE] Save competition points configuration';
export const saveCompetitionPointsConfig = (id, competitionId, payload, onSuccess, onError) => ({
  type: SAVE_COMPETITION_POINTS_CONFIG,
  id,
  competitionId,
  payload,
  onSuccess,
  onError
});

export const SAVE_COMPETITION_ENTRY_FORM = '[COMPETITION_STORE] Save competition entry form';
export const saveCompetitionEntryForm = (id, competitionId, payload, onSuccess, onError) => ({
  type: SAVE_COMPETITION_ENTRY_FORM,
  id,
  competitionId,
  payload,
  onSuccess,
  onError
});

export const SAVE_COMPETITION_DRAFT = '[COMPETITION_STORE] Save competition as a draft';
export const saveCompetitionDraft = (id, competitionId, navigate, onError) => ({
  type: SAVE_COMPETITION_DRAFT,
  id,
  competitionId,
  navigate,
  onError
});

export const PUBLISH_COMPETITION = '[COMPETITION_STORE] Publish competition';
export const publishCompetition = (id, competitionId, navigate, onError, onDuplicate) => ({
  type: PUBLISH_COMPETITION,
  id,
  competitionId,
  navigate,
  onError,
  onDuplicate
});
