import { combineReducers } from 'redux';

import { authReducer } from './auth.reducer';
import { systemReducer } from './system.reducer';
import { profileReducer } from './profile.reducer';
import { competitionReducer } from './competition.reducer';
import { eventReducer } from './event.reducer';
import { templateReducer } from './template.reducer';
import { organisationReducer } from './organisation.reducer';
import { entryReducer } from './entry.reducer';

const rootReducer = combineReducers({
  auth_store: authReducer,
  system_store: systemReducer,
  profile_store: profileReducer,
  competition_store: competitionReducer,
  event_store: eventReducer,
  template_store: templateReducer,
  organisation_store: organisationReducer,
  entry_store: entryReducer
});

export default rootReducer;
