import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import { reinstateEvent } from '../../../../actions/event.actions';

import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';

import ReinstateEvent from '../../../../components/events/reinstate/ReinstateEvent';

const ReinstateEventContainer = ({ event, show, setShowModal }) => {
  const dispatch = useDispatch();
  const { competitionId } = useParams();

  const { id } = useOrganisationContext();

  const onClose = () => setShowModal(false);

  const handleConfirm = () => {
    dispatch(reinstateEvent(id, competitionId, event.id));
    onClose();
  };

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout onClose={onClose} title="Reinstate Event">
            <ReinstateEvent event={event} onConfirmClick={handleConfirm} onCancelClick={onClose} />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default ReinstateEventContainer;
