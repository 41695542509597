import React from 'react';
import { Link } from 'react-router-dom';

import { getClassNames } from '@neslotech/utils';

import './dashboard-card.scss';

const DashboardCard = ({ title, icon, path, disabled }) => (
  <article className={getClassNames('dashboard-card', { disabled })}>
    <section className="dashboard-card__detail">
      <h4>{title}</h4>
      <Link className="dashboard-card__action" to={path}>
        View All
      </Link>
    </section>
    <section className="dashboard-card__icon">{icon}</section>
  </article>
);

export default DashboardCard;
