import React from 'react';

import { getClassNames } from '@neslotech/utils';

import './stepper.scss';

const Step = ({ error, active, index, label, onClick }) => (
  <section className={getClassNames('step', { active, error })} onClick={onClick}>
    <span className="step__index">{index}</span>
    <label className="step__label">{label}</label>
  </section>
);

const Stepper = ({ activeStep, steps, onStepChange, errors }) => (
  <section className="stepper">
    {steps.map((step) => (
      <Step
        error={step.index === activeStep && !!errors}
        key={step.index}
        index={step.index}
        active={activeStep === step.index}
        label={step.label}
        onClick={() => onStepChange(step.index)}
      />
    ))}
  </section>
);

export default Stepper;
