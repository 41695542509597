import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import { removeTemplate } from '../../../../actions/template.actions';

import { useOrganisationContext } from '../../../../hooks/useOrganisationContext';

import RemoveTemplate from '../../../../components/templates/remove/RemoveTemplate';

const RemoveTemplateContainer = ({ template, show, setShowModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useOrganisationContext();

  const onClose = () => setShowModal(false);

  const handleConfirm = () => {
    dispatch(removeTemplate(id, template.id, navigate));
    onClose();
  };

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Template">
            <RemoveTemplate
              template={template}
              onConfirmClick={handleConfirm}
              onCancelClick={onClose}
            />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveTemplateContainer;
