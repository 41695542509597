import React from 'react';

import { ReactComponent as AlertIcon } from '../../icons/alert-icon.svg';
import { ReactComponent as ReadingMan } from '../../icons/reading-man.svg';

import { Card } from '@neslotech/eventhub-ui-kit';

import './unverified-message.scss';

const UnverifiedMessage = () => (
  <article className="unverified-message">
    <header className="unverified-message__header">
      <AlertIcon />
      <p className="unverified-message__header-text">Important Message</p>
    </header>
    <section>
      <Card>
        <section className="unverified-message__message-section">
          <section className="unverified-message__message-section--image">
            <ReadingMan />
          </section>
          <section className="unverified-message__message-section--wrapper">
            <p>
              You are currently an unverified organiser which means the following actions are
              disabled:
            </p>
            <ul>
              <li>Creating an event or competition</li>
              <li>Inviting another organiser</li>
            </ul>
            <p>As soon as you are verified by our admins, these actions will become available</p>
          </section>
        </section>
      </Card>
    </section>
  </article>
);

export default UnverifiedMessage;
