import React, { useState } from 'react';

import { Button, toBase64 } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import OrganisationBadgesSection from './badges/OrganisationBadges';
import AccountDetailsSection from './account/AccountDetails';
import PersonalDetailsSection from './personal/PersonalDetails';

import './profile-details.scss';

const rules = {
  validates: {
    firstName: ['isPresent'],
    lastName: ['isPresent'],
    phoneNumber: ['isNumeric']
  }
};

const formify = (profile) => ({
  firstName: profile.first_name,
  lastName: profile.last_name,
  phoneNumber: profile.phone_number,
  image: profile.image
});

const serverify = (form) => ({
  first_name: form.firstName,
  last_name: form.lastName,
  phone_number: form.phoneNumber,
  image: form.image
});

const ProfileDetails = ({ profile, onSave }) => {
  const [form, setForm] = useFormState(formify(profile), rules);
  const [editMode, setEditMode] = useState(false);

  const handleChange = (newState) => {
    setForm({ ...form, ...newState });
  };

  const handleSubmit = () => {
    const payload = serverify(form);
    onSave(payload);
    setEditMode(false);
  };

  const handleFileChange = async (file) => {
    const base64 = await toBase64(file);
    setForm({ ...form, image: base64 });
  };

  return (
    <article className="profile-details">
      <PersonalDetailsSection
        user={profile}
        form={form}
        handleChange={handleChange}
        editMode={editMode}
        setEditMode={setEditMode}
        handleFileChange={handleFileChange}
      />
      <section className="profile-details__split">
        <OrganisationBadgesSection organisations={profile.organisations} />
        <AccountDetailsSection user={profile} />
      </section>
      <footer className="profile-details__actions">
        {editMode && <Button label="Save Changes" disabled={!form?.valid} onClick={handleSubmit} />}
      </footer>
    </article>
  );
};

export default ProfileDetails;
