import {
  CLEAR_EVENT,
  SET_EVENT,
  SET_EVENT_CLASSES,
  SET_EVENT_SCHEDULE,
  SET_EVENTS
} from '../actions/event.actions';

export const initialState = {
  events: [],
  event: undefined,
  classes: [],
  schedule: undefined
};

export const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EVENTS:
      const { events } = action;
      return {
        ...state,
        events
      };
    case SET_EVENT:
      const { event } = action;
      return {
        ...state,
        event
      };
    case SET_EVENT_CLASSES:
      const { classes } = action;
      return {
        ...state,
        classes
      };
    case SET_EVENT_SCHEDULE:
      const { schedule } = action;
      return {
        ...state,
        schedule
      };
    case CLEAR_EVENT:
      return {
        ...state,
        event: undefined
      };
    default:
      return state;
  }
};
