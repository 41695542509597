import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ACTIVE_STATUS,
  Button,
  CANCELLED_STATUS,
  COMPLETED_STATUS,
  DRAFT_STATUS,
  filterElement,
  searchElement,
  Tabs,
  UPCOMING_STATUS,
  useFilters
} from '@neslotech/eventhub-ui-kit';
import { isEmpty } from '@neslotech/utils';

import { ReactComponent as ActiveIcon } from '../../icons/active-icon.svg';
import { ReactComponent as UpcomingIcon } from '../../icons/upcoming-icon.svg';
import { ReactComponent as CheckIcon } from '../../icons/check-icon.svg';
import { ReactComponent as NoEntryIcon } from '../../icons/no-entry-icon.svg';
import { ReactComponent as DraftIcon } from '../../icons/draft-icon.svg';

import EmptyMessage from '../empty/EmptyMessage';
import EventCard from '../card/EventCard';

import './events-list.scss';

const tabs = (filter) => [
  {
    key: UPCOMING_STATUS,
    label: 'Upcoming',
    icon: <UpcomingIcon />,
    onClick: () => filter(UPCOMING_STATUS)
  },
  {
    key: ACTIVE_STATUS,
    label: 'Active',
    icon: <ActiveIcon />,
    onClick: () => filter(ACTIVE_STATUS)
  },
  {
    key: COMPLETED_STATUS,
    label: 'Completed',
    icon: <CheckIcon />,
    onClick: () => filter(COMPLETED_STATUS)
  },
  {
    key: CANCELLED_STATUS,
    label: 'Cancelled',
    icon: <NoEntryIcon />,
    onClick: () => filter(CANCELLED_STATUS)
  },
  {
    key: DRAFT_STATUS,
    label: 'Drafts',
    icon: <DraftIcon />,
    onClick: () => filter(DRAFT_STATUS)
  }
];

const EventsList = ({ events, verified }) => {
  const navigate = useNavigate();
  const { search, filters, filtering, searching, setFilterDefaults } = useFilters();

  const [tabbedEvents, setTabbedEvents] = useState([]);

  const filteredEvents = useMemo(() => {
    return tabbedEvents
      .filter(
        (event) =>
          searchElement(event.name, search) ||
          searchElement(event.competition?.name, search) ||
          searchElement(event.venue, search)
      )
      .filter((event) => filterElement(event, filters));
  }, [tabbedEvents, search, filters]);

  const emptyMessage = useMemo(() => {
    return filtering || searching
      ? 'There are no events listed under that name.'
      : 'This table is empty. There are no events listed yet.';
  }, [filtering, searching]);

  useEffect(
    () => {
      setFilterDefaults({ status: '' });
      filterEvents();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [events]
  );

  const filterEvents = (type) => {
    switch (type) {
      case COMPLETED_STATUS:
        setTabbedEvents(events.filter((item) => item.status === COMPLETED_STATUS));
        break;
      case CANCELLED_STATUS:
        setTabbedEvents(events.filter((item) => item.status === CANCELLED_STATUS));
        break;
      case DRAFT_STATUS:
        setTabbedEvents(events.filter((item) => item.status === DRAFT_STATUS));
        break;
      default:
      case UPCOMING_STATUS:
        setTabbedEvents(events.filter((item) => item.status === UPCOMING_STATUS));
        break;
      case ACTIVE_STATUS:
        setTabbedEvents(events.filter((item) => item.status === ACTIVE_STATUS));
        break;
    }
  };

  return (
    <section className="events-list">
      <section className="events-list__filters">
        <Tabs tabs={tabs(filterEvents)} vertical />
      </section>
      <section className="events-list__list">
        {isEmpty(filteredEvents) && (
          <EmptyMessage
            type="event"
            message={emptyMessage}
            action={
              <Button
                label="Create Event"
                hollow
                disabled={!verified}
                onClick={() => navigate('/events/add')}
              />
            }
          />
        )}
        {filteredEvents.map((event) => (
          <EventCard
            key={event.id}
            entity={event}
            type="Event"
            onClick={() => navigate(`/competitions/${event.competition?.id}/events/${event.id}`)}
          />
        ))}
      </section>
    </section>
  );
};

EventsList.defaultProps = {
  events: []
};

export default EventsList;
