import React from 'react';

import { Button, ButtonGroup } from '@neslotech/eventhub-ui-kit';

import './reinstate-event.scss';

const ReinstateEvent = ({ event, onConfirmClick, onCancelClick }) => (
  <section className="reinstate-event">
    <section className="reinstate-event__message">
      <p>
        Are you sure you want to reinstate <span>{event?.name}</span>?
      </p>
    </section>
    <ButtonGroup>
      <Button label="Confirm" onClick={onConfirmClick} />
      <Button label="Cancel" onClick={onCancelClick} secondary />
    </ButtonGroup>
  </section>
);

export default ReinstateEvent;
