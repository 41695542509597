import React from 'react';

import { Button, ButtonGroup } from '@neslotech/eventhub-ui-kit';

import './remove-heat.scss';

const RemoveHeat = ({ heat, onConfirmClick, onCancelClick }) => (
  <section className="remove-heat">
    <section className="remove-heat__message">
      <p>
        Continuing this action will delete <span>{heat?.name}</span>, the rest of the scheduled
        heats in this class will not be affected.
      </p>
    </section>
    <ButtonGroup>
      <Button label="Confirm" onClick={onConfirmClick} />
      <Button label="Cancel" onClick={onCancelClick} secondary />
    </ButtonGroup>
  </section>
);

export default RemoveHeat;
