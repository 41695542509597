import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '../../providers/ProtectedRoute';

import DashboardContainer from './dashboard/Dashboard.container';
import CompetitionsContainer from './competitions/Competitions.container';
import EventsContainer from './events/Events.container';
import EventContainer from './events/details/Event.container';
import CompetitionContainer from './competitions/details/Competition.container';
import AddCompetitionContainer from './competitions/add/AddCompetition.container';
import EditCompetitionContainer from './competitions/edit/EditCompetition.container';
import AddEventContainer from './events/add/AddEvent.container';
import EditEventContainer from './events/edit/EditEvent.container';
import AddOrganisationContainer from './organisation/add/AddOrganisation.container';
import AddScheduleContainer from './schedules/add/AddSchedule.container';
import PreviewScheduleContainer from './schedules/preview/PreviewSchedule.container';
import OrganisationDetailsContainer from './organisation/details/OrganisationDetails.container';
import BankingDetailsContainer from './organisation/banking-details/BankingDetails.container';
import OrganiserDetailsContainer from './organisers/details/OrganiserDetails.container';
import TemplatesContainer from './templates/Templates.container';
import TemplateContainer from './templates/details/Template.container';
import AddPointsConfigurationTemplateContainer from './templates/add/AddPointsConfigurationTemplate.container';
import AddEntryFormTemplateContainer from './templates/add/AddEntryFormTemplate.container';
import ProfileContainer from './profile/Profile.container';
import EntriesContainer from './entries/Entries.container';
import EntryContainer from './entries/details/Entry.container';

const OrganizerContainer = () => (
  <Routes>
    <Route
      exact
      path="/dashboard"
      element={
        <ProtectedRoute>
          <DashboardContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/profile"
      element={
        <ProtectedRoute>
          <ProfileContainer />
        </ProtectedRoute>
      }
    />

    <Route
      exact
      path="/competitions"
      element={
        <ProtectedRoute>
          <CompetitionsContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/competitions/add"
      element={
        <ProtectedRoute>
          <AddCompetitionContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/competitions/:id"
      element={
        <ProtectedRoute>
          <CompetitionContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/competitions/:id/edit"
      element={
        <ProtectedRoute>
          <EditCompetitionContainer />
        </ProtectedRoute>
      }
    />

    <Route
      exact
      path="/events"
      element={
        <ProtectedRoute>
          <EventsContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/events/add"
      element={
        <ProtectedRoute>
          <AddEventContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/competitions/:competitionId/events/:id"
      element={
        <ProtectedRoute>
          <EventContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/competitions/:competitionId/events/:id/edit"
      element={
        <ProtectedRoute>
          <EditEventContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/competitions/:competitionId/events/add"
      element={
        <ProtectedRoute>
          <AddEventContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/organisations/add"
      element={
        <ProtectedRoute>
          <AddOrganisationContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/organisations/details"
      element={
        <ProtectedRoute>
          <OrganisationDetailsContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/competitions/:competitionId/events/:id/schedule/add"
      element={
        <ProtectedRoute>
          <AddScheduleContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/competitions/:competitionId/events/:id/schedule/preview"
      element={
        <ProtectedRoute>
          <PreviewScheduleContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/organisations/banking-details"
      element={
        <ProtectedRoute>
          <BankingDetailsContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/organisations/organisers/:id"
      element={
        <ProtectedRoute>
          <OrganiserDetailsContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/templates"
      element={
        <ProtectedRoute>
          <TemplatesContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/templates/points-configuration/add"
      element={
        <ProtectedRoute>
          <AddPointsConfigurationTemplateContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/templates/entry-form/add"
      element={
        <ProtectedRoute>
          <AddEntryFormTemplateContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/templates/:id"
      element={
        <ProtectedRoute>
          <TemplateContainer />
        </ProtectedRoute>
      }
    />

    <Route
      exact
      path="/entries"
      element={
        <ProtectedRoute>
          <EntriesContainer />
        </ProtectedRoute>
      }
    />
    <Route
      exact
      path="/entries/:id"
      element={
        <ProtectedRoute>
          <EntryContainer />
        </ProtectedRoute>
      }
    />
  </Routes>
);

export default OrganizerContainer;
