import React, { useMemo, useState } from 'react';

import {
  Accordion,
  AWAITING_VERIFICATION_STATUS,
  Button,
  ButtonGroup,
  CANCELLED_STATUS,
  Card,
  ContextCard,
  ContextInfoList,
  Datepicker,
  DetailsSection,
  Form,
  FormRow,
  Input,
  Table
} from '@neslotech/eventhub-ui-kit';
import { deepClone, isEmpty, noOp } from '@neslotech/utils';

import { FormBuilderContext } from '../../../contexts/FormBuilder.context';

import RejectEntryContainer from '../../../containers/organizer/entries/reject/RejectEntry.container';

import defaultImage from '../../../images/details-default-image.svg';

import FormCanvas from '../../form-builder/FormCanvas';

import './entry-details.scss';

const crewMemberHeaders = [
  {
    Header: 'Member Type',
    accessor: 'member_type'
  },
  {
    Header: 'Price',
    accessor: 'price'
  },
  {
    Header: 'Full Name',
    accessor: 'full_name'
  },
  {
    Header: 'Contact Number',
    accessor: 'phone_number'
  },
  {
    Header: 'Email Address',
    accessor: 'email'
  },
  {
    Header: 'ID Number',
    accessor: 'id_number'
  }
];

const selectionHeaders = [
  {
    Header: 'Class Name',
    accessor: 'className'
  },
  {
    Header: '1st Class',
    accessor: 'first_class_price'
  },
  {
    Header: '2nd Class',
    accessor: 'second_class_price'
  },
  {
    Header: '1st Class (Family Member)',
    accessor: 'first_class_family_price'
  },
  {
    Header: '2nd Class (Family Member)',
    accessor: 'second_class_family_price'
  },
  {
    Header: 'Price',
    accessor: 'price'
  }
];

const vehicleHeaders = [
  {
    Header: 'Bike Name',
    accessor: 'name'
  },
  {
    Header: 'Allocated Class',
    accessor: 'className'
  },
  {
    Header: 'Manufacturer',
    accessor: 'manufacturer'
  },
  {
    Header: 'Chassis Number',
    accessor: 'chassis_number'
  },
  {
    Header: 'Engine Number',
    accessor: 'engine_number'
  },
  {
    Header: 'Race Number',
    accessor: 'race_number'
  }
];

const totalDueHeaders = [
  { Header: 'Participant', accessor: 'full_name' },
  { Header: 'Price', accessor: 'price' }
];

const EntryDetails = ({ verified, entry, onAccept }) => {
  const [showRejectionModal, setShowRejectionModal] = useState(false);

  const actions = useMemo(() => {
    switch (entry.status) {
      case AWAITING_VERIFICATION_STATUS:
        return [
          <Button disabled={!verified} key="accept" label="Accept" onClick={onAccept} />,
          <Button
            disabled={!verified}
            key="reject"
            label="Reject"
            danger
            onClick={() => setShowRejectionModal(true)}
          />
        ];
      case CANCELLED_STATUS:
        return [
          <Button disabled={!verified} key="refund" label="Refund" />,
          <Button disabled={!verified} key="reject_refund" label="Reject Refund" danger />
        ];
      default:
        return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry.status, verified]);

  const memoizedEntry = useMemo(() => {
    if (!entry.image) {
      entry.image = defaultImage;
    }

    return entry;
  }, [entry]);

  const totalDueData = useMemo(() => {
    return entry.pricing_model.entrant_pricing_list.map((entrantPricing) => ({
      full_name: entrantPricing.entrant.full_name,
      price: entrantPricing.subtotal_cost
    }));
  }, [entry.pricing_model.entrant_pricing_list]);

  return (
    <article className="entry-details">
      <section className="entry-details__context">
        <ContextCard
          context={memoizedEntry}
          image={<img src={memoizedEntry.image} alt={memoizedEntry.name} />}
        >
          <ContextInfoList term="Name" value={memoizedEntry.name} />
          <ContextInfoList
            term="Date Entered"
            value={new Date(memoizedEntry.updated_at).toLocaleDateString()}
          />
        </ContextCard>
        {!isEmpty(actions) && (
          <section className="entry-details__context-actions">
            <Card>
              <section className="context-details__actions">
                <ButtonGroup spaced>
                  {(actions ?? []).map((action, index) => (
                    <React.Fragment key={index}>{action}</React.Fragment>
                  ))}
                </ButtonGroup>
              </section>
            </Card>
          </section>
        )}
      </section>
      <article className="entry-details__detail">
        {entry.entrants.map((entrant, index) => {
          const crewMembers = entry.crew_members.filter(
            (member) => member.entrant_id === entrant.id
          );
          const entrantPricing = entry.pricing_model.entrant_pricing_list.find(
            (pricing) => pricing.entrant.id === entrant.id
          );

          const classPricing = (entrantPricing.class_pricing ?? []).map((pricing) => ({
            ...pricing,
            className: (
              <section>
                <p>{pricing.competition_class.name}</p>
                <small>{pricing.vehicle_name}</small>
              </section>
            )
          }));

          const vehicles = classPricing
            .filter((pricing) => !!pricing.vehicle_information)
            .map(({ competition_class, vehicle_information }) => ({
              ...vehicle_information,
              className: competition_class.name
            }));

          let template = entry.entry_form_template;
          const entrantEntryForm = (entry.entry_forms ?? []).find(
            (form) => form.entrant_id === entrant.id
          );
          if (!!template) {
            template = deepClone(template);
            const elements = template.reduce(
              (accum, section) => [...accum, ...section.elements],
              []
            );
            Object.entries(entrantEntryForm).forEach(([key, value]) => {
              if (key === 'entrant_id') {
                return;
              }

              const matchingElement = elements.find((element) => element.id === key);
              matchingElement.value = value;
            });
          }

          return (
            <Accordion key={index} title={entrant.full_name} open={index === 0}>
              <DetailsSection title="Personal Details">
                <PersonalDetailsForm entrant={entrant} />
              </DetailsSection>
              <FormBuilderContext.Provider
                value={{
                  onActivateSection: noOp,
                  onCloseSelector: noOp,
                  onCloseProperties: noOp,
                  sections: template
                }}
              >
                {entrantEntryForm && <FormCanvas />}
              </FormBuilderContext.Provider>
              {!isEmpty(crewMembers) && (
                <DetailsSection
                  title="Additional Crew Members"
                  subtitle={`These are the members that you have selected to accompany ${entrant.full_name} to the event`}
                >
                  <Table cols={crewMemberHeaders} rowData={crewMembers} />
                </DetailsSection>
              )}
              <DetailsSection
                title="Class Selection"
                subtitle={`These are the classes that ${entrant.full_name} has been entered to participant in`}
              >
                <Table cols={selectionHeaders} rowData={classPricing} />
              </DetailsSection>
              {!isEmpty(vehicles) && (
                <DetailsSection
                  title="Vehicle Information"
                  subtitle={`These are the vehicles that ${entrant.full_name} has selected to be operated per class`}
                >
                  <Table cols={vehicleHeaders} rowData={vehicles} />
                </DetailsSection>
              )}
            </Accordion>
          );
        })}
        <Accordion title="Total Due" open>
          <Table cols={totalDueHeaders} rowData={totalDueData} />
          <footer className="entry-details__total-due">
            <CostBreakdown pricing={entry.pricing_model} />
          </footer>
        </Accordion>
      </article>
      <RejectEntryContainer
        show={showRejectionModal}
        entry={entry}
        setShowModal={setShowRejectionModal}
      />
    </article>
  );
};

const CostBreakdown = ({ pricing }) => (
  <ul>
    <li>
      <span>Subtotal</span>
      <p>{pricing?.subtotal_cost}</p>
    </li>
    <li>
      <span>Total</span>
      <p>{pricing?.total_cost}</p>
    </li>
  </ul>
);

const PersonalDetailsForm = ({ entrant }) => (
  <article className="entry-details__personal-details">
    <Form>
      <FormRow fluidSpaced>
        <Input disabled label="First Name" placeholder="First Name" value={entrant.first_name} />
        <Input disabled label="Last Name" placeholder="Last Name" value={entrant.last_name} />
      </FormRow>
      <FormRow fluidSpaced>
        <Input disabled label="Email Address" placeholder="Email Address" value={entrant.email} />
        <Datepicker
          disabled
          label="Date of Birth"
          placeholder="Date of Birth"
          value={entrant.date_of_birth}
        />
      </FormRow>
      <FormRow fluidSpaced>
        <Input
          disabled
          label="ID/Passport Number"
          placeholder="ID/Passport Number"
          value={entrant.id_number}
        />
        <Input
          disabled
          label="Contact Number"
          placeholder="Contact Number"
          value={entrant.phone_number}
        />
      </FormRow>
    </Form>
  </article>
);

export default EntryDetails;
