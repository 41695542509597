import React from 'react';

import { AddAction } from '@neslotech/eventhub-ui-kit';

import { useFormBuilderContext } from '../../hooks/useFormBuilderContext';

import WidgetProperties from './widgets/WidgetProperties';
import WidgetSelector from './widgets/WidgetSelector';
import FormCanvas from './FormCanvas';

import './form-builder.scss';

const FormBuilder = ({ editable, showTitle, handleChange }) => {
  const { onAddSection, openProperties, openSelector } = useFormBuilderContext();

  return (
    <article className="form-builder" id="form-builder">
      <section className="form-builder__content">
        <WidgetSelector open={openSelector} />
        <section className="form-builder__sections">
          <FormCanvas handleChange={handleChange} editable={editable} showTitle={showTitle} />
          {!openProperties && editable && (
            <footer className="form-builder__add-section" onClick={onAddSection}>
              <AddAction title="Add New Section" />
            </footer>
          )}
        </section>
        <WidgetProperties open={openProperties} />
      </section>
    </article>
  );
};

FormBuilder.defaultProps = {
  editable: true,
  showTitle: true
};

export default FormBuilder;
