import React, { useMemo } from 'react';

import { getClassNames, noOp } from '@neslotech/utils';

import { useWizardContext } from '../../hooks/useWizardContext';

import Stepper from '../stepper/Stepper';

import './wizard.scss';

const Wizard = ({ steps, steppable }) => {
  const { entity, activeStep, onStepChange, errors } = useWizardContext();

  const WizardComponent = useMemo(() => {
    return steps.find((step) => step.index === activeStep).component;
  }, [activeStep, steps]);

  const key = useMemo(() => {
    return steps.find((step) => step.index === activeStep).key;
  }, [activeStep, steps]);

  return (
    <section className="wizard">
      {/* Adding this as an anchor for the automated scroll to ensure the page with the stepper in view */}
      <input id="stepper" className={getClassNames('wizard__anchor', { error: !!errors })} />
      <header className="wizard__steps">
        <Stepper
          errors={errors}
          activeStep={activeStep}
          steps={steps}
          onStepChange={steppable && !!entity?.id ? onStepChange : noOp}
        />
        {errors && (
          <article className="wizard__error">
            <small>This page has some errors:</small>
            <p>
              Please complete the required fields to be able to save your event as a draft. The
              required fields are highlighted in red.
            </p>
          </article>
        )}
      </header>
      <section className="wizard__content">
        <WizardComponent entityKey={key} />
      </section>
    </section>
  );
};

Wizard.defaultProps = {
  steppable: true
};

export default Wizard;
