import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPatchOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

import { API_HOST } from './index';

const getEventsEndpoint = (id) => `${API_HOST}/organisations/${id}/events`;
export const getLoadEventsRequest = (id) => [
  getEventsEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

const getCompetitionEventsEndpoint = (id, competitionId) =>
  `${API_HOST}/organisations/${id}/competitions/${competitionId}/events`;
export const getCreateEventRequest = (id, competitionId, payload) => [
  getCompetitionEventsEndpoint(id, competitionId),
  getHttpPostOptions(payload, getAuthHeaders())
];

const getEventEndpoint = (id, competitionId, eventId) =>
  `${API_HOST}/organisations/${id}/competitions/${competitionId}/events/${eventId}`;
export const getLoadEventRequest = (id, competitionId, eventId) => [
  getEventEndpoint(id, competitionId, eventId),
  getHttpGetOptions(getAuthHeaders())
];

export const getRemoveEventRequest = (id, competitionId, eventId) => [
  getEventEndpoint(id, competitionId, eventId),
  getHttpDeleteOptions(getAuthHeaders())
];

export const getEditEventRequest = (id, competitionId, eventId, payload) => [
  getEventEndpoint(id, competitionId, eventId),
  getHttpPutOptions(payload, getAuthHeaders())
];

const getCancelEventEndpoint = (id, competitionId, eventId) =>
  `${getEventEndpoint(id, competitionId, eventId)}/cancel`;
export const getCancelEventRequest = (id, competitionId, eventId, payload) => [
  getCancelEventEndpoint(id, competitionId, eventId),
  getHttpPatchOptions(payload, getAuthHeaders())
];

const getReinstateEventEndpoint = (id, competitionId, eventId) =>
  `${getEventEndpoint(id, competitionId, eventId)}/reinstate`;
export const getReinstateEventRequest = (id, competitionId, eventId) => [
  getReinstateEventEndpoint(id, competitionId, eventId),
  getHttpPatchOptions(undefined, getAuthHeaders())
];

const getPostponeEventEndpoint = (id, competitionId, eventId) =>
  `${getEventEndpoint(id, competitionId, eventId)}/postpone`;
export const getPostponeEventRequest = (id, competitionId, eventId, payload) => [
  getPostponeEventEndpoint(id, competitionId, eventId),
  getHttpPatchOptions(payload, getAuthHeaders())
];

const getEventClassesEndpoint = (eventId) => `${API_HOST}/events/${eventId}/classes`;
export const getEventClassesRequest = (eventId) => [
  getEventClassesEndpoint(eventId),
  getHttpGetOptions(getAuthHeaders())
];

const getEventSchedulesEndpoint = (eventId) => `${API_HOST}/events/${eventId}/schedules`;
export const getLoadEventScheduleRequest = (eventId) => [
  getEventSchedulesEndpoint(eventId),
  getHttpGetOptions(getAuthHeaders())
];

export const getCreateEventScheduleRequest = (id, payload) => [
  getEventSchedulesEndpoint(id),
  getHttpPostOptions(payload, getAuthHeaders())
];

const getEventScheduleEndpoint = (id, scheduleId) =>
  `${API_HOST}/events/${id}/schedules/${scheduleId}`;
export const getUpdateEventScheduleRequest = (id, scheduleId, payload) => [
  getEventScheduleEndpoint(id, scheduleId),
  getHttpPostOptions(payload, getAuthHeaders())
];

const getEventDetailsEndpoint = (id, competitionId) =>
  `${API_HOST}/organisations/${id}/competitions/${competitionId}/events/details`;
export const getSaveEventsDetailsRequest = (id, competitionId, payload) => [
  getEventDetailsEndpoint(id, competitionId),
  getHttpPostOptions(payload, getAuthHeaders())
];

const getEventDetailEndpoint = (id, competitionId, eventId) =>
  `${API_HOST}/organisations/${id}/competitions/${competitionId}/events/${eventId}/details`;
export const getUpdateEventsDetailsRequest = (id, competitionId, eventId, payload) => [
  getEventDetailEndpoint(id, competitionId, eventId),
  getHttpPutOptions(payload, getAuthHeaders())
];

const getEventPointsConfigurationEndpoint = (id, competitionId, eventId) =>
  `${getEventEndpoint(id, competitionId, eventId)}/points-configuration`;
export const getSaveEventsPointsConfigRequest = (id, competitionId, eventId, payload) => [
  getEventPointsConfigurationEndpoint(id, competitionId, eventId),
  getHttpPostOptions(payload, getAuthHeaders())
];

const getEventEntryFormEndpoint = (id, competitionId, eventId) =>
  `${getEventEndpoint(id, competitionId, eventId)}/entry-form`;
export const getSaveEventsEntryFormRequest = (id, competitionId, eventId, payload) => [
  getEventEntryFormEndpoint(id, competitionId, eventId),
  getHttpPostOptions(payload, getAuthHeaders())
];

const getDraftEventEndpoint = (id, competitionId, eventId) =>
  `${getEventEndpoint(id, competitionId, eventId)}/draft`;
export const getSaveEventsDraftRequest = (id, competitionId, eventId) => [
  getDraftEventEndpoint(id, competitionId, eventId),
  getHttpPatchOptions(undefined, getAuthHeaders())
];

const getPublishEventEndpoint = (id, competitionId, eventId) =>
  `${getEventEndpoint(id, competitionId, eventId)}/publish`;
export const getPublishEventRequest = (id, competitionId, eventId) => [
  getPublishEventEndpoint(id, competitionId, eventId),
  getHttpPatchOptions(undefined, getAuthHeaders())
];
